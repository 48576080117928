import { OnDestroy, OnInit } from "@angular/core";
import { LoginDataservice } from "../../../../core/data/login.dataservice";
import { Subject } from "rxjs/Subject";
import { AppSettings } from "../../../../../app-settings";
import { SearchService } from "app/support/services/search.service";
import * as moment from 'moment/moment';
import { DateUtil } from "../../../../date-util";
var ExtendedSearchComponent = /** @class */ (function () {
    function ExtendedSearchComponent(loginDataservice, searchService) {
        this.loginDataservice = loginDataservice;
        this.searchService = searchService;
        this.ngDestroyed = new Subject();
        this.calendar = AppSettings.CUSTOM_CALENDAR;
        this.ticketMask = "";
        this.username = "";
        this.dateFrom = null;
        this.dateTo = null;
        this.keywords = "";
    }
    ExtendedSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(function (data) {
            return _this._panelList = data.panels.map(function (p) { return ({ "label": p, "value": p }); });
        });
        this.searchService.searchEvents.takeUntil(this.ngDestroyed).subscribe(function (data) {
            var map = _this.searchService.parseQuery(data);
            _this.ticketMask = map["№"];
            _this.username = map["клиент"];
            _this.dateFrom = (map["от"].length > 0) ? DateUtil.parseDate(map["от"][0], "DD.MM.YYYY") : null;
            _this.dateTo = (map["до"].length > 0) ? DateUtil.parseDate(map["до"][0], "DD.MM.YYYY") : null;
            _this.panel = map["панель"];
            _this.keywords = map["text"];
        });
    };
    ExtendedSearchComponent.prototype.ngOnDestroy = function () {
    };
    Object.defineProperty(ExtendedSearchComponent.prototype, "panelList", {
        get: function () {
            return this._panelList;
        },
        enumerable: true,
        configurable: true
    });
    ExtendedSearchComponent.prototype.filter = function (event, isSubmit) {
        if (event === void 0) { event = null; }
        if (isSubmit === void 0) { isSubmit = false; }
        var queryParamsMap = {};
        if (this.ticketMask) {
            queryParamsMap["№"] = this.ticketMask;
        }
        if (this.username) {
            queryParamsMap["клиент"] = this.username;
        }
        if (this.dateFrom) {
            queryParamsMap["от"] = moment(this.dateFrom).format("DD.MM.YYYY");
        }
        if (this.dateTo) {
            queryParamsMap["до"] = moment(this.dateTo).format("DD.MM.YYYY");
        }
        if (this.panel) {
            queryParamsMap["панель"] = this.panel;
        }
        if (this.keywords) {
            queryParamsMap["текст"] = this.keywords.replace(/[^a-zA-Zа-яА-ЯёЁ0-9]+ */g, ' ');
        }
        this.searchService.searchModel = this.searchService.makeQuery(queryParamsMap);
        if (isSubmit) {
            this.searchService.search();
            return;
        }
    };
    return ExtendedSearchComponent;
}());
export { ExtendedSearchComponent };
