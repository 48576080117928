import {Component, Input} from "@angular/core";
import {Ticket} from "../../domain/ticket.domain";
import {NavigationService} from "../../services/navigation.service";

@Component({
    selector: 'ticket-preview',
    templateUrl: './ticket-preview.component.html'
})
export class TicketPreviewComponent {

    @Input() ticket: Ticket;

    constructor(private navigationService: NavigationService) {
    }

    makeUrl(anchor: string): string {
        return this.navigationService.makeChatUrl(this.ticket.lastMessage.panelPrefix, this.ticket.lastMessage.ticketId, anchor);
    }

}
