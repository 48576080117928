import {Injectable} from "@angular/core";
import {Message} from "primeng/components/common/api";
import {Response} from "@angular/http";
import "rxjs/add/operator/map";
import {Links} from "../../../links";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class GrowlMessageService {
    msgs: Message[] = [];

    constructor(private http: HttpClient) {
    }

    success(summary: string, message: string) {
        this.showMsg({severity: 'success', summary: summary, detail: message}, 2000);
    }

    error(message: string) {
        this.showMsg({severity: 'error', summary: "Ошибка", detail: message}, 10000);
        this.http.get(Links.errorLog).map((res: Response) => res.text());
    }

    warn(summary: string, message: string) {
        this.showMsg({severity: 'warn', summary: summary, detail: message}, 10000);
    }

    info(summary: string, message: string) {
        this.showMsg({severity: 'info', summary: summary, detail: message}, 5000);
    }

    showMsg(msg, time) {
        this.msgs.push(msg);
        setTimeout(() => {
            this.removeMsg(msg)
        }, time);
    }

    removeMsg(msg) {
        this.msgs = this.msgs.filter(it => it != msg);
    }

}
