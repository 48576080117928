import {Injectable} from "@angular/core";

@Injectable()
export class AsideSupportService {

    private _showTabs = [];

    constructor() {
    }

    get showTabs() {
        return this._showTabs;
    }

    set showTabs(value) {
        this._showTabs = value;
    }

    get showAside(): boolean {
        return this._showTabs.length > 0;
    }
}
