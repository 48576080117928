import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {TicketService} from "../../../services/ticket.service";
import {ChatMessagesDataservice} from "../../../data/chat-messages.dataservice";
import {Subject} from "rxjs/Subject";
import {Admin} from "../../../domain/admin.domain";
import {TicketDataservice} from "app/support/data/ticket.dataservice";
import {ActivatedRoute, Router} from "@angular/router";
import {ChatInfoShowLogsEvents} from "../../../services/chat-info.show-logs.events";
import {LoginDataservice} from "../../../../core/data/login.dataservice";
import {Tag} from "../../../domain/tag.domain";
import {Store} from "../../../store/store";
import {AdminService} from "../../../../core/services/admin.service";
import {ChatTextareaEventbus} from "../reply/chat-textarea.eventbus";
import {ChatTextareaDirective} from "../reply/chat-textarea.directive";
import {StatusesStore} from "../../../store/statuses.store";
import {NavigationService} from "../../../services/navigation.service";
import {Dropdown} from "primeng/primeng";
import {ChatInfoShowOnlyNotesEvents} from "../../../services/chat-info.show-only-notes.events";

@Component({
    selector: 'chat-info',
    templateUrl: './chat-info.component.html'
})
export class ChatInfoComponent implements OnInit, OnDestroy {

    @ViewChild('assignedToSelector') assignedToSelector: Dropdown;

    private _ticketPanel: string;

    private _admins: Admin[];
    private _tags: Tag[];

    private chatTextareaDirective: ChatTextareaDirective;
    private ngDestroyed: Subject<void> = new Subject<void>();

    private _showLogs: boolean;
    private _showOnlyNotes: boolean;

    constructor(private ticketService: TicketService,
                private ticketDataService: TicketDataservice,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private adminService: AdminService,
                private chatInfoShowLogsEvents: ChatInfoShowLogsEvents,
                private chatInfoShowOnlyNotesEvents: ChatInfoShowOnlyNotesEvents,
                private loginDataservice: LoginDataservice,
                private navigationService: NavigationService,
                private chatTextareaEventbus: ChatTextareaEventbus,
                private chatMessagesDataservice: ChatMessagesDataservice,
                private _store: Store) {
    }

    get store(): Store {
        return this._store;
    }

    get navigation() {
        return this.navigationService;
    }

    get currentLoggedInAdmin(): Admin {
        return this.adminService.currentLoggedInAdmin
    }

    ngOnInit() {
        this._ticketPanel = this.activatedRoute.snapshot.params.panel;

        this.chatInfoShowLogsEvents.subscribe(this.ngDestroyed, (data) => {
            this._showLogs = data;
        });
        this.chatInfoShowOnlyNotesEvents.subscribe(this.ngDestroyed, (data) => {
            this._showOnlyNotes = data;
        });

        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(data => {
            this._admins = data.adminsInPanels;
            this._tags = data.tagsInPanels;
        });

        this.chatTextareaEventbus.chatTextarea.takeUntil(this.ngDestroyed).subscribe(data => {
            if (data != null) {
                this.chatTextareaDirective = data;
            }
        });
    }

    ngOnDestroy(): void {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    }

    get admins(): Admin[] {
        return this._admins[this._ticketPanel]
    }

    get tags(): Tag[] {
        return this._tags[this._ticketPanel];
    }

    changeDepartment(ticketId: number, deptId: number) {
        if (ticketId > 0) {
            this.ticketDataService.changeDepartment(this._ticketPanel, ticketId, deptId, this.store.ticketData.ticket.version);
            this.store.ticketData.setAfterPropertyChangeInChat();
        } else {
            // new ticket
            this.store.ticketData.changeDepartment(this.store.departments.getById(deptId));
        }
    }

    changeStatus(ticketId: number, statusId: number) {
        if (ticketId > 0) {
            this.ticketDataService.changeStatus(this._ticketPanel, ticketId, statusId, this.store.ticketData.ticket.version);
            if (statusId == StatusesStore.STATUS_CLOSED.id) {
                this.navigationService.goToDashboard();
                this.store.ticketData.clear();
                return;
            }
            this.store.ticketData.setAfterPropertyChangeInChat();
        } else {
            // new ticket
            this.store.ticketData.changeStatus(this.store.statuses.getById(statusId));
        }
    }

    assignTo(ticketId: number, username: string) {
        if (ticketId > 0) {
            this.ticketDataService.assignTo(this._ticketPanel, ticketId, username, this.store.ticketData.ticket.version);

            // чтобы не застревало на выбранном элементе
            this.assignedToSelector.selectedOption = null;
            this.assignedToSelector.value = null;
        } else {
            // new ticket
            this.store.ticketData.assignTo(this.admins.find(a => a.username == username));
        }
    }

    changeTag(ticketId: number, tagId: number) {
        if (ticketId > 0) {
            this.ticketDataService.changeTag(this._ticketPanel, ticketId, tagId, this.store.ticketData.ticket.version);
        } else {
            // new ticket
            this.store.ticketData.changeTag(this.tags.find(tag => tag.id == tagId));
        }
    }

    get showOnlyNotes() {
        return this._showOnlyNotes;
    }

    set showOnlyNotes(show: boolean) {
        this.chatInfoShowOnlyNotesEvents.next(show);
    }

    get showLogs() {
        return this._showLogs;
    }

    set showLogs(show: boolean) {
        this.chatInfoShowLogsEvents.next(show);
    }

    scrollToPin(id: number) {
        this.showOnlyNotes = false;
        setTimeout(() => {
            let pos = $("#anchor" + id).position();
            $('html, body').animate({scrollTop: pos.top}, 400);
        }, 100);
    }
}
