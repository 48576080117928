import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Links } from "../../../links";
import { GrowlMessageService } from "../../core/services/growl-message.service";
import { AdminService } from "../../core/services/admin.service";
import { TicketBuilder } from "../domain/builder/ticket.builder";
import { MessageBuilder } from "../domain/builder/message.builder";
import { Store } from "../store/store";
var ChatMessagesDataservice = /** @class */ (function () {
    function ChatMessagesDataservice(http, adminService, growlMessageService, ticketBuilder, messageBuilder, store) {
        this.http = http;
        this.adminService = adminService;
        this.growlMessageService = growlMessageService;
        this.ticketBuilder = ticketBuilder;
        this.messageBuilder = messageBuilder;
        this.store = store;
        this._processReply = false;
        this.messageSent = new Subject();
    }
    ChatMessagesDataservice.prototype.ticketSynchronization = function (formData) {
        var _this = this;
        this.http.post(Links.ticketSync, formData).filter(function (resp) {
            return true;
        }).map(function (resp) {
            if (resp["error"]) {
                _this.growlMessageService.error(resp["error"].message);
            }
            _this.store.ticketData.setSync(resp);
        }).subscribe(function (data) {
        }, function (error) {
            console.log(error);
        });
    };
    ChatMessagesDataservice.prototype.reply = function (panel, ticketId, formData, isMessage, goToDashboard, processReply) {
        var _this = this;
        if (goToDashboard === void 0) { goToDashboard = true; }
        if (processReply === void 0) { processReply = true; }
        this._processReply = processReply;
        this.http.post(Links.ticketNewMessage, formData).filter(function (resp) {
            if (resp["unauthorized"] == "true") {
                _this.adminService.unauthorized();
                return false;
            }
            return true;
        }).map(function (resp) {
            if (resp["error"]) {
                _this.growlMessageService.error(resp["error"].message);
            }
            _this.loadMessages(panel, ticketId, false);
            if (isMessage) {
                _this.messageSent.next({ sent: true, goToDashboard: goToDashboard });
            }
        }).subscribe(function (data) {
        }, function (error) {
            console.log(error);
            var data = error.error;
            if (data.error.message) {
                _this.growlMessageService.error(data.error.message);
            }
            else {
                _this.growlMessageService.error("Could not post reply. " + error.message);
            }
        });
    };
    ChatMessagesDataservice.prototype.massReply = function (message, postpone, ticketsIdsGroupedByPanels, processReply) {
        var _this = this;
        if (processReply === void 0) { processReply = true; }
        this._processReply = processReply;
        var body = { message: message, postpone: postpone, ticketsIdsGroupedByPanels: ticketsIdsGroupedByPanels };
        this.http.post(Links.ticketMassReply, body).filter(function (resp) {
            if (resp["unauthorized"] == "true") {
                _this.adminService.unauthorized();
                return false;
            }
            return true;
        }).map(function (resp) {
            if (resp["error"]) {
                _this.growlMessageService.error(resp["error"].message);
            }
            if (resp["answers"]) {
                var answers = resp["answers"];
                answers.forEach(function (answer) {
                    if (answer.sended) {
                        var successMsg = "\u0412 " + answer.panelPrefix + " \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F \u043E\u0442\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u044B";
                        _this.growlMessageService.success("", successMsg);
                    }
                    else {
                        var errorMsg = "\u0412 " + answer.panelPrefix + " \u043D\u0435 \u0443\u0434\u0430\u043B\u043E\u0441\u044C \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F";
                        _this.growlMessageService.error(errorMsg);
                    }
                });
            }
            _this.messageSent.next({ sent: true, goToDashboard: true });
            _this._processReply = false;
        }).subscribe(function (data) {
        }, function (error) {
            console.log(error);
            _this.growlMessageService.error("Could not post reply. " + error.message);
            _this._processReply = false;
        });
    };
    ChatMessagesDataservice.prototype.create = function (panel, formData) {
        var _this = this;
        this._processReply = true;
        this.http.post(Links.ticketCreateMessage, formData).filter(function (resp) {
            if (resp["unauthorized"] == "true") {
                _this.adminService.unauthorized();
                return false;
            }
            return true;
        }).map(function (resp) {
            if (resp["error"]) {
                _this.growlMessageService.error(resp["error"].message);
            }
            _this.messageSent.next({ sent: true, goToDashboard: true });
        }).subscribe(function (data) {
        }, function (error) {
            console.log(error);
            _this.growlMessageService.error("Could not post reply. " + error.message);
            _this._processReply = false;
        });
    };
    ChatMessagesDataservice.prototype.filterErrors = function (resp) {
        if (resp["unauthorized"] == "true") {
            this.adminService.unauthorized();
            return false;
        }
        if (resp["error"]) {
            this.growlMessageService.error(resp["error"].message);
            return false;
        }
        return true;
    };
    ChatMessagesDataservice.prototype.parseMessages = function (resp) {
        var _this = this;
        var $messages = [];
        for (var _i = 0, _a = resp["list"]; _i < _a.length; _i++) {
            var messageData = _a[_i];
            var $message = this.messageBuilder.build(messageData, resp["ticket"]["panelPrefix"]);
            $messages.push($message);
        }
        var ticketData = resp["ticket"];
        var c = ticketData["customer"];
        var $ticket = this.ticketBuilder.build(ticketData);
        var quickTickets = resp["quickTickets"].map(function (data) { return _this.ticketBuilder.build(data); });
        var ticketVersion = resp["ticketVersion"];
        return {
            messages: $messages,
            ticket: $ticket,
            username: $ticket.username,
            quickTickets: quickTickets,
            ticketVersion: ticketVersion,
            activeFlows: resp["activeFlows"]
        };
    };
    ChatMessagesDataservice.prototype.loadMessages = function (panel, ticketId, erase) {
        var _this = this;
        this.replayData = { panel: panel, ticketId: ticketId };
        if (erase) {
            this.store.ticketData.clear();
        }
        return this.http.post(Links.ticketMessages, { id: ticketId, panel: panel })
            .filter(function (resp) { return _this.filterErrors(resp); })
            .map(function (resp) { return _this.parseMessages(resp); })
            .subscribe(function (data) {
            _this.store.ticketData.set(data);
            _this._processReply = false;
        }, function (error) {
            console.log(error);
            _this.growlMessageService.error("Could not load ticket data. " + error.message);
            _this._processReply = false;
        });
    };
    ChatMessagesDataservice.prototype.getNewTicketData = function (panel, personId) {
        var _this = this;
        this.replayData = null;
        this.store.ticketData.clear();
        return this.http.post(Links.getNewTicketData, { id: personId, panel: panel })
            .filter(function (resp) { return _this.filterErrors(resp); })
            .map(function (resp) { return _this.parseMessages(resp); })
            .subscribe(function (data) {
            _this.store.ticketData.set(data);
            _this._processReply = false;
        }, function (error) {
            console.log(error);
            _this.growlMessageService.error("Could not create ticket. " + error.message);
            _this._processReply = false;
        });
    };
    ChatMessagesDataservice.prototype.replayLoadMessages = function () {
        if (this.replayData) {
            this.loadMessages(this.replayData.panel, this.replayData.ticketId, false);
        }
    };
    ChatMessagesDataservice.prototype.isProcessReply = function () {
        return this._processReply;
    };
    Object.defineProperty(ChatMessagesDataservice.prototype, "processReply", {
        set: function (processReply) {
            this._processReply = processReply;
        },
        enumerable: true,
        configurable: true
    });
    ChatMessagesDataservice.prototype.removeMessage = function (id, ticketId, panel) {
        var _this = this;
        this.http.post(Links.removeMessage, { id: id, panel: panel, ticketId: ticketId }).filter(function (resp) {
            if (resp["unauthorized"] == "true") {
                _this.adminService.unauthorized();
                return false;
            }
            if (resp["error"]) {
                _this.growlMessageService.error(resp["error"].message);
                return false;
            }
            if (resp["alert"].message) {
                _this.growlMessageService.error(resp["alert"].message);
            }
            return true;
        }).subscribe(function (data) {
            _this.loadMessages(panel, ticketId, false);
        }, function (error) {
            console.log(error);
            _this.growlMessageService.error("Could not remove message. " + error.message);
        });
    };
    ChatMessagesDataservice.prototype.pinMessage = function (messageId, ticketId, panel) {
        var _this = this;
        this.http.post(Links.pinMessage, { id: messageId, ticketId: ticketId, panel: panel }).filter(function (resp) {
            if (resp["unauthorized"] == "true") {
                _this.adminService.unauthorized();
                return false;
            }
            if (resp["error"]) {
                _this.growlMessageService.error(resp["error"].message);
                return false;
            }
            return true;
        }).subscribe(function (data) {
            _this.loadMessages(panel, ticketId, false);
        }, function (error) {
            console.log(error);
            _this.growlMessageService.error("Could not pin message. " + error.message);
        });
    };
    ChatMessagesDataservice.prototype.unpinMessage = function (messageId, ticketId, panel) {
        var _this = this;
        this.http.post(Links.unpinMessage, { id: messageId, ticketId: ticketId, panel: panel }).filter(function (resp) {
            if (resp["unauthorized"] == "true") {
                _this.adminService.unauthorized();
                return false;
            }
            if (resp["error"]) {
                _this.growlMessageService.error(resp["error"].message);
                return false;
            }
            return true;
        }).subscribe(function (data) {
            _this.loadMessages(panel, ticketId, false);
        }, function (error) {
            console.log(error);
            _this.growlMessageService.error("Could not unpin message. " + error.message);
        });
    };
    ChatMessagesDataservice.prototype.getNotificationSettings = function (panel, username, setPhoneAndTime) {
        var _this = this;
        return this.http.post(Links.getNotificationSettings, { panel: panel, username: username })
            .filter(function (resp) { return _this.filterErrors(resp); })
            .map(function (resp) {
            return { phone: resp['phone'], time: resp['time'], customerEmail: resp['customerEmail'], customer: resp['customer'] };
        }).subscribe(setPhoneAndTime, function (error) {
            console.log(error);
            _this.growlMessageService.error("Could not get notification settings. " + error.message);
        });
    };
    ChatMessagesDataservice.prototype.translate = function (messageId, message) {
        var _this = this;
        this.http.post(Links.translate, { message: message }).filter(function (resp) {
            if (resp["unauthorized"] == "true") {
                _this.adminService.unauthorized();
                return false;
            }
            if (resp["error"]) {
                _this.growlMessageService.error(resp["error"].message);
                return false;
            }
            return true;
        }).subscribe(function (data) {
            // @ts-ignore
            var translatedMessage = data.translatedMessage;
            if (messageId == null) {
                _this.store.ticketData.translateText(translatedMessage);
            }
            else {
                _this.store.ticketData.translateMessage(messageId, translatedMessage);
            }
        }, function (error) {
            console.log(error);
            _this.growlMessageService.error("Could not translate message. " + error.message);
        });
    };
    return ChatMessagesDataservice;
}());
export { ChatMessagesDataservice };
