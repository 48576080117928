var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { TicketInfo } from "./ticketInfo.domain";
import * as moment from 'moment/moment';
import { observable } from "mobx-angular";
var Ticket = /** @class */ (function () {
    function Ticket() {
    }
    Object.defineProperty(Ticket.prototype, "activityStr", {
        get: function () {
            return moment(this.activity).format("DD.MM.YYYY");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Ticket.prototype, "maskPostfix", {
        get: function () {
            return (this.adminAnswersNumber > 0 ? " *" : "");
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        observable,
        __metadata("design:type", TicketInfo)
    ], Ticket.prototype, "info", void 0);
    return Ticket;
}());
export { Ticket };
