var AsideSupportService = /** @class */ (function () {
    function AsideSupportService() {
        this._showTabs = [];
    }
    Object.defineProperty(AsideSupportService.prototype, "showTabs", {
        get: function () {
            return this._showTabs;
        },
        set: function (value) {
            this._showTabs = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AsideSupportService.prototype, "showAside", {
        get: function () {
            return this._showTabs.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    return AsideSupportService;
}());
export { AsideSupportService };
