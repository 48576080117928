import { OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AdminService } from "../core/services/admin.service";
import { Subject } from "rxjs";
import { LoginDataservice } from "../core/data/login.dataservice";
import { GrowlMessageService } from "../core/services/growl-message.service";
import { Store } from "../support/store/store";
import { FormBuilder, Validators } from "@angular/forms";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(router, adminService, loginDataservice, growlMessageService, _store, formBuilder) {
        this.router = router;
        this.adminService = adminService;
        this.loginDataservice = loginDataservice;
        this.growlMessageService = growlMessageService;
        this._store = _store;
        this.formBuilder = formBuilder;
        this.ngDestroyed = new Subject();
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            token: ['', Validators.required]
        });
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(function (data) {
            if (data.user && data.user.username) {
                _this.adminService.authorized();
            }
            if (data.error) {
                _this.growlMessageService.error(data.error.message);
            }
        });
    };
    Object.defineProperty(LoginComponent.prototype, "store", {
        get: function () {
            return this._store;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.ngOnDestroy = function () {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    };
    LoginComponent.prototype.submit = function () {
        this.adminService.login(this.loginForm);
        return false;
    };
    Object.defineProperty(LoginComponent.prototype, "msgs", {
        get: function () {
            return this.growlMessageService.msgs;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.removeGrowlMsg = function (event) {
        this.growlMessageService.removeMsg(event.message);
    };
    LoginComponent.prototype.isProcessLogin = function () {
        return this.loginDataservice.isProcessLogin();
    };
    return LoginComponent;
}());
export { LoginComponent };
