import {Router, NavigationEnd} from "@angular/router";
import {Injectable} from "@angular/core";

@Injectable()
export class MobileSidebarsCleanerService {

    constructor(private router: Router) {
    }

    public start() {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                document.querySelector('body').classList.remove('aside-mobile-show');
                document.querySelector('body').classList.remove('sidebar-mobile-show');
            }
        });
    }
}
