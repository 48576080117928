<header class="app-header navbar navbar-inverse bg-inverse" style="top: 0; ">
  <button class="navbar-toggler mobile-sidebar-toggler hidden-lg-up" type="button">&#9776;</button>
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler navbar-toggler-aside mobile-aside-toggler hidden-lg-up" type="button">
    <i class="glyphicons glyphicons-info-sign"></i>
  </button>
  <ng-content select="[layout-title]"></ng-content>
</header>

<ng-content select="[layout-growl]"></ng-content>

<ng-content select="[layout-modal-backdrop]"></ng-content>

<div class="app-body">
  <div class="sidebar">
    <nav class="sidebar-nav">
      <ng-content select="[layout-sidebar]"></ng-content>
    </nav>
  </div>

  <!-- Main content -->
  <main class="main" style="overflow-x: hidden; overflow-y: scroll">

    <ng-content select="[layout-subtitle]"></ng-content>

    <ol class="breadcrumb" [hidden]="true">
      <breadcrumbs></breadcrumbs>
    </ol>

    <div class="container-fluid content-main content-main-aside">
      <ng-content select="[layout-main]"></ng-content>
    </div>

  </main>

  <aside class="aside-menu">
    <ng-content select="[layout-aside]"></ng-content>
  </aside>
</div>

<!--<footer class="app-footer">-->
<!--<ng-content select="[layout-footer]"></ng-content>-->
<!--</footer>-->

<ng-content select="[layout-dialogs]"></ng-content>
