import {Component, OnDestroy, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AdminService} from "../core/services/admin.service";
import {LoginDataservice} from "../core/data/login.dataservice";

@Component({
    moduleId: module.id,
    templateUrl: 'logout.component.html'
})

export class LogoutComponent implements OnInit, OnDestroy {

    constructor(private router: Router,
                private adminService: AdminService,
                private loginDataservice: LoginDataservice) {
    }

    ngOnInit() {
        this.adminService.logout();
    }

    ngOnDestroy(): void {
    }
}
