import {Injectable} from "@angular/core";
import {Store} from "../../store/store";
import {DateUtil} from "../../../date-util";
import {Message} from "../message.domain";
import {LinkUtil} from "../../../link-util";

@Injectable()
export class MessageBuilder {

    constructor(private store: Store) {
    }

    build(messageData, panel) {
        let $message = new Message();
        $message.id = messageData["id"];
        $message.active = messageData["active"];
        $message.evaluation = messageData["evaluation"];
        $message.evaluationComment = messageData["evaluationComment"];
        $message.pinned = messageData["pinned"];
        $message.ticketId = +messageData["ticketId"];
        $message.author = messageData["author"];
        $message.type = messageData["type"];
        $message.date = DateUtil.parse(messageData["created"]);
        $message.text = LinkUtil.replaceSupportControllerLinks(messageData["text"], panel);
        $message.ticketInfo = messageData["ticketInfo"];
        $message.panelPrefix = panel;
        $message.attachments = messageData["attachments"] ? messageData["attachments"] : [];
        $message.setAttachments(messageData["attachments"], panel);
        return $message;
    }

}
