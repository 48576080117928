import { AfterViewInit, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import "jquery";
import "bootstrap";
import { ChatMessagesDataservice } from "../../data/chat-messages.dataservice";
import { NavigationService } from "../../services/navigation.service";
import { Subject } from "rxjs";
import { GrowlMessageService } from "../../../core/services/growl-message.service";
import { LoginDataservice } from "../../../core/data/login.dataservice";
import { ChatInfoShowLogsEvents } from "../../services/chat-info.show-logs.events";
import { Dialog } from "primeng/components/dialog/dialog";
import "rxjs/add/operator/takeUntil";
import { AsideSupportService } from "./aside/aside-support.service";
import { ChatTextareaEventbus } from "./reply/chat-textarea.eventbus";
import { Store } from "../../store/store";
import { ChatInfoShowOnlyNotesEvents } from "../../services/chat-info.show-only-notes.events";
import { MassReplyDataservice } from "../../data/mass-reply.dataservice";
var ChatMassReplyComponent = /** @class */ (function () {
    function ChatMassReplyComponent(asideSupportService, chatMessagesDataservice, massReplyDataservice, growlMessageService, activatedRoute, navigationService, chatInfoShowLogsEvents, chatInfoShowOnlyNotesEvents, chatTextareaEventbus, loginDataservice, _store) {
        this.asideSupportService = asideSupportService;
        this.chatMessagesDataservice = chatMessagesDataservice;
        this.massReplyDataservice = massReplyDataservice;
        this.growlMessageService = growlMessageService;
        this.activatedRoute = activatedRoute;
        this.navigationService = navigationService;
        this.chatInfoShowLogsEvents = chatInfoShowLogsEvents;
        this.chatInfoShowOnlyNotesEvents = chatInfoShowOnlyNotesEvents;
        this.chatTextareaEventbus = chatTextareaEventbus;
        this.loginDataservice = loginDataservice;
        this._store = _store;
        this.showLogs = false;
        this.showOnlyNotes = false;
        this.ngDestroyed = new Subject();
        this.quoteText = "";
    }
    Object.defineProperty(ChatMassReplyComponent.prototype, "store", {
        get: function () {
            return this._store;
        },
        enumerable: true,
        configurable: true
    });
    ChatMassReplyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.navigationService.navEnd.takeUntil(this.ngDestroyed).subscribe(function (n) {
            var params = _this.activatedRoute.snapshot.queryParams;
            _this._ticketsIdsGroupedByPanels = Object.getOwnPropertyNames(params).map(function (name) {
                var ids = params[name]
                    .split(",")
                    .map(function (id) { return Number.parseInt(id); });
                return { panelPrefix: name, ids: ids };
            });
            if (_this._ticketsIdsGroupedByPanels.length == 1) {
                _this._panelPrefixForAllTickets = _this._ticketsIdsGroupedByPanels[0].panelPrefix;
            }
            _this.massReplyDataservice.loadTicketListSubject.next({
                ticketsIdsGroupedByPanels: _this._ticketsIdsGroupedByPanels,
                erase: true
            });
            _this.chatInfoShowOnlyNotesEvents.next(false);
            _this._store.ticketData.clear();
        });
        this.chatMessagesDataservice.messageSent.takeUntil(this.ngDestroyed).subscribe(function (data) {
            if (data.sent) {
                _this.navigationService.goToDashboard();
            }
        });
        this.asideSupportService.showTabs = ['info'];
        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(function (data) { return _this._userInfo = data; });
        this.chatTextareaEventbus.chatTextarea.takeUntil(this.ngDestroyed).subscribe(function (data) {
            if (data != null) {
                _this.chatTextareaDirective = data;
                var draftText = "";
                var hasAdminMsg = false;
                _this.chatTextareaDirective.initMessage(draftText, hasAdminMsg);
            }
        });
        this.chatInfoShowLogsEvents.subscribe(this.ngDestroyed, function (data) { return _this.showLogs = data; });
        this.chatInfoShowOnlyNotesEvents.subscribe(this.ngDestroyed, function (data) { return _this.showOnlyNotes = data; });
    };
    ChatMassReplyComponent.prototype.ngOnDestroy = function () {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    };
    Object.defineProperty(ChatMassReplyComponent.prototype, "msgs", {
        get: function () {
            return this.growlMessageService.msgs;
        },
        enumerable: true,
        configurable: true
    });
    ChatMassReplyComponent.prototype.removeGrowlMsg = function (event) {
        this.growlMessageService.removeMsg(event.message);
    };
    Object.defineProperty(ChatMassReplyComponent.prototype, "massReplyTicketList", {
        get: function () {
            return this._store.massReplyTickets.value;
        },
        enumerable: true,
        configurable: true
    });
    ChatMassReplyComponent.prototype.ngAfterViewInit = function () {
    };
    ChatMassReplyComponent.prototype.postMassReplyByHotKey = function (event, chatMessage, postpone) {
        event.preventDefault();
        this.postMassReply(event, chatMessage, postpone);
    };
    ChatMassReplyComponent.prototype.postMassReply = function (event, chatMessage, postpone) {
        var _this = this;
        event.preventDefault();
        if (!chatMessage) {
            this.growlMessageService.error("Введите сообщение");
            return;
        }
        var reply = function (message) {
            _this.chatMessagesDataservice.massReply(message, postpone, _this._ticketsIdsGroupedByPanels);
        };
        this.chatMessagesDataservice.processReply = true;
        reply(chatMessage);
    };
    ChatMassReplyComponent.prototype.isProcessReply = function () {
        return this.chatMessagesDataservice.isProcessReply();
    };
    ChatMassReplyComponent.prototype.scrollTop = function () {
        window.scrollTo(0, 0);
    };
    ChatMassReplyComponent.prototype.quotation = function (text) {
        if (text === void 0) { text = this.quoteText; }
        text = this.decodeText(text.split("\n").map(function (s) { return "> " + s; }).join("\n"));
        this.chatTextareaDirective.insertText(text + "\n\n__cursorPosition__", null, "__cursorPosition__", true);
        this.chatTextareaDirective.focus();
        $('.quote-block').css('visibility', 'hidden');
        return false;
    };
    ChatMassReplyComponent.prototype.decodeText = function (html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    };
    ChatMassReplyComponent.prototype.selectQuote = function () {
        var _this = this;
        setTimeout(function () {
            var selection = window.getSelection();
            var quoteText = selection.toString();
            if (quoteText) {
                var range = selection.getRangeAt(0);
                if (range.toString()) {
                    _this.quoteText = quoteText;
                    _this.setQuoteBlockPosition(range);
                    return;
                }
            }
            $('.quote-block').css('visibility', 'hidden');
        }, 10);
    };
    ChatMassReplyComponent.prototype.setQuoteBlockPosition = function (range) {
        var position = range.getBoundingClientRect();
        var scrollTop = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop;
        var topOffset = 25;
        var leftOffset = 90;
        var top = position.top + scrollTop - topOffset;
        var left = position.left + (position.width - leftOffset) / 2;
        $(".quote-block").offset({ top: top, left: left }).css('visibility', 'visible');
    };
    Object.defineProperty(ChatMassReplyComponent.prototype, "panelPrefixForAllTickets", {
        get: function () {
            return this._panelPrefixForAllTickets;
        },
        enumerable: true,
        configurable: true
    });
    return ChatMassReplyComponent;
}());
export { ChatMassReplyComponent };
