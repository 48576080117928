import {Injectable} from "@angular/core";
import {SystemInfo, SystemInfoStore} from "../../core/store/system-info.store";
import {DepartmentsStore} from "./departments.store";
import {Statuses, StatusesStore} from "./statuses.store";
import {SidebarFilterStore} from "./sidebar-filter.store";
import {TicketStore} from "./ticket.store";
import {TicketListStore} from "./ticket-list.store";
import {MassReplyTicketListStore} from "./mass-reply-ticket-list.store";

@Injectable()
export class Store {

    constructor(private _systemInfoStore: SystemInfoStore,
                private _departmentsStore: DepartmentsStore,
                private _statusesStore: StatusesStore,
                private _ticketListStore: TicketListStore,
                private _massReplyTicketListStore: MassReplyTicketListStore,
                private _sidebarFilterStore: SidebarFilterStore,
                private _ticketStore: TicketStore) {
    }

    get systemInfo(): SystemInfo {
        return this._systemInfoStore.value;
    }

    get departments(): DepartmentsStore {
        return this._departmentsStore;
    }

    get tickets(): TicketListStore {
        return this._ticketListStore;
    }

    get massReplyTickets(): MassReplyTicketListStore {
        return this._massReplyTicketListStore;
    }

    get statuses(): Statuses {
        return this._statusesStore.value;
    }

    get sidebarFilter(): SidebarFilterStore {
        return this._sidebarFilterStore;
    }

    get ticketData(): TicketStore {
        return this._ticketStore;
    }
}
