
        <ul [ngClass]="{'ui-widget-content ui-corner-all ui-shadow ui-submenu-list': !root}" class="ui-menu-list"
            (click)="listClick($event)">
            <ng-template ngFor let-child [ngForOf]="(root ? item : item.items)">
                <li *ngIf="child.separator" class="ui-menu-separator ui-widget-content">
                <li *ngIf="!child.separator" #listItem
                    [ngClass]="{'ui-menuitem ui-widget ui-corner-all':true,'ui-menuitem-active':listItem==activeItem}"
                    [class]="child.styleClass" [ngStyle]="child.style"
                    (mouseenter)="onItemMouseEnter($event, listItem, child)" (mouseleave)="onItemMouseLeave($event)">
                    <a *ngIf="!child.routerLink" [href]="child.url||'#'" class="ui-menuitem-link ui-corner-all"
                       [attr.target]="child.target" [attr.title]="child.title" [attr.id]="child.id"
                       [ngClass]="{'ui-state-disabled':child.disabled}" (click)="itemClick($event, child)">
                        <span class="ui-menuitem-icon fa fa-fw" *ngIf="child.icon" [ngClass]="child.icon"></span>
                        <span class="ui-menuitem-text">{{child.label}}</span>
                        <span class="ui-submenu-icon fa fa-fw fa-caret-right" *ngIf="child.items"></span>
                    </a>
                    <a *ngIf="child.routerLink" [routerLink]="child.routerLink" [queryParams]="child.queryParams"
                       [routerLinkActive]="'ui-state-active'"
                       [routerLinkActiveOptions]="child.routerLinkActiveOptions||{exact:false}" [href]="child.url||'#'"
                       class="ui-menuitem-link ui-corner-all" [attr.target]="child.target" [attr.title]="child.title"
                       [attr.id]="child.id"
                       [ngClass]="{'ui-state-disabled':child.disabled}" (click)="itemClick($event, child)">

                        <span class="ui-menuitem-icon fa fa-fw" *ngIf="child.icon" [ngClass]="child.icon"></span>
                        <span class="ui-menuitem-text">{{child.label}}</span>
                        <span class="ui-submenu-icon fa fa-fw fa-caret-right" *ngIf="child.items"></span>
                    </a>
                    <p-tieredMenuSub-relative class="ui-submenu" [item]="child" *ngIf="child.items"
                                              [baseZIndex]="baseZIndex" [generalCommand]="generalCommand"
                                              [autoZIndex]="autoZIndex"></p-tieredMenuSub-relative>
                </li>
            </ng-template>
        </ul>
    