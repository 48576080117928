import {Component, OnDestroy, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AdminService} from "../core/services/admin.service";
import {Subject} from "rxjs";
import {LoginDataservice} from "../core/data/login.dataservice";
import {GrowlMessageService} from "../core/services/growl-message.service";
import {Store} from "../support/store/store";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    moduleId: module.id,
    templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy {
    private ngDestroyed: Subject<void> = new Subject<void>();

    loginForm: FormGroup;

    constructor(private router: Router,
                private adminService: AdminService,
                private loginDataservice: LoginDataservice,
                private growlMessageService: GrowlMessageService,
                private _store: Store,
                private formBuilder: FormBuilder) {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            token: ['', Validators.required]
        });
    }

    ngOnInit() {
        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(data => {
            if (data.user && data.user.username) {
                this.adminService.authorized();
            }
            if (data.error) {
                this.growlMessageService.error(data.error.message);
            }
        });
    }

    get store() {
        return this._store;
    }

    ngOnDestroy(): void {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    }

    submit() {
        this.adminService.login(this.loginForm)
        return false;
    }

    get msgs() {
        return this.growlMessageService.msgs;
    }

    removeGrowlMsg(event) {
        this.growlMessageService.removeMsg(event.message);
    }

    isProcessLogin() {
        return this.loginDataservice.isProcessLogin();
    }
}
