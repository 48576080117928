import {Injectable, OnInit} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {SearchQueryParserService} from "../../shared/search-query-parser.service";
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/map";
import {TicketListDataservice} from "../data/ticket-list.dataservice";
import {Observable} from "rxjs/Observable";
import {AppSettings} from "../../../app-settings";
import {Store} from "../store/store";
import {StatusesStore} from "../store/statuses.store";


@Injectable()
export class SearchService implements OnInit {
    private defaultSearchModel: string = '';
    private _searchModel: string = '';

    private _search: BehaviorSubject<string> = new BehaviorSubject<string>("");
    searchEvents: Observable<string>;

    extendedSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private departmentKey = "отдел";
    private statusKey = "статус";

    private initialized: boolean = false;

    private _timer: number = AppSettings.COUNTDOWN_TIME;

    private _keywords: string[] = [
        '№',
        'от',
        'до',
        'дата',
        'тема',
        'клиент',
        'статус',
        'отдел',
        'панель',
        'назначен',
        'урл',
        'заказ',
    ];

    constructor(private ticketListDataservice: TicketListDataservice,
                private store: Store) {
        this.searchEvents = this._search.asObservable();
        setInterval(() => {
            if (this._timer == 0) {
                this.search(this.searchModel, false);
                return;
            }
            this._timer--;
        }, 1000);
    }

    ngOnInit(): void {
    }

    initSearch(initial?: string) {
        // инициируем первый поиск
        this.clearSearch(initial);
        this._timer = AppSettings.COUNTDOWN_TIME;
    }

    clearSearch(initial?: string) {
        this._searchModel = initial ? initial : this.defaultSearchModel;
        this.search();
    }

    appendSearch(item) {

        // просто добавить строку в конец

        if (!this._searchModel.endsWith(" ")) {
            this._searchModel += " ";
        }
        this._searchModel += item.str + " ";

        this.search();
    }

    get keywords(): string[] {
        return this._keywords;
    }

    get searchModel(): string {
        return this._searchModel;
    }

    set searchModel(value: string) {
        this._searchModel = value;
    }

    get timer() {
        return this._timer;
    }

    public search(str: string = this.searchModel, erase: boolean = true): void {
        let urlRegex = new RegExp('^((http|https)://|(//))[a-z0-9]', 'i');
        if (urlRegex.test(str)) {
            str = str.replace(/[^a-zA-Zа-яА-ЯёЁ0-9]+ */g, ' ');
        }

        this.doSearch(this._searchModel = str, erase);
        this._timer = AppSettings.COUNTDOWN_TIME;
    }

    public parseQuery(searchStr: string): any {
        return SearchQueryParserService.parse(searchStr, {keywords: this.keywords, ranges: []});
    }

    public makeQuery(map: any): string {
        let result = "";
        for (let kw of this.keywords) {
            if (map[kw]) {
                if (map[kw] instanceof Array && map[kw].length > 0) {
                    result += kw + ":" + map[kw].join(",") + " ";
                }
                if (typeof map[kw] === 'string' && map[kw] != "") {
                    result += kw + ":" + map[kw] + " ";
                }
            }
        }
        if (map["текст"]) {
            result += map["текст"] + " ";
        }

        return result;
    }

    private doSearch(searchStr: string, erase: boolean = true) {
        let str = searchStr.trim() ? searchStr : "";

        let selected = "отдел:" + this.store.sidebarFilter.selectedDepartments.map((d) => d.id).join(",") + " " +
            "статус:" + this.store.sidebarFilter.selectedStatuses.map((s) => s.id).join(",") + " ";

        let countInProgress = "отдел:" + this.store.sidebarFilter.selectedDepartments.map((d) => d.id).join(",") + " " +
            "статус:" + StatusesStore.STATUS_IN_PROGRESS.id;

        let parsed = this.parseQuery(searchStr);
        let sideFilters = parsed["№"].length === 0 && parsed["клиент"].length === 0 && !parsed["text"];

        let searchMap = {
            query: sideFilters ? selected + str : str,
            counters: {["" + StatusesStore.STATUS_IN_PROGRESS.id]: countInProgress}
        };
        searchMap["sort"] = {field: this.sortField, order: this.sortOrder};
        this.ticketListDataservice.loadTicketListSubject.next({searchMap: searchMap, erase: erase});
        this._search.next(str);
    }

    sortField: string = "byactivity";
    sortOrder: number = -1;

    setOrder(sortField: string, sortOrder: number) {
        if (this.sortOrder != sortOrder || this.sortField != sortField) {
            this.sortField = "by" + sortField;
            this.sortOrder = sortOrder;
            // let __this = this;
            // setTimeout(function () {
            //     __this.search();
            // }, 100);
        }
    }
}
