import { OnDestroy, OnInit } from "@angular/core";
import { SearchService } from "../../../services/search.service";
import { Subject } from "rxjs";
var TitleSearchComponent = /** @class */ (function () {
    function TitleSearchComponent(searchService) {
        this.searchService = searchService;
        this.ngDestroyed = new Subject();
        var _this = this;
    }
    Object.defineProperty(TitleSearchComponent.prototype, "timer", {
        get: function () {
            return this.searchService.timer;
        },
        enumerable: true,
        configurable: true
    });
    TitleSearchComponent.prototype.toggleExtended = function ($event) {
        this.searchService.extendedSearch.next(!this.extendedSearch);
    };
    TitleSearchComponent.prototype.makeItem = function (key, values) {
        return {
            label: '+' + key + ':',
            items: values.map(function (obj) {
                var value = obj.label || obj;
                return { label: value, data: { key: key, value: value } };
            })
        };
    };
    TitleSearchComponent.prototype.searchChange = function () {
        this.searchApplied = false;
        this.searchService.searchModel = this.searchModel;
    };
    TitleSearchComponent.prototype.searchSubmit = function () {
        this.searchService.search(this.searchModel);
    };
    TitleSearchComponent.prototype.clearSearch = function () {
        this.searchService.clearSearch();
    };
    TitleSearchComponent.prototype.onResize = function ($event) {
        var mq1 = window.matchMedia('screen and (min-width:80rem)');
        this.smallScreen = !mq1.matches;
    };
    TitleSearchComponent.prototype.ngOnInit = function () {
        var _this_1 = this;
        this.parent = jQuery(".titleSearch__container")[0];
        this.searchService.searchEvents.takeUntil(this.ngDestroyed).subscribe(function (data) {
            _this_1.searchModel = data;
            _this_1.searchApplied = true;
        });
        this.searchService.extendedSearch.asObservable().takeUntil(this.ngDestroyed).subscribe(function (data) {
            _this_1.extendedSearch = data;
        });
        this.onResize();
    };
    TitleSearchComponent.prototype.ngOnDestroy = function () {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    };
    return TitleSearchComponent;
}());
export { TitleSearchComponent };
