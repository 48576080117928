import {ErrorHandler, Injectable, Injector} from "@angular/core";
import {HashLocationStrategy, LocationStrategy, PathLocationStrategy} from "@angular/common";
import * as StackTrace from "stacktrace-js";
import {ServerLoggingService} from "./server-logging.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) {
    }

    handleError(error) {
        const loggingService = this.injector.get(ServerLoggingService);
        const location = this.injector.get(LocationStrategy);
        const message = error.message ? error.message : error.toString();
        const url = location instanceof PathLocationStrategy ? location.path() :
            location instanceof HashLocationStrategy ? location.path(true) : '';

        // get the stack trace, lets grab the last 10 stacks only
        StackTrace.fromError(error).then(stackframes => {
            const stackString = stackframes
                .filter((sf) => {
                    let s = sf.toString();
                    return s.indexOf("/core.js") == -1 && s.indexOf("/zone.js") == -1;
                })
                .splice(0, 20)
                .map(function (sf) {
                    return sf.toString();
                }).join('\n');
            // log on the server
            loggingService.log({message, url, stack: stackString});
        });

        throw error;
    }

}
