var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, observable } from "mobx-angular";
import { Status } from "../domain/status.domain";
var Statuses = /** @class */ (function () {
    function Statuses(items) {
        var _this = this;
        this.list = [];
        items && items.forEach(function (item) { return _this.list.push(item); });
    }
    Statuses.prototype.getById = function (id) {
        return this.list.find(function (e) { return e.id == id; });
    };
    return Statuses;
}());
export { Statuses };
var StatusesStore = /** @class */ (function () {
    function StatusesStore() {
        this.value = new Statuses();
        this.set([
            StatusesStore.STATUS_OPENED,
            StatusesStore.STATUS_IN_PROGRESS,
            StatusesStore.STATUS_POSTPONED,
            StatusesStore.STATUS_CLOSED
        ].sort(function (a, b) {
            var x = a.id;
            var y = b.id;
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }));
    }
    StatusesStore.prototype.set = function (statuses) {
        this.value = new Statuses(statuses);
    };
    StatusesStore.prototype.clear = function () {
        this.value = new Statuses();
    };
    StatusesStore.STATUS_OPENED = new Status(1, "Открыт", true, 'Открыть', 'success');
    StatusesStore.STATUS_POSTPONED = new Status(2, "Отложен", false, 'Отложить', 'info');
    StatusesStore.STATUS_CLOSED = new Status(3, "Закрыт", false, 'Закрыть', 'danger');
    StatusesStore.STATUS_IN_PROGRESS = new Status(4, "В работе", false, 'В работу', 'warning');
    __decorate([
        observable,
        __metadata("design:type", Statuses)
    ], StatusesStore.prototype, "value", void 0);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], StatusesStore.prototype, "set", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], StatusesStore.prototype, "clear", null);
    return StatusesStore;
}());
export { StatusesStore };
