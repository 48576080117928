import {Injectable} from "@angular/core";
import {Store} from "../store/store";
import {Ticket} from "../domain/ticket.domain";
import {LiteraDataservice} from "../data/litera.dataservice";
import {Links} from "../../../links";
import {ChatMessagesDataservice} from "../data/chat-messages.dataservice";

export interface CheckData {
    ticket: Ticket,
    text: string,
    id?: number,
    started?: Date,
    onStart?(data: any): any, // свернутый iframe
    onError?(): any, // проверка не началась
    onCheck?(data: any): any, // развернуть iframe
    onSuccess?(text: string): any, // закрыть все и отправить
}

@Injectable()
export class CheckService {

    private checkData?: CheckData;
    private iframeUrl: string;
    private lastState: string;

    constructor(private store: Store,
                private literaDataservice: LiteraDataservice,
                private chatMessagesDataservice: ChatMessagesDataservice) {
        setInterval(() => {
            if (this.isChecking()) {

                this.literaDataservice.result(this.checkData, data => {
                    let litera = $('.litera');
                    switch (data.state) {
                        case 'init': {
                            if (new Date().getTime() - this.checkData.started.getTime() > 10000) {
                                // литера не отвечает, показываем iframe
                                litera.css("opacity", "1");
                            }
                            // ждем
                            break;
                        }
                        case 'check': {
                            litera.css("opacity", "1");
                            //litera.show();
                            break;
                        }
                        case 'success': {
                            this.doSuccess(data.text);
                            break;
                        }
                        default: {
                            this.stop();
                            break;
                        }
                    }
                    this.lastState = data.state;
                });
            }
        }, 1000);
    }

    isChecking(): boolean {
        return this.checkData && this.checkData.id && this.checkData.ticket && this.iframeUrl &&
            this.store.ticketData && this.store.ticketData.ticket &&
            this.checkData.ticket.panelPrefix == this.store.ticketData.ticket.panelPrefix &&
            this.checkData.ticket.mask == this.store.ticketData.ticket.mask
    }

    start(check: CheckData = this.checkData) {
        check.onStart = data => {
            if (data.literaDisable) {
                this.doSuccess();
                return;
            }
            this.checkData.id = data.literaCheckId;
            this.iframeUrl = `${Links.literaCheck}/${data.literaCheckId}`;
            setTimeout(() => {
                let litera = $('.litera');
                litera.show();//?
                litera.css("opacity", "0.01");//?
                litera.find('iframe').attr('src', this.iframeUrl);
            }, 100);
        };

        this.literaDataservice.check(check);
        check.started = new Date();
        this.checkData = check;
    }

    stop() {
        this.checkData = null;
        let litera = $('.litera');
        litera.hide();
        this.chatMessagesDataservice.processReply = false;
    }

    reload() {
        let litera = $('.litera');
        litera.find('iframe').attr('src', this.iframeUrl);
    }

    doSuccess(text: string = this.checkData.text) {
        if (this.checkData) {
            this.checkData.onSuccess(text);
        }
        this.stop();
    }
}
