import {Department} from "./department.domain";
import {Status} from "./status.domain";
import {TicketInfo} from "./ticketInfo.domain";
import {Admin} from "./admin.domain";
import {Tag} from "./tag.domain";
import * as moment from 'moment/moment';
import {Message} from "./message.domain";
import {observable} from "mobx-angular";

export class Ticket {
    id: number;
    version: number;
    mask: string;
    subject: string;
    username: string;
    panelPrefix: string;
    department: Department;
    status: Status;
    date: Date;
    activity: Date;
    activityDiff: string;
    messagesCount: number;
    unreadMessages: number;
    assignedTo: Admin;
    @observable info: TicketInfo;
    updating: boolean;
    opId: number; //todo неплохо было бы сделать отдельной сущностью orderedProduct
    url: string;
    tag: Tag;
    customerNote: string;
    opNote: string;
    adminAnswersNumber: number;
    draftText: string;
    unread: boolean;
    lastMessage?: Message;

    get activityStr() {
        return moment(this.activity).format("DD.MM.YYYY");
    }

    get maskPostfix(): String {
        return (this.adminAnswersNumber > 0 ? " *" : "");
    }
}
