var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, observable } from "mobx-angular";
import { DepartmentsStore } from "app/support/store/departments.store";
import { StatusesStore } from "./statuses.store";
var SidebarFilterStore = /** @class */ (function () {
    function SidebarFilterStore(departmentsStore, statusesStore) {
        var _this = this;
        this.departmentsStore = departmentsStore;
        this.statusesStore = statusesStore;
        // без учета "все" и "только"
        this.departmentsState = new Map();
        // без учета "все" и "только"
        this.statusesState = new Map();
        // выбранное "все" или "только"
        this.overrideDep = null;
        // выбранное "все" или "только"
        this.overrideStat = null;
        this.departmentsStore.active.forEach(function (d) {
            _this.chooseDepartment(d.id, true);
        });
        this.chooseStatus(1, true);
        this.chooseStatus(4, true);
    }
    Object.defineProperty(SidebarFilterStore.prototype, "selectedDepartments", {
        // с учетом "все" и "только"
        get: function () {
            if (this.overrideDep == null) {
                return Array.from(this.departmentsState.values());
            }
            else if (this.overrideDep == 0) {
                return this.departmentsStore.all;
            }
            else {
                return [this.departmentsStore.getById(this.overrideDep)];
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SidebarFilterStore.prototype, "selectedStatuses", {
        // с учетом "все" и "только"
        get: function () {
            if (this.overrideStat == null) {
                return Array.from(this.statusesState.values());
            }
            else if (this.overrideStat == 0) {
                return this.statusesStore.value.list;
            }
            else {
                return [this.statusesStore.value.getById(this.overrideStat)];
            }
        },
        enumerable: true,
        configurable: true
    });
    SidebarFilterStore.prototype.isDepartmentSelected = function (d) {
        return this.selectedDepartments.indexOf(d) >= 0;
    };
    SidebarFilterStore.prototype.isStatusSelected = function (s) {
        return this.selectedStatuses.indexOf(s) >= 0;
    };
    SidebarFilterStore.prototype.chooseDepartment = function (id, choose) {
        if (this.overrideDep != null) {
            this.overrideDep = null;
            return;
        }
        if (choose) {
            this.departmentsState.set("" + id, this.departmentsStore.getById(id));
        }
        else {
            this.departmentsState.delete("" + id);
        }
    };
    SidebarFilterStore.prototype.chooseStatus = function (id, choose) {
        if (this.overrideStat != null) {
            this.overrideStat = null;
            return;
        }
        if (choose) {
            this.statusesState.set("" + id, this.statusesStore.value.getById(id));
        }
        else {
            this.statusesState.delete("" + id);
        }
    };
    SidebarFilterStore.prototype.chooseAllOrOneDepartment = function (id) {
        this.overrideDep = (this.overrideDep == id) ? null : id;
    };
    SidebarFilterStore.prototype.chooseAllOrOneStatus = function (id) {
        this.overrideStat = (this.overrideStat == id) ? null : id;
    };
    Object.defineProperty(SidebarFilterStore.prototype, "isOverridedDep", {
        get: function () {
            return this.overrideDep != null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SidebarFilterStore.prototype, "isOverridedStat", {
        get: function () {
            return this.overrideStat != null;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        observable,
        __metadata("design:type", Map)
    ], SidebarFilterStore.prototype, "departmentsState", void 0);
    __decorate([
        observable,
        __metadata("design:type", Map)
    ], SidebarFilterStore.prototype, "statusesState", void 0);
    __decorate([
        observable,
        __metadata("design:type", Number)
    ], SidebarFilterStore.prototype, "overrideDep", void 0);
    __decorate([
        observable,
        __metadata("design:type", Number)
    ], SidebarFilterStore.prototype, "overrideStat", void 0);
    __decorate([
        computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], SidebarFilterStore.prototype, "selectedDepartments", null);
    __decorate([
        computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], SidebarFilterStore.prototype, "selectedStatuses", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], SidebarFilterStore.prototype, "chooseDepartment", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], SidebarFilterStore.prototype, "chooseStatus", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SidebarFilterStore.prototype, "chooseAllOrOneDepartment", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SidebarFilterStore.prototype, "chooseAllOrOneStatus", null);
    __decorate([
        computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], SidebarFilterStore.prototype, "isOverridedDep", null);
    __decorate([
        computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], SidebarFilterStore.prototype, "isOverridedStat", null);
    return SidebarFilterStore;
}());
export { SidebarFilterStore };
