import { AsideSupportService } from "./aside-support.service";
var AsideSupportComponent = /** @class */ (function () {
    function AsideSupportComponent(asideSupportService) {
        this.asideSupportService = asideSupportService;
        this._tabs = [
            { name: 'info', icon: 'icon-info' },
            { name: 'settings', icon: 'icon-settings' }
        ];
    }
    Object.defineProperty(AsideSupportComponent.prototype, "tabs", {
        get: function () {
            var _this = this;
            return this._tabs.filter(function (tab) {
                return _this.asideSupportService.showTabs.indexOf(tab.name) >= 0;
            });
        },
        enumerable: true,
        configurable: true
    });
    return AsideSupportComponent;
}());
export { AsideSupportComponent };
