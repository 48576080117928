/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../layouts/shared/sidebar.directive";
import * as i2 from "../../../layouts/shared/breadcrumb.component.ngfactory";
import * as i3 from "../../../layouts/shared/breadcrumb.component";
import * as i4 from "@angular/router";
import * as i5 from "./dashboard-layout.component";
var styles_DashboardLayoutComponent = [];
var RenderType_DashboardLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DashboardLayoutComponent, data: {} });
export { RenderType_DashboardLayoutComponent as RenderType_DashboardLayoutComponent };
export function View_DashboardLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "header", [["class", "app-header navbar navbar-inverse bg-inverse"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["class", "navbar-toggler mobile-sidebar-toggler hidden-lg-up"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).toggleOpen($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.MobileSidebarToggleDirective, [], null, null), (_l()(), i0.ɵted(-1, null, ["\u2630"])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "a", [["class", "navbar-brand"], ["href", "#"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(6, 0, null, null, 11, "div", [["class", "app-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "nav", [["class", "sidebar-nav"]], null, null, null, null, null)), i0.ɵncd(null, 1), (_l()(), i0.ɵeld(10, 0, null, null, 7, "main", [["class", "main"], ["style", "overflow-x: hidden; overflow-y: hidden"]], null, null, null, null, null)), i0.ɵncd(null, 2), (_l()(), i0.ɵeld(12, 0, null, null, 2, "ol", [["class", "breadcrumb"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "breadcrumbs", [], null, null, null, i2.View_BreadcrumbsComponent_0, i2.RenderType_BreadcrumbsComponent)), i0.ɵdid(14, 114688, null, 0, i3.BreadcrumbsComponent, [i4.Router, i4.ActivatedRoute], null, null), (_l()(), i0.ɵeld(15, 0, null, null, 2, "div", [["class", "container-fluid content-main"]], null, null, null, null, null)), i0.ɵncd(null, 3), i0.ɵncd(null, 4)], function (_ck, _v) { _ck(_v, 14, 0); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 12, 0, currVal_0); }); }
export function View_DashboardLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dashboard-layout", [], null, null, null, View_DashboardLayoutComponent_0, RenderType_DashboardLayoutComponent)), i0.ɵdid(1, 49152, null, 0, i5.DashboardLayoutComponent, [], null, null)], null, null); }
var DashboardLayoutComponentNgFactory = i0.ɵccf("dashboard-layout", i5.DashboardLayoutComponent, View_DashboardLayoutComponent_Host_0, {}, {}, ["[layout-title]", "[layout-sidebar]", "[layout-subtitle]", "[layout-extended-search]", "[layout-main]"]);
export { DashboardLayoutComponentNgFactory as DashboardLayoutComponentNgFactory };
