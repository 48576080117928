<div layout-growl style="z-index: 9999;position: absolute">
  <p-growl [value]="msgs" [sticky]="true" (onClose)="removeGrowlMsg($event)"></p-growl>
</div>

<chat-layout *ngIf="ticketData == null">
  <form layout-title class="form-title px-h hidden-md-down col">
    <div class="chat-data-title">Загрузка...</div>
  </form>

  <ol layout-subtitle class="breadcrumb hidden-lg-up" style="margin-bottom: 0;">
    <div class="chat-data-title">Загрузка...</div>
  </ol>
</chat-layout>

<chat-layout *ngIf="ticketData != null">

  <form layout-title class="form-title px-h hidden-md-down col">
    <div class="chat-data-title">
      <span class="badge badge-{{ticketData.ticket.panelPrefix}}">{{ticketData.ticket.panelPrefix}}</span>&nbsp;
      <span (click)="selectTicketMask($event)">{{ticketData.ticket.mask}}</span> &mdash; {{ticketData.ticket.subject}}
    </div>
  </form>

  <ol layout-subtitle class="breadcrumb hidden-lg-up" style="margin-bottom: 0;">
    <div class="chat-data-title">{{ticketData.ticket.mask}} &mdash; {{ticketData.ticket.subject}}</div>
  </ol>

  <ul layout-sidebar class="nav">
    <chat-sidebar></chat-sidebar>
  </ul>

  <aside-support layout-aside></aside-support>

  <div layout-main class="animated fadeIn fast">
    <div class="chat-pad mx-auto">

      <div class="chat">
        <div class="chat-history">
          <div class="chat-ul">

            <div file-drop class="chat-message clearfix">
              <form (submit)="postMessage($event, chatMessage.value, true)" class="message__form">
                
                <input type="hidden" #chatMessageId value="">
                
                <chat-top-controls [canAddNote]="false"
                                   [ticketPanel]="ticketData.ticket.panelPrefix">
                </chat-top-controls>
                
                <div class="createTicket-panel-user-subject">
                  <div class="d-flex flex-row" style="margin-bottom: 2px">
                    <div class="ui-inputgroup">
                      <p-dropdown [options]="panels"
                                  [ngModel]="panelPrefix"
                                  [placeholder]=""
                                  [ngModelOptions]="{standalone: true}"
                                  (onChange)="createTicketInPanel($event.value)">
                      </p-dropdown>
                    </div>
                    <div class="ui-inputgroup">
                      <input pInputText class="chat-message-person"
                             placeholder="aXXXXX"
                             [(ngModel)]="ticketData.ticket.username"
                             [ngModelOptions]="{standalone: true}"
                             (blur)="showNotificationSettings()"/>
                    </div>
                    <div class="ui-inputgroup" style="flex:1">
                      <input pInputText class="chat-message-subject" #chatMessageSubject
                             placeholder="Введите тему письма"
                             [(ngModel)]="ticketData.ticket.subject"
                             [ngModelOptions]="{standalone: true}"/>
                    </div>
                  </div>
                </div>
                
                <textarea chatTextarea #chatMessage name="message-to-send" id="message-to-send"
                          placeholder=""
                          (keydown.meta.enter)="postMessageByHotKey($event, chatMessage.value, false);"
                          (keydown.control.enter)="postMessageByHotKey($event, chatMessage.value, false);"
                          rows="5" [maxHeight]="400" autoResize="autoResize"></textarea>

                <div class="createTicket-actions">
                  <br/>
                  <span>Прикрепить заметку:</span>
                  <textarea pInputTextarea2 #chatNote name="note-to-send" id="note-to-send"
                            placeholder=""
                            (keydown.meta.enter)="postMessageByHotKey($event, chatMessage.value, false);"
                            (keydown.control.enter)="postMessageByHotKey($event, chatMessage.value, false);"
                            rows="3"></textarea>

                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox"
                             (change)="invertIsSendSms()"
                             [checked]="isSendSms"
                             [disabled]="isAbuse || !phone">
                      <span>Отправить SMS {{phone ? "на "+ phone : "(телефон не подтверждён)" }}</span>&nbsp;
                    </label>
                    <span *ngIf="phone" class="pseudo-link" style="color:inherit" (click)="invertSendSmsNow()">{{time}}</span>
                  </div>
                  <div>
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox"
                             (change)="changeSendEmail()"
                             [checked]="isSendEmail"
                             [disabled]="!customerEmail">
                      <span>Отправить на email: {{customerEmail ? customerEmail : "(e-mail не подтверждён)"}}</span>
                    </label>
                  </div>
                </div>
                <br/>

                <chat-attachments></chat-attachments>

                <div class="createTicket-sent-buttons">
                  <div class="pull-right">
                    <div style="display: inline-block" [class.hidden]="isProcessReply()">
                      <a class="reply-submit-not-pending pseudo-link"
                         (click)="postMessage($event, chatMessage.value, null);">
                        Добавить ответ
                      </a>
                      <span>&nbsp;или&nbsp;</span>
                      <a class="reply-submit-not-pending pseudo-link"
                         (click)="postMessage($event, chatMessage.value, true);">
                        Отправить и отложить</a>
                      <span>&nbsp;или&nbsp;</span>
                    </div>
                    <button type="button" (click)="postMessage($event, chatMessage.value, false);"
                            class="btn btn-primary reply-submit" [disabled]="isProcessReply()">
                      {{isProcessReply() ? 'Отправляется...': 'Отправить в работу'}}
                    </button>
                  </div>
                </div>
                
              </form>
            </div>
            <div style="height: 20px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</chat-layout>


<div class="litera" layout-modal-backdrop style="display: none" *ngIf="literaIframe">
  <iframe frameborder="0" class="iframe-litera"></iframe>
  <div class="iframe-litera-close">
    <a class="pseudo-link correction-skip" style="float: left; margin-top: 1em" (click)="sendMsgWithoutCheck()">
      Отправить без проверки
    </a>
    <span style="float: right" class="fa fa-times fa-2x pointer" (click)="closeIframe()"></span>
    <span style="float: right;margin-right: 10px;" class="fa fa-refresh fa-2x pointer" (click)="reloadIframe()"></span>
  </div>
</div>
