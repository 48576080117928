<header class="app-header navbar navbar-inverse bg-inverse">
  <button class="navbar-toggler mobile-sidebar-toggler hidden-lg-up" type="button">&#9776;</button>
  <a class="navbar-brand" href="#"></a>
  <ng-content select="[layout-title]"></ng-content>
</header>

<div class="app-body">
  <div class="sidebar">
    <nav class="sidebar-nav">
      <ng-content select="[layout-sidebar]"></ng-content>
    </nav>
  </div>

  <!-- Main content -->
  <main class="main" style="overflow-x: hidden; overflow-y: hidden">

    <ng-content select="[layout-subtitle]"></ng-content>

    <ol class="breadcrumb" [hidden]="true">
      <breadcrumbs></breadcrumbs>
    </ol>

    <div class="container-fluid content-main" >
      <ng-content select="[layout-extended-search]"></ng-content>
      <ng-content select="[layout-main]"></ng-content>
    </div>

  </main>
</div>
