import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {LaddaModule} from "angular2-ladda/module/module";
import {TabsModule} from "ngx-bootstrap/tabs";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    DataTableModule,
    DialogModule,
    DropdownModule,
    GrowlModule,
    InputTextareaModule,
    SplitButtonModule
} from "primeng/primeng";
import {MomentModule} from "angular2-moment";
import {LinkyModule} from "angular-linky";
import {DropdownEditorComponent} from "./primeng/dropdown-editor.component";
import {TieredMenuRelative, TieredMenuSubRelative} from "./primeng/tieredmenu-relative.component";
import {TieredMenuModule} from "primeng/components/tieredmenu/tieredmenu";
import {InputTextarea2Directive} from "./primeng/input-textarea2.directive";
import {MarkdownComponent, MarkdownModule} from "angular2-markdown";
import {MultiSelectModule} from "primeng/components/multiselect/multiselect";
import {PluralPipe} from "./pipes/plural.pipe";
import {TableModule} from "primeng/table";
import {TooltipDirective} from "./jquery/tooltip.directive";
import {TooltipComponent} from "./jquery/tooltip.component";
import {FileDropDirective} from "./file-drop.directive";
// import {MultiSelectModule} from 'primeng/multiselect';
/* Здесь аггрегируем импорты виджетов и кладем общие компоненты (Cookbook/NgModule/SharedModule) */

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        LaddaModule,
        ButtonModule,
        DataTableModule,
        DropdownModule,
        DialogModule,
        InputTextareaModule,
        TableModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        MarkdownModule.forRoot(),
        MomentModule,
        LinkyModule,
        TieredMenuModule,
        SplitButtonModule,
        GrowlModule,
        MultiSelectModule,
        CalendarModule,
        CheckboxModule
    ],
    declarations: [
        DropdownEditorComponent,
        TieredMenuRelative,
        TieredMenuSubRelative,
        InputTextarea2Directive,
        PluralPipe,
        TooltipComponent,
        TooltipDirective,
        FileDropDirective,
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        LaddaModule,
        ButtonModule,
        DataTableModule,
        DropdownModule,
        DialogModule,
        InputTextareaModule,
        TableModule,
        TabsModule,
        BsDropdownModule,
        MomentModule,
        LinkyModule,
        TieredMenuModule,
        SplitButtonModule,
        MarkdownModule,
        GrowlModule,
        MultiSelectModule,
        CalendarModule,
        CheckboxModule,
        FileDropDirective,

        DropdownEditorComponent,
        TieredMenuRelative,
        InputTextarea2Directive,
        MarkdownComponent,
        PluralPipe,
        TooltipComponent,
        TooltipDirective,
    ]
})
export class SharedModule {
}
