export class Admin {
    id: number;
    username: string;
    userRealName: string;

    get label(): string {
        return "" + this.userRealName;
    }

    get value(): Admin {
        return this;
    }

    constructor(id: number, username: string, userRealName: string) {
        this.id = id;
        this.username = username;
        this.userRealName = userRealName;
    }
}
