import {Injectable} from "@angular/core";
import {action, computed, observable} from "mobx-angular";
import {Department} from "../domain/department.domain";
import {Status} from "../domain/status.domain";
import {DepartmentsStore} from "app/support/store/departments.store";
import {StatusesStore} from "./statuses.store";


@Injectable()
export class SidebarFilterStore {

    // без учета "все" и "только"
    @observable
    departmentsState: Map<string, Department> = new Map();

    // без учета "все" и "только"
    @observable
    statusesState: Map<string, Status> = new Map();

    // выбранное "все" или "только"
    @observable
    overrideDep: number = null;

    // выбранное "все" или "только"
    @observable
    overrideStat: number = null;

    constructor(private departmentsStore: DepartmentsStore,
                private statusesStore: StatusesStore) {
        this.departmentsStore.active.forEach((d) => {
            this.chooseDepartment(d.id, true);
        });
        this.chooseStatus(1, true);
        this.chooseStatus(4, true);
    }


    // с учетом "все" и "только"
    @computed
    get selectedDepartments(): Array<Department> {
        if (this.overrideDep == null) {
            return Array.from(this.departmentsState.values());
        } else if (this.overrideDep == 0) {
            return this.departmentsStore.all;
        } else {
            return [this.departmentsStore.getById(this.overrideDep)];
        }
    }

    // с учетом "все" и "только"
    @computed
    get selectedStatuses(): Array<Status> {
        if (this.overrideStat == null) {
            return Array.from(this.statusesState.values());
        } else if (this.overrideStat == 0) {
            return this.statusesStore.value.list;
        } else {
            return [this.statusesStore.value.getById(this.overrideStat)];
        }
    }

    isDepartmentSelected(d: Department): boolean {
        return this.selectedDepartments.indexOf(d) >= 0;
    }

    isStatusSelected(s: Status): boolean {
        return this.selectedStatuses.indexOf(s) >= 0;
    }

    @action
    chooseDepartment(id, choose) {
        if (this.overrideDep != null) {
            this.overrideDep = null;
            return;
        }
        if (choose) {
            this.departmentsState.set("" + id, this.departmentsStore.getById(id));
        } else {
            this.departmentsState.delete("" + id);
        }
    }

    @action
    chooseStatus(id, choose) {
        if (this.overrideStat != null) {
            this.overrideStat = null;
            return;
        }
        if (choose) {
            this.statusesState.set("" + id, this.statusesStore.value.getById(id));
        } else {
            this.statusesState.delete("" + id);
        }
    }

    @action
    chooseAllOrOneDepartment(id) {
        this.overrideDep = (this.overrideDep == id) ? null : id;
    }

    @action
    chooseAllOrOneStatus(id) {
        this.overrideStat = (this.overrideStat == id) ? null : id;
    }

    @computed
    get isOverridedDep() {
        return this.overrideDep != null;
    }

    @computed
    get isOverridedStat() {
        return this.overrideStat != null;
    }
}
