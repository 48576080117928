import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {LoginDataservice} from "../data/login.dataservice";
import {Admin} from "../../support/domain/admin.domain";
import {FormGroup} from "@angular/forms";

@Injectable()
export class AdminService {
    private _currentLoggedInAdmin: Admin;
    private lastRoute = '/support';
    private lastParams = {};

    constructor(private router: Router,
                private loginDataservice: LoginDataservice) {
        loginDataservice.userInfo.subscribe((data) => {
            this._currentLoggedInAdmin = data.user;
        })
    }

    get currentLoggedInAdmin() {
        return this._currentLoggedInAdmin
    }

    username(): string {
        return this._currentLoggedInAdmin ? this._currentLoggedInAdmin.username : ''
    }

    login(form: FormGroup) {
        this.loginDataservice.login(form);
        return false
    }

    logout() {
        this.lastRoute = '/support';
        this.loginDataservice.logout();
        this.router.navigate(['/login']);
        return false
    }

    unauthorized() {
        if (this.router.url.startsWith("/support/")) {
            const urlTree = this.router.parseUrl(this.router.url);
            const urlWithoutParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
            this.lastRoute = urlWithoutParams;
            this.lastParams = urlTree.queryParams;
        }
        this.loginDataservice.loadUserInfo();
        this.router.navigate(['/login']);
        return false
    }

    authorized() {
        this.router.navigate([this.lastRoute], {queryParams: this.lastParams});
        return false
    }

}
