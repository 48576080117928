import {Component, OnDestroy, OnInit} from "@angular/core";
import {ChatMessagesDataservice} from "../../../data/chat-messages.dataservice";
import {Subject} from "rxjs/Subject";
import {NavigationService} from "../../../services/navigation.service";
import {Store} from "../../../store/store";

@Component({
    selector: 'chat-sidebar',
    templateUrl: './chat-sidebar.component.html'
})
export class ChatSidebarComponent implements OnInit, OnDestroy {

    private ngDestroyed: Subject<void> = new Subject<void>();

    constructor(private chatMessagesDataservice: ChatMessagesDataservice,
                private navigationService: NavigationService,
                private _store: Store) {
    }

    get store(): Store {
        return this._store;
    }

    get navigation() {
        return this.navigationService;
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    }
}
