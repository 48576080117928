import {Injectable} from "@angular/core";
import {Links} from "../../../links";
import {HttpClient} from "@angular/common/http";
import {SystemInfoStore} from "../store/system-info.store";

@Injectable()
export class SystemInfoDataservice {

    constructor(private http: HttpClient,
                private systemInfoStore: SystemInfoStore) {
    }

    loadSystemInfo() {
        this.systemInfoStore.clear();
        return this.http.post(Links.systemInfo, {}).filter((resp: Response) => {
            if (resp["error"]) {
                console.log("Could not load system info. " + resp["error"].message);
                return false;
            }
            return true;
        }).map((resp: Response) => {
            return {
                gitInfo: resp["gitInfo"],
                built: resp["built"],
                started: resp["started"],
                reCaptchaSiteKey: resp["reCaptchaSiteKey"],
                translateEnabled: resp["translateEnabled"],
            };
        }).subscribe(data => {
            this.systemInfoStore.set(data);
        }, error => {
            console.log("Could not load system info");
            console.log(error);
        });
    }
}
