var Status = /** @class */ (function () {
    function Status(id, name, selected, action, color) {
        this.id = id;
        this.name = name;
        this.selected = selected;
        this.action = action;
        this.color = color;
    }
    Object.defineProperty(Status.prototype, "label", {
        get: function () {
            return this.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Status.prototype, "value", {
        get: function () {
            return this;
        },
        enumerable: true,
        configurable: true
    });
    return Status;
}());
export { Status };
