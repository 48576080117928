var Department = /** @class */ (function () {
    function Department(id, name, active) {
        this.id = id;
        this.name = name;
        this.active = active;
    }
    Object.defineProperty(Department.prototype, "label", {
        get: function () {
            return this.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Department.prototype, "value", {
        get: function () {
            return this;
        },
        enumerable: true,
        configurable: true
    });
    return Department;
}());
export { Department };
