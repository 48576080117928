import {Component, OnDestroy, OnInit} from "@angular/core";
import {LoginDataservice} from "../../../../core/data/login.dataservice";
import {Subject} from "rxjs/Subject";
import {AppSettings} from "../../../../../app-settings";
import {SearchService} from "app/support/services/search.service";
import * as moment from 'moment/moment';
import {DateUtil} from "../../../../date-util";

@Component({
    selector: '[extended-search]',
    templateUrl: './extended-search.component.html'
})
export class ExtendedSearchComponent implements OnInit, OnDestroy {

    private ngDestroyed: Subject<void> = new Subject<void>();

    calendar = AppSettings.CUSTOM_CALENDAR;

    private _panelList: any[];

    ticketMask: string = "";
    username: string = "";
    dateFrom: Date = null;
    dateTo: Date = null;
    panel: string[];
    keywords: string = "";

    constructor(private loginDataservice: LoginDataservice,
                private searchService: SearchService) {
    }

    ngOnInit() {
        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(data =>
            this._panelList = data.panels.map(p => ({"label": p, "value": p}))
        );
        this.searchService.searchEvents.takeUntil(this.ngDestroyed).subscribe(data => {
            let map = this.searchService.parseQuery(data);
            this.ticketMask = map["№"];
            this.username = map["клиент"];

            this.dateFrom = (map["от"].length > 0) ? DateUtil.parseDate(map["от"][0], "DD.MM.YYYY") : null;
            this.dateTo = (map["до"].length > 0) ? DateUtil.parseDate(map["до"][0], "DD.MM.YYYY") : null;

            this.panel = map["панель"];
            this.keywords = map["text"];
        });
    }

    ngOnDestroy(): void {
    }

    get panelList(): any[] {
        return this._panelList
    }

    filter(event: Event = null, isSubmit: boolean = false): void {
        let queryParamsMap = {};
        if (this.ticketMask) {
            queryParamsMap["№"] = this.ticketMask;
        }
        if (this.username) {
            queryParamsMap["клиент"] = this.username;
        }
        if (this.dateFrom) {
            queryParamsMap["от"] = moment(this.dateFrom).format("DD.MM.YYYY");
        }

        if (this.dateTo) {
            queryParamsMap["до"] = moment(this.dateTo).format("DD.MM.YYYY");
        }

        if (this.panel) {
            queryParamsMap["панель"] = this.panel;
        }
        if (this.keywords) {
            queryParamsMap["текст"] = this.keywords.replace(/[^a-zA-Zа-яА-ЯёЁ0-9]+ */g, ' ');
        }

        this.searchService.searchModel = this.searchService.makeQuery(queryParamsMap);

        if (isSubmit) {
            this.searchService.search();
            return;
        }
    }
}
