import { Router } from "@angular/router";
import { LoginDataservice } from "../data/login.dataservice";
var AdminService = /** @class */ (function () {
    function AdminService(router, loginDataservice) {
        var _this = this;
        this.router = router;
        this.loginDataservice = loginDataservice;
        this.lastRoute = '/support';
        this.lastParams = {};
        loginDataservice.userInfo.subscribe(function (data) {
            _this._currentLoggedInAdmin = data.user;
        });
    }
    Object.defineProperty(AdminService.prototype, "currentLoggedInAdmin", {
        get: function () {
            return this._currentLoggedInAdmin;
        },
        enumerable: true,
        configurable: true
    });
    AdminService.prototype.username = function () {
        return this._currentLoggedInAdmin ? this._currentLoggedInAdmin.username : '';
    };
    AdminService.prototype.login = function (form) {
        this.loginDataservice.login(form);
        return false;
    };
    AdminService.prototype.logout = function () {
        this.lastRoute = '/support';
        this.loginDataservice.logout();
        this.router.navigate(['/login']);
        return false;
    };
    AdminService.prototype.unauthorized = function () {
        if (this.router.url.startsWith("/support/")) {
            var urlTree = this.router.parseUrl(this.router.url);
            var urlWithoutParams = urlTree.root.children['primary'].segments.map(function (it) { return it.path; }).join('/');
            this.lastRoute = urlWithoutParams;
            this.lastParams = urlTree.queryParams;
        }
        this.loginDataservice.loadUserInfo();
        this.router.navigate(['/login']);
        return false;
    };
    AdminService.prototype.authorized = function () {
        this.router.navigate([this.lastRoute], { queryParams: this.lastParams });
        return false;
    };
    return AdminService;
}());
export { AdminService };
