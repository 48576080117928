import {Injectable} from "@angular/core";
import {action, computed, observable} from "mobx-angular";
import {Ticket} from "../domain/ticket.domain";
import {Subject} from "rxjs/Subject";
import {Observable} from "rxjs/Observable";

export interface TicketList {
    tickets: Ticket[],
    count?: number,
    counters?: any,
    loading: boolean
}

@Injectable()
export class TicketListStore {
    private _changed: Subject<TicketList> = new Subject<TicketList>();
    public changed: Observable<TicketList> = this._changed.asObservable();

    @observable
    value: TicketList = {tickets: [], loading: true};

    constructor() {
    }

    @action
    set(ticketList: TicketList) {
        this.value = ticketList;
        this._changed.next(ticketList);
    }

    @action
    clear() {
        this.value = {tickets: [], loading: true};
        this._changed.next(this.value);
    }

    @computed
    get all(): Ticket[] {
        if (this.value == null) {
            return null;
        }
        return this.value.tickets;
    }

    @computed
    get countAll(): number {
        if (this.value == null) {
            return 0;
        }
        return this.value.tickets.length;
    }

    @computed
    get loading(): boolean {
        if (this.value == null) {
            return true;
        }
        return this.value.loading;
    }

    @computed
    get counters(): any {
        if (!this.value || !this.value.counters) {
            return {};
        }
        return this.value.counters;
    }

}
