import {Directive, ElementRef} from "@angular/core";
import {InputTextarea2Directive} from "../../../../shared/primeng/input-textarea2.directive";
import {ChatMarkdownService} from "../../../services/chat-markdown.service";
import {ChatTextareaEventbus} from "./chat-textarea.eventbus";
import {Subject} from "rxjs/Subject";

declare var $: any;

@Directive({
    selector: '[chatTextarea]',
    host: {
        '[class.ui-inputtext]': 'true',
        '[class.ui-corner-all]': 'true',
        '[class.ui-state-default]': 'true',
        '[class.ui-widget]': 'true',
        '[class.ui-state-filled]': 'filled',
        '[attr.data-provide]': '"markdown"'
    }
})
export class ChatTextareaDirective extends InputTextarea2Directive {

    public onShow: Subject<any> = new Subject<any>();

    constructor(public el: ElementRef,
                private chatMarkdownService: ChatMarkdownService,
                chatTextareaEventbus: ChatTextareaEventbus) {
        super(el);

        // публикуемся
        chatTextareaEventbus.chatTextareaBehaviorSubject.next(this);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        this.chatMarkdownService.initTextareaEditor(this);
    }

    get textArea() {
        return this.el.nativeElement;
    }

    setSelectionRange(el: HTMLTextAreaElement, start: number, end: number) {
        // если сделать setSelectionRange на невидимом textarea, то в firefox падает NS_ERROR_FAILURE
        // поэтому откладываем это до появления
        if ($(el).is(':visible')) {
            el.setSelectionRange(start, end);
        } else {
            setTimeout(() => this.setSelectionRange(el, start, end), 200);
        }
    }

    emptyText() {
        this.textArea.value = "";
        this.setSelectionRange(this.textArea, 0, 0);
        this.resize();
    }

    get cursorPositionMarker() {
        return "__cursorPosition__";
    }

    get textareaSelectionStart() {
        return this.textArea.selectionStart;
    }

    get textareaSelectionEnd() {
        return this.textArea.selectionEnd;
    }

    get initMessageText() {
        return `Здравствуйте.\n\n${this.cursorPositionMarker}`;
    }

    initMessage(message: string = "", hasAdminMsg: boolean = false) {
        this.emptyText();
        if (!message) {
            message = hasAdminMsg ? "" : this.initMessageText
        }
        this.insertTextWithCursorPosition(message);
        setTimeout(() => this.resize(), 10)
    }

    getText(): string {
        return this.textArea.value;
    }

    focus() {
        this.textArea.focus();
    }

    blur() {
        this.textArea.blur();
    }

    insertTextWithCursorPosition(text: string, position?: number) {
        this.insertText(text, position, this.cursorPositionMarker, true);
    }

    insertTextWithPlaceholder(text: string, position?: number) {
        this.insertText(text, position, "XXXXXXXXXXX", false);
    }

    insertText(text: string, position?: number, select?: string, emptySelection?: boolean) {
        let message: string = this.textArea.value;

        text = text.replace(/\r\n/g, "\n");

        let positionBegin = position;
        let positionEnd = position;

        // если position = null, то вставляем в текущее выделение
        if (position == null) {
            positionBegin = this.textArea.selectionStart;
            positionEnd = this.textArea.selectionEnd;
        }

        let begin: string = message.substring(0, positionBegin);
        let end: string = message.substring(positionEnd);

        let newText = begin + text + end;

        let selectionBegin = positionBegin + text.length;
        let selectionEnd = positionBegin + text.length;

        if (select != null) {
            select = select.replace(/\r\n/g, "\n");

            // ищем плейсхолдер
            selectionBegin = newText.indexOf(select, positionBegin);
            if (selectionBegin >= 0) {
                selectionEnd = selectionBegin + select.length;
            } else {
                selectionBegin = positionBegin + text.length;
                selectionEnd = selectionBegin;
            }

            // вырезаем, если надо и есть что вырезать
            if (emptySelection && selectionBegin != selectionEnd) {
                begin = newText.substring(0, selectionBegin);
                end = newText.substring(selectionEnd);
                newText = begin + end;
                selectionEnd = selectionBegin;
            }
        }

        this.textArea.value = newText;
        this.setSelectionRange(this.textArea, selectionBegin, selectionEnd);
        this.resize();
    }

}
