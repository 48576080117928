/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "mobx-angular/dist/directives/mobx-autorun.directive";
import * as i4 from "./sidebar-support.component";
import * as i5 from "../../../../core/services/admin.service";
import * as i6 from "../../../services/search.service";
import * as i7 from "../../../services/ticket.service";
import * as i8 from "../../../data/chat-messages.dataservice";
import * as i9 from "../../../store/store";
import * as i10 from "../../../store/sidebar-filter.store";
var styles_SidebarSupportComponent = [];
var RenderType_SidebarSupportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarSupportComponent, data: {} });
export { RenderType_SidebarSupportComponent as RenderType_SidebarSupportComponent };
function View_SidebarSupportComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "button", [["class", "departmentSelect btn btn-block text-left rounded fast"]], [[2, "departmentSelected", null], [2, "btn-primary", null], [2, "btn-default", null], [2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chooseDepartment(_v.context.$implicit.id, !_co.store.sidebarFilter.isDepartmentSelected(_v.context.$implicit)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "icon-folder departmentSelect__item"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0 "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "departmentName"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "pointer only-one"]], [[2, "only-one-select", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chooseAllOrOneDepartment(_v.context.$implicit.id, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0442\u043E\u043B\u044C\u043A\u043E"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.store.sidebarFilter.isDepartmentSelected(_v.context.$implicit); var currVal_1 = _co.store.sidebarFilter.isDepartmentSelected(_v.context.$implicit); var currVal_2 = !_co.store.sidebarFilter.isDepartmentSelected(_v.context.$implicit); var currVal_3 = _co.store.sidebarFilter.isOverridedDep; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_4); var currVal_5 = (_v.context.$implicit.id == _co.store.sidebarFilter.overrideDep); _ck(_v, 5, 0, currVal_5); }); }
function View_SidebarSupportComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "departments"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "button", [["class", "departmentSelect btn btn-block text-left rounded fast"]], [[2, "departmentSelected", null], [2, "btn-primary", null], [2, "btn-default", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chooseAllOrOneDepartment(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "icon-folder"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0 "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "departmentName"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0412\u0441\u0435 \u043E\u0442\u0434\u0435\u043B\u044B"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSupportComponent_2)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.store.departments.active; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.store.sidebarFilter.overrideDep == 0); var currVal_1 = (_co.store.sidebarFilter.overrideDep == 0); var currVal_2 = (_co.store.sidebarFilter.overrideDep != 0); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_SidebarSupportComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-danger pull-right anti-only-one"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.store.tickets.counters[("" + _v.parent.context.$implicit.id)]; _ck(_v, 1, 0, currVal_0); }); }
function View_SidebarSupportComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "button", [["class", "statusSelect btn btn-block text-left rounded fast"]], [[2, "statusSelected", null], [2, "btn-success", null], [2, "btn-default", null], [2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chooseStatus(_v.context.$implicit.id, !_co.store.sidebarFilter.isStatusSelected(_v.context.$implicit)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "pointer only-one"]], [[2, "only-one-select", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chooseAllOrOneStatus(_v.context.$implicit.id, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0442\u043E\u043B\u044C\u043A\u043E"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSupportComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "icon-tag statusSelect__item"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0 "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "statusName"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_5 = (_co.store.tickets.counters[("" + _v.context.$implicit.id)] > 0); _ck(_v, 4, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.store.sidebarFilter.isStatusSelected(_v.context.$implicit); var currVal_1 = _co.store.sidebarFilter.isStatusSelected(_v.context.$implicit); var currVal_2 = !_co.store.sidebarFilter.isStatusSelected(_v.context.$implicit); var currVal_3 = _co.store.sidebarFilter.isOverridedStat; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_v.context.$implicit.id == _co.store.sidebarFilter.overrideStat); _ck(_v, 1, 0, currVal_4); var currVal_6 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_6); }); }
function View_SidebarSupportComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "statuses"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "button", [["class", "statusSelect btn btn-block text-left rounded fast"]], [[2, "statusSelected", null], [2, "btn-success", null], [2, "btn-default", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chooseAllOrOneStatus(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "icon-tag"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0 "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "statusName"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0412\u0441\u0435 \u0441\u0442\u0430\u0442\u0443\u0441\u044B"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSupportComponent_4)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.store.statuses.list; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.store.sidebarFilter.overrideStat == 0); var currVal_1 = (_co.store.sidebarFilter.overrideStat == 0); var currVal_2 = (_co.store.sidebarFilter.overrideStat != 0); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_SidebarSupportComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["href", "/info/version"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["style", "text-align: center;color: gray;font-size: small"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " (", ") "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.store.systemInfo == null) ? null : ((_co.store.systemInfo.gitInfo == null) ? null : _co.store.systemInfo.gitInfo.branch)); var currVal_1 = (((_co.store.systemInfo == null) ? null : ((_co.store.systemInfo.gitInfo == null) ? null : ((_co.store.systemInfo.gitInfo.revision == null) ? null : _co.store.systemInfo.gitInfo.revision.substr(0, 7)))) + "..."); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_SidebarSupportComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 29, "div", [["class", "sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 28, "nav", [["class", "sidebar-nav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 14, "ul", [["class", "nav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 13, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 12, "div", [["class", "dropdown"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "button", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "btn btn-block btn-default dropdown-toggle"], ["data-toggle", "dropdown"], ["id", "profileDropdownMenuButton"], ["type", "button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-user"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" \u00A0\u00A0", "\u00A0\u00A0 "])), (_l()(), i0.ɵeld(8, 0, null, null, 8, "div", [["aria-labelledby", "profileDropdownMenuButton"], ["class", "dropdown-menu dropdown-menu-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 7, "a", [["class", "dropdown-item"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(11, 1), i0.ɵdid(12, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵeld(15, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-sign-out"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u0412\u044B\u0445\u043E\u0434 "])), (_l()(), i0.ɵeld(17, 0, null, null, 2, "div", [["class", "newTicket"], ["style", "padding: 6px 15px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "button", [["class", "btn btn-secondary btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createTicket() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SidebarSupportComponent_1)), i0.ɵdid(21, 212992, null, 0, i3.MobxAutorunDirective, [i0.TemplateRef, i0.ViewContainerRef, i0.Renderer], { mobxAutorun: [0, "mobxAutorun"] }, null), i0.ɵpod(22, { detach: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SidebarSupportComponent_3)), i0.ɵdid(24, 212992, null, 0, i3.MobxAutorunDirective, [i0.TemplateRef, i0.ViewContainerRef, i0.Renderer], { mobxAutorun: [0, "mobxAutorun"] }, null), i0.ɵpod(25, { detach: 0 }), (_l()(), i0.ɵeld(26, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SidebarSupportComponent_6)), i0.ɵdid(28, 212992, null, 0, i3.MobxAutorunDirective, [i0.TemplateRef, i0.ViewContainerRef, i0.Renderer], { mobxAutorun: [0, "mobxAutorun"] }, null), i0.ɵpod(29, { detach: 0 })], function (_ck, _v) { var currVal_3 = _ck(_v, 11, 0, "/logout"); _ck(_v, 10, 0, currVal_3); var currVal_4 = "active"; _ck(_v, 12, 0, currVal_4); var currVal_5 = _ck(_v, 22, 0, true); _ck(_v, 21, 0, currVal_5); var currVal_6 = _ck(_v, 25, 0, true); _ck(_v, 24, 0, currVal_6); var currVal_7 = _ck(_v, 29, 0, true); _ck(_v, 28, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.username; _ck(_v, 7, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 10).target; var currVal_2 = i0.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_1, currVal_2); }); }
export function View_SidebarSupportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sidebar-support", [], null, null, null, View_SidebarSupportComponent_0, RenderType_SidebarSupportComponent)), i0.ɵdid(1, 245760, null, 0, i4.SidebarSupportComponent, [i5.AdminService, i6.SearchService, i7.TicketService, i8.ChatMessagesDataservice, i9.Store, i10.SidebarFilterStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarSupportComponentNgFactory = i0.ɵccf("sidebar-support", i4.SidebarSupportComponent, View_SidebarSupportComponent_Host_0, {}, {}, []);
export { SidebarSupportComponentNgFactory as SidebarSupportComponentNgFactory };
