import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {ChatTextareaDirective} from "./chat-textarea.directive";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable} from "rxjs/Observable";

@Injectable()
export class ChatTextareaEventbus {

    private _chatTextarea: BehaviorSubject<ChatTextareaDirective>;

    public snippetDialogSubject: Subject<boolean> = new Subject<boolean>();
    public noteDialogSubject: Subject<boolean> = new Subject<boolean>();
    public chatTextarea: Observable<ChatTextareaDirective>;

    constructor() {
        this._chatTextarea = <BehaviorSubject<ChatTextareaDirective>>new BehaviorSubject(null);
        this.chatTextarea = this._chatTextarea.asObservable();
    }

    get chatTextareaBehaviorSubject() {
        return this._chatTextarea;
    }

}
