<div>
  <div class="attachments d-flex flex-column">
    <div style="padding-bottom: 5px">
      <input type="file" style="width:400px;background-color:#f0ffff;" multiple>
      <span class="fa fa-fw fa-close pointer" (click)="removeAttachment($event)"></span>
    </div>
  </div>
  <div style="margin-left: 7px">
    <a class="pseudo-link" (click)="addAttachment()">Добавить файл</a>
    <span style="font-size: 80%; margin-left: 0.5em">(можно drag-and-drop'ом)</span>
    <span style="color: red; font-size: 80%; margin-left: 0.5em">(максимум 10 файлов)</span>
  </div>
</div>
