<div layout-growl style="z-index: 9999;position: absolute">
  <p-growl [value]="msgs" [sticky]="true" (onClose)="removeGrowlMsg($event)"></p-growl>
</div>

<chat-layout *ngIf="massReplyTicketList == null">
  <form layout-title class="form-title px-h hidden-md-down col">
    <div class="chat-data-title">Загрузка...</div>
  </form>

  <ol layout-subtitle class="breadcrumb hidden-lg-up" style="margin-bottom: 0;">
    <div class="chat-data-title">Загрузка...</div>
  </ol>
</chat-layout>

<chat-layout *ngIf="massReplyTicketList != null">

  <form layout-title class="form-title px-h hidden-md-down col">
    <div class="chat-data-title">massReply</div>
  </form>

  <ol layout-subtitle class="breadcrumb hidden-lg-up" style="margin-bottom: 0;">
    <div class="chat-data-title">massReply</div>
  </ol>

  <ul layout-sidebar class="nav">
    <chat-sidebar></chat-sidebar>
  </ul>

  <aside-support layout-aside></aside-support>

  <div layout-main class="animated fadeIn fast" (mouseup)="selectQuote()">
    <div class="chat-pad mx-auto">

      <div class="chat">
        <div class="chat-history">
          <div class="chat-ul">

            <div *ngFor="let ticket of massReplyTicketList.tickets;" class="clearfix">
              <ticket-preview [ticket]="ticket"></ticket-preview>
            </div>

            <div class="text-center mb-1" *ngIf="massReplyTicketList.tickets.length > 0">
              <button class="btn-link" (click)="scrollTop()">⬆️&nbsp; в начало</button>
            </div>

            <div class="chat-message clearfix">
              <form (submit)="postMassReply($event, chatMessage.value, true)" class="message__form">

                <input type="hidden" #chatMessageId value="">

                <chat-top-controls [canAddNote]="false"
                                   [ticketPanel]="panelPrefixForAllTickets"></chat-top-controls>

                <textarea chatTextarea #chatMessage name="message-to-send" id="message-to-send"
                          placeholder=""
                          (keydown.meta.enter)="postMassReplyByHotKey($event, chatMessage.value, true);"
                          (keydown.control.enter)="postMassReplyByHotKey($event, chatMessage.value, true);"
                          rows="5" [maxHeight]="400" autoResize="autoResize"></textarea>
                <br/>
                
                <div class="sent-buttons">
                  <div class="pull-right">
                    <div style="display: inline-block" [class.hidden]="isProcessReply()">
                      <a class="reply-submit-not-pending pseudo-link"
                         (click)="postMassReply($event, chatMessage.value, null);">
                        Добавить ответ
                      </a>
                      или&nbsp;
                      <a class="reply-submit-not-pending pseudo-link"
                         (click)="postMassReply($event, chatMessage.value, false);">
                        Ответить и в работу
                      </a>
                      или&nbsp;
                    </div>
                    <button type="submit" class="btn btn-primary reply-submit" [disabled]="isProcessReply()">
                      {{isProcessReply() ? 'Отправляется...': 'Ответить и отложить'}}
                    </button>
                  </div>
                </div>

              </form>
            </div>
            <div style="height: 20px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</chat-layout>

<div class="quote-block" *ngIf="massReplyTicketList != null">
  <div class="quote-btn" (click)="quotation()">Цитировать</div>
  <div class="quote-arrow"></div>
</div>
