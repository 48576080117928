import {Injectable} from "@angular/core";
import {action, observable} from "mobx-angular";
import {Subject} from "rxjs/Subject";
import {TicketList} from "./ticket-list.store";

@Injectable()
export class MassReplyTicketListStore {
    private _changed: Subject<TicketList> = new Subject<TicketList>();

    @observable
    value: TicketList = {tickets: [], loading: true};

    constructor() {
    }

    @action
    set(ticketList: TicketList) {
        this.value = ticketList;
        this._changed.next(ticketList);
    }
}
