/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./chat-attachments.component";
var styles_ChatAttachmentsComponent = [];
var RenderType_ChatAttachmentsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChatAttachmentsComponent, data: {} });
export { RenderType_ChatAttachmentsComponent as RenderType_ChatAttachmentsComponent };
export function View_ChatAttachmentsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "attachments d-flex flex-column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["style", "padding-bottom: 5px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "input", [["multiple", ""], ["style", "width:400px;background-color:#f0ffff;"], ["type", "file"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "span", [["class", "fa fa-fw fa-close pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeAttachment($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["style", "margin-left: 7px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "a", [["class", "pseudo-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addAttachment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0444\u0430\u0439\u043B"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["style", "font-size: 80%; margin-left: 0.5em"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["(\u043C\u043E\u0436\u043D\u043E drag-and-drop'\u043E\u043C)"])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["style", "color: red; font-size: 80%; margin-left: 0.5em"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["(\u043C\u0430\u043A\u0441\u0438\u043C\u0443\u043C 10 \u0444\u0430\u0439\u043B\u043E\u0432)"]))], null, null); }
export function View_ChatAttachmentsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "chat-attachments", [], null, null, null, View_ChatAttachmentsComponent_0, RenderType_ChatAttachmentsComponent)), i0.ɵdid(1, 245760, null, 0, i1.ChatAttachmentsComponent, [i0.Renderer2, i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatAttachmentsComponentNgFactory = i0.ɵccf("chat-attachments", i1.ChatAttachmentsComponent, View_ChatAttachmentsComponent_Host_0, {}, {}, []);
export { ChatAttachmentsComponentNgFactory as ChatAttachmentsComponentNgFactory };
