<ng-container *ngIf="store.ticketData.ticket?.id != null">
  <div class="chat-info">

    <ng-container *ngIf="!store.ticketData.isNewTicket">
      <p>
      <span
        [ngClass]="{'dot-blue': store.ticketData.ticket.unread, 'dot-green': !store.ticketData.ticket.unread}"></span>
        &nbsp;&nbsp;Тикет <span *ngIf="store.ticketData.ticket.unread">не</span> прочитан
      </p>
    </ng-container>

    <div *ngFor="let pinnedNote of store.ticketData.pinnedNotes; let i = index" class="note-pinned">
      <div class="note-info">
        <span class="fa fa-thumb-tack"></span>&nbsp;{{pinnedNote.author}}
      </div>
      <div class="note-text pointer" (click)="scrollToPin(pinnedNote.id)">
        <div [innerHTML]="pinnedNote.text"></div>
      </div>
    </div>
    <div class="form-check" *ngIf="!store.ticketData.isNewTicket && store.ticketData.countNotes > 0">
      <label class="form-check-label">
        <input class="form-check-input" type="checkbox" [(ngModel)]="showOnlyNotes"> Только заметки&nbsp;
        <span class="badge badge-danger pull-right">
          {{store.ticketData.countNotes}}
        </span>
      </label>
    </div>
    
    <p>Teг<br/>
      <p-dropdown [options]="tags"
                  [ngModel]="store.ticketData.ticket?.tag"
                  (onChange)="changeTag(store.ticketData.ticket?.id, $event.value.id)"
                  dataKey="id">
        <ng-template let-tag pTemplate="selectedItem">
          <span class="badge" [style.background-color]="tag.color">{{tag.label}}</span>
        </ng-template>
        <ng-template let-tag pTemplate="item">
          <span class="badge" [style.background-color]="tag.color">{{tag.label}}</span>
        </ng-template>
      </p-dropdown>
    </p>

    <p>Отдел<br/>
      <p-dropdown [options]="store.departments.active"
                  [ngModel]="store.ticketData.ticket.department"
                  (onChange)="changeDepartment(store.ticketData.ticket?.id, $event.value.id)">
      </p-dropdown>
    </p>
    
    <div style="margin-bottom: 1rem">Статус<br/>
      <div *ngFor="let status of store.statuses.list; let i = index"
           (click)="changeStatus(store.ticketData.ticket?.id, status.id)"
           class="ticket-status"
           [ngClass]="{'status-selected': status.id == store.ticketData.ticket?.status.id}">
        <span>
          {{status.name}}
        </span>
      </div>
    </div>

    <ng-container *ngIf="!store.ticketData.isNewTicket">
      <p>
        <span *ngIf="store.ticketData.ticket.assignedTo">Назначен на<br/></span>
        <span href="#" *ngIf="!store.ticketData.ticket.assignedTo"
              class="assign-to-me-chat-link"
              (click)="assignTo(store.ticketData.ticket?.id, currentLoggedInAdmin.username)">
          Назначить на себя<br/>
        </span>

        <p-dropdown #assignedToSelector
                    [options]="admins"
                    [placeholder]="store.ticketData.ticket.assignedTo ? store.ticketData.ticket.assignedTo.label : 'Не назначен'"
                    (onChange)="assignTo(store.ticketData.ticket?.id, $event.value.username)"
                    dataKey="id">
        </p-dropdown>
      </p>
    </ng-container>

    <div class="form-check" *ngIf="!store.ticketData.isNewTicket">
      <label class="form-check-label">
        <input class="form-check-input" type="checkbox" [(ngModel)]="showLogs">&nbsp;&nbsp;Логи
      </label>
    </div>

    <div class="sidebar" style="position: static; margin-left: 0;">
      <ul class="sidebar nav" style="position: static; margin-left: 0;">
        <li class="nav-item">
          <a class="nav-link" (click)="this.navigation.goToDashboard();">
            <i class="icon-arrow-left"></i> Назад
          </a>
        </li>
      </ul>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="store.ticketData.ticket?.id == null && store.massReplyTickets == null">
  <div> Загрузка...</div>
</ng-container>
