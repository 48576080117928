import { OnDestroy, OnInit } from "@angular/core";
import { TicketService } from "../../services/ticket.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TicketListDataservice } from "../../data/ticket-list.dataservice";
import { Subject } from "rxjs";
import "rxjs/add/operator/first";
import { SearchService } from "../../services/search.service";
import { GrowlMessageService } from "../../../core/services/growl-message.service";
import { LoginDataservice } from "../../../core/data/login.dataservice";
import { TicketDataservice } from "../../data/ticket.dataservice";
import { Location } from "@angular/common";
import { NavigationService } from "../../services/navigation.service";
import { Base64 } from "js-base64";
import { Store } from "../../store/store";
import "lodash";
import { AdminService } from "../../../core/services/admin.service";
import { Table } from "primeng/table";
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(ticketService, loginDataservice, ticketListDataservice, router, activatedRoute, navigationService, ticketDataService, growlMessageService, location, adminService, searchService, store) {
        this.ticketService = ticketService;
        this.loginDataservice = loginDataservice;
        this.ticketListDataservice = ticketListDataservice;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.navigationService = navigationService;
        this.ticketDataService = ticketDataService;
        this.growlMessageService = growlMessageService;
        this.location = location;
        this.adminService = adminService;
        this.searchService = searchService;
        this.store = store;
        this.ticketList = { loading: true, tickets: [] };
        this.ngDestroyed = new Subject();
        this._dtFirst = 0;
        this._dtRows = 20;
        this._dtOrder = -1;
        // Обработка нажатий
        this.selectedTickets = [];
    }
    Object.defineProperty(DashboardComponent.prototype, "currentLoggedInAdmin", {
        get: function () {
            return this.adminService.currentLoggedInAdmin;
        },
        enumerable: true,
        configurable: true
    });
    DashboardComponent.prototype.bindShowPagedata = function () {
        var _this = this;
        this.showPagedata();
        this.dt.onPage.subscribe(function (event) {
            _this._dtRows = event.rows;
            _this._dtFirst = event.first;
            _this.showPagedata();
        });
        this.dt.onSort.subscribe(function (event) {
            _this._dtOrder = event.order;
            _this.showPagedata();
        });
    };
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        var q = "";
        this.navigationService.navStart.takeUntil(this.ngDestroyed).subscribe(function (nd) {
            if (_this.ticketList != null) {
                _this.ticketList.loading = true;
            }
        });
        this.activatedRoute.queryParams.takeUntil(this.ngDestroyed).subscribe(function (params) {
            _this.applyPagedata(window.localStorage.getItem("queryParamsStr"));
            _this.applyPagedata(params['q'] ? Base64.decode(params['q']) : "");
        });
        // восстановление прокрутки
        var st = 0, sl = 0;
        this.store.tickets.changed.takeUntil(this.ngDestroyed).subscribe(function (data) {
            if (_.isEqual(_this.ticketList, data)) {
                return;
            }
            if (!_this.ticketList.loading) {
                st = $(".content-main").scrollTop();
                sl = $(".content-main").scrollLeft();
            }
            _this.ticketList = data;
            if (!_this.ticketList.loading) {
                data.loading = true; // скрываем данные
                var __this_1 = _this;
                var delayed_1 = function () {
                    if (!__this_1.dt) {
                        setTimeout(function () {
                            delayed_1();
                        }, 100);
                        return;
                    }
                    // пейджим, когда все прогрузилось
                    // именно в таком порядке, иначе страница сбрасывается
                    __this_1.dt.sortOrder = __this_1.searchService.sortOrder = __this_1._dtOrder;
                    __this_1.dt.rows = __this_1._dtRows;
                    __this_1.dt.first = __this_1._dtFirst < __this_1.ticketList.tickets.length ? __this_1._dtFirst : 0;
                    // __this.dt.paginate();
                    // показываем данные
                    __this_1.ticketList.loading = false;
                    // подменяем обработчик сортировки на свой
                    if (!__this_1.dt.__sort) {
                        __this_1.dt.__sort = __this_1.dt.sort;
                        __this_1.dt.sort = function (event) {
                            __this_1.dt.__sort(event);
                            if (__this_1.ticketList.count > __this_1.ticketList.tickets.length) {
                                __this_1.ticketList.loading = true;
                                __this_1.searchService.search();
                            }
                        };
                    }
                    // при изменениях в пейджинге - сохраняем в урл
                    __this_1.bindShowPagedata();
                    // выравниваем скроллы
                    setTimeout(function () {
                        $(".content-main").scrollTop(st);
                        $(".content-main").scrollLeft(sl);
                        __this_1.onResize();
                    }, 1);
                };
                setTimeout(function () {
                    delayed_1();
                }, 1);
            }
        });
        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(function (data) {
            _this._userInfo = data;
        });
        this.searchService.extendedSearch.asObservable().takeUntil(this.ngDestroyed).subscribe(function (data) {
            _this.extendedSearch = data;
            $(".content-main").scrollTop(0);
            $(".content-main").scrollLeft(0);
            setTimeout(function () { return _this.onResize(); }, 1);
            setTimeout(function () { return _this.onResize(); }, 1); // в первый раз иногда некорректно рассчитывается высота документа
        });
        this.onResize();
    };
    DashboardComponent.prototype.ngOnDestroy = function () {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    };
    Object.defineProperty(DashboardComponent.prototype, "userInfo", {
        get: function () {
            return this._userInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardComponent.prototype, "msgs", {
        get: function () {
            return this.growlMessageService.msgs;
        },
        enumerable: true,
        configurable: true
    });
    DashboardComponent.prototype.removeGrowlMsg = function (event) {
        this.growlMessageService.removeMsg(event.message);
    };
    DashboardComponent.prototype.showPagedata = function () {
        var _this = this;
        var departmentsSelection = this.store.departments.active.map(function (d) {
            return (_this.store.sidebarFilter.departmentsState.has("" + d.id) ? 1 : 0) << d.id;
        }).reduce(function (a, b) { return a + b; }, 0);
        var statusesSelection = this.store.statuses.list.map(function (s) {
            return (_this.store.sidebarFilter.statusesState.has("" + s.id) ? 1 : 0) << s.id;
        }).reduce(function (a, b) { return a + b; }, 0);
        var query = this.searchService.searchModel;
        var paginationFirst = this._dtFirst;
        var paginationRows = this._dtRows;
        var overrideDep = this.store.sidebarFilter.overrideDep;
        var overrideStat = this.store.sidebarFilter.overrideStat;
        var sortOrder = this.searchService.sortOrder;
        var map = JSON.stringify({
            f1: [departmentsSelection, statusesSelection, overrideDep, overrideStat],
            q1: query,
            p1: [paginationFirst, paginationRows, sortOrder],
        });
        window.localStorage.setItem("queryParamsStr", map);
        var url = this.router.serializeUrl(this.router.createUrlTree(['/support/dashboard'], { queryParams: { q: Base64.encode(map) } }));
        this.navigationService.replaceUrl(url);
    };
    DashboardComponent.prototype.applyPagedata = function (str) {
        var _this = this;
        console.log("str: " + str);
        try {
            var map_1 = str ? JSON.parse(str) : {};
            if (!map_1) {
                return;
            }
            if (map_1.f1) {
                if (map_1.f1[0] != null) {
                    this.store.departments.active.forEach(function (d) {
                        var selected = (((map_1.f1[0] >> d.id) & 1) == 1);
                        _this.store.sidebarFilter.chooseDepartment(d.id, selected);
                    });
                }
                if (map_1.f1[1] != null) {
                    this.store.statuses.list.forEach(function (s) {
                        var selected = (((map_1.f1[1] >> s.id) & 1) == 1);
                        _this.store.sidebarFilter.chooseStatus(s.id, selected);
                    });
                }
                this.store.sidebarFilter.overrideDep = map_1.f1[2];
                this.store.sidebarFilter.overrideStat = map_1.f1[3];
            }
            if (map_1.p1) {
                this._dtFirst = map_1.p1[0] || 0;
                this._dtRows = map_1.p1[1] || 20;
                this.searchService.sortOrder = this._dtOrder = map_1.p1[2] || -1;
            }
            if (map_1.q1) {
                this.searchService.initSearch(map_1.q1);
            }
            else {
                this.searchService.initSearch();
            }
        }
        catch (e) {
            console.log(e);
        }
    };
    Object.defineProperty(DashboardComponent.prototype, "statuses", {
        // Данные для отображения
        get: function () {
            return this.store.statuses.list;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardComponent.prototype, "departments", {
        get: function () {
            return this.store.departments.active;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardComponent.prototype, "tickets", {
        get: function () {
            return this.ticketList.tickets;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardComponent.prototype, "count", {
        get: function () {
            return this.ticketList.count;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardComponent.prototype, "loading", {
        get: function () {
            return this.ticketList.loading;
        },
        enumerable: true,
        configurable: true
    });
    DashboardComponent.prototype.ticketClick = function ($event) {
        var target = $event.target;
        var el = null;
        if ($(target).closest('td').is('.ticketField__check')) {
            // курсор попал мимо галки
            el = $(target).find(".ui-chkbox-box")[0];
        }
        else if ($(target).closest('td').is('.ticketField__link')) {
            // переход на чат
            el = $(target).find("a")[0];
        }
        if (el) {
            el.click();
        }
    };
    DashboardComponent.prototype.onSort = function (e) {
        this.searchService.setOrder(e.field, e.order);
    };
    // Форматирование
    DashboardComponent.prototype.isTicketOld = function (date) {
        return (new Date().getTime() - date.getTime() > 1000 * 60 * 60 * 2);
    };
    DashboardComponent.prototype.rowStyleClass = function (rowData) {
        if (rowData.updating) {
            return 'ticket__updating';
        }
        if (new Date().getTime() - rowData.activity.getTime() > 1000 * 60 * 60 * 24) {
            return 'ticket__alert';
        }
        return '';
    };
    DashboardComponent.prototype.focusEditable = function (comp) {
        comp.show();
        comp.bindDocumentClickListener();
    };
    DashboardComponent.prototype.onResize = function ($event) {
        var mq1 = window.matchMedia('screen and (max-width:991px)');
        this.smallScreen = mq1.matches;
        var wh = $(window).height();
        if ($(".ticketsTable .ui-table").is(":visible")) {
            var st = $(".content-main").scrollTop();
            var sl = $(".content-main").scrollLeft();
            $(".content-main").scrollTop(0);
            $(".content-main").scrollLeft(0);
            $(".ticketsTable").css("height", "auto");
            var dh = $(document).height();
            var oh = $(".ticketsTable").outerHeight();
            if (dh > wh) {
                var h = oh - (dh - wh);
                $(".ticketsTable").css("height", h + "px");
                $(".content-main").css("overflow-x", "scroll");
                $(".content-main").css("overflow-y", "scroll");
            }
            else {
                $(".content-main").css("overflow-x", "visible");
                $(".content-main").css("overflow-y", "visible");
            }
            $(".content-main").scrollTop(st);
            $(".content-main").scrollLeft(sl);
        }
    };
    // Редактирование тикета из таблицы
    DashboardComponent.prototype.changeDepartment = function (ticket, dept) {
        ticket.department = dept;
        ticket.updating = true;
        this.ticketDataService.changeDepartment(ticket.panelPrefix, ticket.id, dept.id, ticket.version);
    };
    DashboardComponent.prototype.changeStatus = function (ticket, status) {
        ticket.status = status;
        ticket.updating = true;
        this.ticketDataService.changeStatus(ticket.panelPrefix, ticket.id, status.id, ticket.version);
    };
    DashboardComponent.prototype.changeMassStatus = function (selectedTickets, status) {
        selectedTickets.forEach(function (value) {
            value.status = status;
            value.updating = true;
        });
        var groupedTickets = selectedTickets.reduce(function (acc, ticket) {
            if (!acc[ticket.panelPrefix]) {
                acc[ticket.panelPrefix] = [];
            }
            acc[ticket.panelPrefix].push(ticket.id);
            return acc;
        }, {});
        var ticketsData = Object.keys(groupedTickets).map(function (panelPrefix) {
            return { panelPrefix: panelPrefix, ids: groupedTickets[panelPrefix] };
        });
        this.ticketDataService.changeMassStatus(ticketsData, status.id);
    };
    DashboardComponent.prototype.isProcessChangeMassStatus = function () {
        return this.ticketDataService.isProcessChangeMassStatus();
    };
    DashboardComponent.prototype.assignToMe = function (ticket) {
        if (ticket.assignedTo != null) {
            return;
        }
        this.changeAssignTo(ticket, this.adminService.currentLoggedInAdmin, true);
    };
    DashboardComponent.prototype.changeAssignTo = function (ticket, admin, followRedirect) {
        if (followRedirect === void 0) { followRedirect = false; }
        ticket.assignedTo = admin;
        ticket.updating = true;
        this.ticketDataService.assignTo(ticket.panelPrefix, ticket.id, admin.username, ticket.version, followRedirect);
    };
    DashboardComponent.prototype.changeTag = function (ticket, tag) {
        ticket.tag = tag;
        ticket.updating = true;
        this.ticketDataService.changeTag(ticket.panelPrefix, ticket.id, tag.id, ticket.version);
    };
    DashboardComponent.prototype.ticketEdit = function ($event, rowData) {
        // console.log(rowData);
    };
    DashboardComponent.prototype.blurEditable = function (self) {
        // принудительное снятие фокуса с элемента ввода
        setTimeout(function () {
            // workaround отсюда https://github.com/primefaces/primeng/issues/5352
            // TODO переделать на что-то нормальное
            var escape = {
                key: '27',
                'keyCode': '27' // deprecated, но используется в primeng
            };
            var $td = $(self.el.nativeElement).closest("td");
            if ($td.is(".ui-editing-cell")) {
                $td[0].dispatchEvent(new KeyboardEvent("keydown", escape));
            }
        }, 1);
    };
    DashboardComponent.prototype.getAvailableAdmins = function (ticket) {
        return this._userInfo.adminsInPanels[ticket.panelPrefix];
    };
    DashboardComponent.prototype.getTags = function (ticket) {
        return this._userInfo.tagsInPanels[ticket.panelPrefix];
    };
    DashboardComponent.prototype.openChatMassReply = function (selectedTickets) {
        var ticketIdsGroupedByPanel = selectedTickets.reduce(function (groups, ticket) {
            var panel = ticket.panelPrefix;
            groups[panel] = groups[panel] || [];
            groups[panel].push(ticket.id);
            return groups;
        }, {});
        var ticketIdsStrGroupedByPanel = {};
        Object.getOwnPropertyNames(ticketIdsGroupedByPanel).forEach(function (prop) {
            ticketIdsStrGroupedByPanel[prop] = ticketIdsGroupedByPanel[prop].sort().join(",");
        });
        this.router.navigate(['/support/chatMassReply'], { queryParams: ticketIdsStrGroupedByPanel });
        return false;
    };
    return DashboardComponent;
}());
export { DashboardComponent };
