import {Injectable} from "@angular/core";
import {action, computed, observable} from "mobx-angular";
import {Message} from "../domain/message.domain";
import {Ticket} from "../domain/ticket.domain";
import {Subject} from "rxjs/Subject";
import {Observable} from "rxjs/Observable";
import {TicketInfo} from "../domain/ticketInfo.domain";
import {Department} from "../domain/department.domain";
import {Status} from "../domain/status.domain";
import {Admin} from "../domain/admin.domain";
import {Tag} from "../domain/tag.domain";

export interface TicketData {
    messages: Message[],
    quickTickets: Ticket[];
    ticket: Ticket,
    username: string,
    ticketVersion: number
}

@Injectable()
export class TicketStore {
    private _changed: Subject<TicketData> = new Subject<TicketData>();
    public changed: Observable<TicketData> = this._changed.asObservable();

    @observable
    value: TicketData = null;

    @observable
    actualVersion: number = -1;

    @observable
    typingAdmins: string[] = [];

    @observable
    activeFlows: any[] = [];

    @observable
    afterPropertyChangeInChat: boolean = false; // IHC-6682 только для текущего тикета, до отправки ответа

    translatedText: string =  null;

    constructor() {

    }

    @action
    setAfterPropertyChangeInChat() {
        this.afterPropertyChangeInChat = true;
    }

    @action
    clearAfterPropertyChangeInChat() {
        this.afterPropertyChangeInChat = false;
    }

    @action
    set(ticketData: TicketData) {
        if (!this.value || !this.value.ticket || this.value.ticket.id != ticketData.ticket.id) {
            // тикет сменился, забываем
            this.afterPropertyChangeInChat = false;
        }
        this.value = ticketData;
        this.actualVersion = ticketData.ticketVersion;
        this.setActiveFlows(ticketData["activeFlows"] || []);
        this.setTypingAdmins([]);
        this._changed.next(ticketData);
    }

    @action
    setInfo(info: TicketInfo) {
        this.value.ticket.info = info;
    }

    @action
    setSync(sync: any) {
        if (this.value && this.value.ticket && sync["ticketId"] === this.value.ticket.id) {
            this.setTypingAdmins(sync["typingAdmins"] || []);
            this.setActiveFlows(sync["activeFlows"] || []);
            this.setActualVersion(sync["ticketVersion"] || 0);

            this.value.ticket.unread = sync["unread"] || false;
        }
    }

    @action
    setActualVersion(actualVersion: number) {
        this.actualVersion = actualVersion;
    }

    @action
    setTypingAdmins(typingAdmins: string[]) {
        this.typingAdmins = typingAdmins;
    }

    @action
    setActiveFlows(flows: string[]) {
        this.activeFlows = flows;
    }

    @action
    clear() {
        this.afterPropertyChangeInChat = false;
        this.value = null;
        this.actualVersion = -1;
        this.setActiveFlows([]);
        this.setTypingAdmins([]);
    }

    @action
    changeDepartment(dep: Department) {
        this.value.ticket.department = dep;
    }

    @action
    changeStatus(status: Status) {
        this.value.ticket.status = status;
    }

    @action
    assignTo(admin: Admin) {
        this.value.ticket.assignedTo = admin;
    }

    @action
    changeTag(tag: Tag) {
        this.value.ticket.tag = tag;
    }

    @action
    translateMessage(messageId: number, translatedText: string) {
        this.value.messages.filter(m => m.id == messageId).forEach(m => {
            m.textTranslated = translatedText;
            m.translated = true;
        });
    }

    @action
    translateText(translatedText: string) {
        this.translatedText = translatedText;
    }


    @computed
    get ticket(): Ticket {
        if (this.value == null) {
            return null;
        }
        return this.value.ticket;
    }

    @computed
    get isNewTicket(): boolean {
        if (this.value == null || this.value.ticket == null) {
            return false;
        }
        return this.value.ticket.id < 0;
    }

    @computed
    get quickTickets(): Ticket[] {
        if (this.value == null) {
            return [];
        }
        return this.value.quickTickets || [];
    }

    @computed
    get info(): TicketInfo {
        if (this.ticket == null) {
            return null;
        }
        return this.ticket.info;
    }

    @computed
    get pinnedNotes(): Message[] {
        return this.value.messages.filter((m) => m.type == "note" && m.pinned && m.active);
    }

    @computed
    get lastMessage(): Message {
        return this.value.messages.slice().reverse().find((m) => (m.type != "log") && m.active);
    }

    @computed
    get ticketChanged(): boolean {
        if (!this.value) {
            return false
        }

        return this.actualVersion != this.value.ticketVersion
    }

    @computed
    get countNotes(): number {
        return this.value.messages.filter((m) => m.type == "note" && m.active).length
    }
}
