import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable()
export class ChatInfoShowLogsEvents {

    private _events: BehaviorSubject<boolean>;
    events: Observable<boolean>;

    constructor() {
        this._events = <BehaviorSubject<boolean>>new BehaviorSubject(false);
        this.events = this._events.asObservable();
    }

    subscribe(ngDestroyed, f) {
        this.events.takeUntil(ngDestroyed).subscribe(f);
    }

    next(data) {
        this._events.next(data);
    }
}
