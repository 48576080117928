var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef } from "@angular/core";
import { InputTextarea2Directive } from "../../../../shared/primeng/input-textarea2.directive";
import { ChatMarkdownService } from "../../../services/chat-markdown.service";
import { ChatTextareaEventbus } from "./chat-textarea.eventbus";
import { Subject } from "rxjs/Subject";
var ChatTextareaDirective = /** @class */ (function (_super) {
    __extends(ChatTextareaDirective, _super);
    function ChatTextareaDirective(el, chatMarkdownService, chatTextareaEventbus) {
        var _this = _super.call(this, el) || this;
        _this.el = el;
        _this.chatMarkdownService = chatMarkdownService;
        _this.onShow = new Subject();
        // публикуемся
        chatTextareaEventbus.chatTextareaBehaviorSubject.next(_this);
        return _this;
    }
    ChatTextareaDirective.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    ChatTextareaDirective.prototype.ngAfterViewInit = function () {
        this.chatMarkdownService.initTextareaEditor(this);
    };
    Object.defineProperty(ChatTextareaDirective.prototype, "textArea", {
        get: function () {
            return this.el.nativeElement;
        },
        enumerable: true,
        configurable: true
    });
    ChatTextareaDirective.prototype.setSelectionRange = function (el, start, end) {
        var _this = this;
        // если сделать setSelectionRange на невидимом textarea, то в firefox падает NS_ERROR_FAILURE
        // поэтому откладываем это до появления
        if ($(el).is(':visible')) {
            el.setSelectionRange(start, end);
        }
        else {
            setTimeout(function () { return _this.setSelectionRange(el, start, end); }, 200);
        }
    };
    ChatTextareaDirective.prototype.emptyText = function () {
        this.textArea.value = "";
        this.setSelectionRange(this.textArea, 0, 0);
        this.resize();
    };
    Object.defineProperty(ChatTextareaDirective.prototype, "cursorPositionMarker", {
        get: function () {
            return "__cursorPosition__";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatTextareaDirective.prototype, "textareaSelectionStart", {
        get: function () {
            return this.textArea.selectionStart;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatTextareaDirective.prototype, "textareaSelectionEnd", {
        get: function () {
            return this.textArea.selectionEnd;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatTextareaDirective.prototype, "initMessageText", {
        get: function () {
            return "\u0417\u0434\u0440\u0430\u0432\u0441\u0442\u0432\u0443\u0439\u0442\u0435.\n\n" + this.cursorPositionMarker;
        },
        enumerable: true,
        configurable: true
    });
    ChatTextareaDirective.prototype.initMessage = function (message, hasAdminMsg) {
        var _this = this;
        if (message === void 0) { message = ""; }
        if (hasAdminMsg === void 0) { hasAdminMsg = false; }
        this.emptyText();
        if (!message) {
            message = hasAdminMsg ? "" : this.initMessageText;
        }
        this.insertTextWithCursorPosition(message);
        setTimeout(function () { return _this.resize(); }, 10);
    };
    ChatTextareaDirective.prototype.getText = function () {
        return this.textArea.value;
    };
    ChatTextareaDirective.prototype.focus = function () {
        this.textArea.focus();
    };
    ChatTextareaDirective.prototype.blur = function () {
        this.textArea.blur();
    };
    ChatTextareaDirective.prototype.insertTextWithCursorPosition = function (text, position) {
        this.insertText(text, position, this.cursorPositionMarker, true);
    };
    ChatTextareaDirective.prototype.insertTextWithPlaceholder = function (text, position) {
        this.insertText(text, position, "XXXXXXXXXXX", false);
    };
    ChatTextareaDirective.prototype.insertText = function (text, position, select, emptySelection) {
        var message = this.textArea.value;
        text = text.replace(/\r\n/g, "\n");
        var positionBegin = position;
        var positionEnd = position;
        // если position = null, то вставляем в текущее выделение
        if (position == null) {
            positionBegin = this.textArea.selectionStart;
            positionEnd = this.textArea.selectionEnd;
        }
        var begin = message.substring(0, positionBegin);
        var end = message.substring(positionEnd);
        var newText = begin + text + end;
        var selectionBegin = positionBegin + text.length;
        var selectionEnd = positionBegin + text.length;
        if (select != null) {
            select = select.replace(/\r\n/g, "\n");
            // ищем плейсхолдер
            selectionBegin = newText.indexOf(select, positionBegin);
            if (selectionBegin >= 0) {
                selectionEnd = selectionBegin + select.length;
            }
            else {
                selectionBegin = positionBegin + text.length;
                selectionEnd = selectionBegin;
            }
            // вырезаем, если надо и есть что вырезать
            if (emptySelection && selectionBegin != selectionEnd) {
                begin = newText.substring(0, selectionBegin);
                end = newText.substring(selectionEnd);
                newText = begin + end;
                selectionEnd = selectionBegin;
            }
        }
        this.textArea.value = newText;
        this.setSelectionRange(this.textArea, selectionBegin, selectionEnd);
        this.resize();
    };
    return ChatTextareaDirective;
}(InputTextarea2Directive));
export { ChatTextareaDirective };
