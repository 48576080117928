import {Injectable} from "@angular/core";
import {action, observable} from "mobx-angular";
import {Status} from "../domain/status.domain";

export class Statuses {
    list: Array<Status> = [];

    constructor(items?: Status[]) {
        items && items.forEach(item => this.list.push(item));
    }

    getById(id: number): Status {
        return this.list.find((e) => e.id == id);
    }
}

@Injectable()
export class StatusesStore {

    static STATUS_OPENED = new Status(1, "Открыт", true, 'Открыть', 'success');
    static STATUS_POSTPONED = new Status(2, "Отложен", false, 'Отложить', 'info');
    static STATUS_CLOSED = new Status(3, "Закрыт", false, 'Закрыть', 'danger');
    static STATUS_IN_PROGRESS = new Status(4, "В работе", false, 'В работу', 'warning');

    @observable
    value: Statuses = new Statuses();

    constructor() {
        this.set([
            StatusesStore.STATUS_OPENED,
            StatusesStore.STATUS_IN_PROGRESS,
            StatusesStore.STATUS_POSTPONED,
            StatusesStore.STATUS_CLOSED
        ].sort(function (a, b) {
            let x = a.id;
            let y = b.id;
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }))
    }

    @action
    set(statuses: Status[]) {
        this.value = new Statuses(statuses);
    }

    @action
    clear() {
        this.value = new Statuses();
    }
}
