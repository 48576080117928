/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./chat-settings.component";
var styles_ChatSettingsComponent = [];
var RenderType_ChatSettingsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChatSettingsComponent, data: {} });
export { RenderType_ChatSettingsComponent as RenderType_ChatSettingsComponent };
export function View_ChatSettingsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0417\u0434\u0435\u0441\u044C \u0431\u0443\u0434\u0443\u0442 \u0432\u0441\u0435 \u043D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438 \u0442\u0438\u043A\u0435\u0442\u0430"]))], null, null); }
export function View_ChatSettingsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "chat-settings", [], null, null, null, View_ChatSettingsComponent_0, RenderType_ChatSettingsComponent)), i0.ɵdid(1, 49152, null, 0, i1.ChatSettingsComponent, [], null, null)], null, null); }
var ChatSettingsComponentNgFactory = i0.ɵccf("chat-settings", i1.ChatSettingsComponent, View_ChatSettingsComponent_Host_0, {}, {}, []);
export { ChatSettingsComponentNgFactory as ChatSettingsComponentNgFactory };
