<tabset>
  <tab *ngFor="let tab of tabs; let i = index"
       [removable]="true">
    <ng-template tabHeading>
      <i class="{{tab.icon}}"></i>
    </ng-template>

    <div class="p-1">
      <div *ngIf="tab.name=='info'">
        <chat-info></chat-info>
      </div>
      <div *ngIf="tab.name=='settings'">
        <chat-settings></chat-settings>
      </div>
    </div>
  </tab>
</tabset>
