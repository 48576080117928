import { ElementRef, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import "jquery";
import "bootstrap";
import { ChatMessagesDataservice } from "../../data/chat-messages.dataservice";
import { NavigationService } from "../../services/navigation.service";
import { Subject } from "rxjs";
import { GrowlMessageService } from "../../../core/services/growl-message.service";
import { LoginDataservice } from "../../../core/data/login.dataservice";
import { AsideSupportService } from "./aside/aside-support.service";
import { ChatTextareaEventbus } from "./reply/chat-textarea.eventbus";
import { Store } from "../../store/store";
import { CheckService } from "../../services/litera.service";
import { ChatMarkdownService } from "../../services/chat-markdown.service";
import { TicketInfoBuilder } from "../../domain/builder/ticket-info.builder";
var ChatNewComponent = /** @class */ (function () {
    function ChatNewComponent(asideSupportService, chatMessagesDataservice, growlMessageService, activatedRoute, navigationService, chatTextareaEventbus, loginDataservice, _store, checkService, ticketInfoBuilder, chatMarkdownService) {
        this.asideSupportService = asideSupportService;
        this.chatMessagesDataservice = chatMessagesDataservice;
        this.growlMessageService = growlMessageService;
        this.activatedRoute = activatedRoute;
        this.navigationService = navigationService;
        this.chatTextareaEventbus = chatTextareaEventbus;
        this.loginDataservice = loginDataservice;
        this._store = _store;
        this.checkService = checkService;
        this.ticketInfoBuilder = ticketInfoBuilder;
        this.chatMarkdownService = chatMarkdownService;
        this.ngDestroyed = new Subject();
        this._isSendSmsNow = false;
        this._isSendSms = false;
        this._isSendEmail = false;
    }
    Object.defineProperty(ChatNewComponent.prototype, "store", {
        get: function () {
            return this._store;
        },
        enumerable: true,
        configurable: true
    });
    ChatNewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.navigationService.navEnd.takeUntil(this.ngDestroyed).subscribe(function (n) {
            _this._ticketPanel = _this.activatedRoute.snapshot.params.panel;
            _this._store.ticketData.clear();
            _this.chatMessagesDataservice.getNewTicketData(_this._ticketPanel, 0);
            _this._ticketSetUser = _this.activatedRoute.snapshot.queryParams.username;
            _this._ticketSetDept = _this.activatedRoute.snapshot.queryParams.department;
            _this._ticketSetSubj = _this.activatedRoute.snapshot.queryParams.subject;
            _this._ticketSetPanel = _this.activatedRoute.snapshot.queryParams.panel;
        });
        this._store.ticketData.changed.takeUntil(this.ngDestroyed).subscribe(function (data) {
            if (_this._ticketSetUser) {
                _this.ticketData.username = _this._ticketSetUser;
                _this.ticketData.ticket.username = _this._ticketSetUser;
            }
            if (_this._ticketSetDept) {
                _this.ticketData.ticket.department = _this._store.departments.getById(+_this._ticketSetDept);
            }
            if (_this._ticketSetSubj) {
                _this.ticketData.ticket.subject = _this._ticketSetSubj;
            }
            if (_this._ticketSetPanel) {
                _this._ticketPanel = _this._ticketSetPanel;
            }
            else {
                _this.ticketData.ticket.panelPrefix = undefined;
            }
            _this.showNotificationSettings();
        });
        this.chatMessagesDataservice.messageSent.takeUntil(this.ngDestroyed).subscribe(function (data) {
            if (data.sent) {
                _this.growlMessageService.success("Отправлено", "Сообщение отправлено");
                _this.navigationService.goToDashboard();
            }
        });
        this.asideSupportService.showTabs = ['info'];
        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(function (data) {
            _this._userInfo = data;
            _this.panels = _this._userInfo.panels.map(function (p) {
                return { label: p, value: p };
            });
            _this.panels.splice(0, 0, { label: "?", value: "?" });
        });
        this.chatTextareaEventbus.chatTextarea.takeUntil(this.ngDestroyed).subscribe(function (data) {
            _this.chatTextareaDirective = data;
            if (_this.chatTextareaDirective) {
                _this.chatTextareaDirective.initMessage();
            }
        });
        setTimeout(function () {
            if (_this.chatMessageSubject != undefined) {
                _this.chatMessageSubject.nativeElement.focus();
            }
        }, 100);
    };
    ChatNewComponent.prototype.ngOnDestroy = function () {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    };
    Object.defineProperty(ChatNewComponent.prototype, "msgs", {
        get: function () {
            return this.growlMessageService.msgs;
        },
        enumerable: true,
        configurable: true
    });
    ChatNewComponent.prototype.removeGrowlMsg = function (event) {
        this.growlMessageService.removeMsg(event.message);
    };
    Object.defineProperty(ChatNewComponent.prototype, "ticketData", {
        get: function () {
            return this._store.ticketData.value;
        },
        set: function (td) {
            this._store.ticketData.set(td);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatNewComponent.prototype, "panelPrefix", {
        get: function () {
            return this.ticketData.ticket.panelPrefix;
        },
        enumerable: true,
        configurable: true
    });
    ChatNewComponent.prototype.postMessageByHotKey = function (event, chatMessage, postpone) {
        event.preventDefault();
        this.postMessage(event, chatMessage, postpone);
    };
    //в случае редактирования не забыть проверить postMessage метод в chat.component.ts
    ChatNewComponent.prototype.postMessage = function (event, chatMessage, postpone) {
        var _this = this;
        event.preventDefault();
        var inputs = $(".message__form").find("input[type='file']");
        var formData = new FormData();
        formData.append('panelPrefix', this.panelPrefix);
        if (inputs) {
            var n = 0;
            for (var i = 0; i < inputs.length; i++) {
                var nativeElement = inputs[i];
                var files = nativeElement["files"];
                for (var j = 0; j < files.length; j++) {
                    var file = files[j];
                    if (file != undefined && file.size > 0) {
                        formData.append('attachment' + n++, file);
                    }
                }
            }
        }
        formData.append('message', chatMessage);
        if (this.chatNote) {
            formData.append('note', this.chatNote.nativeElement.value);
        }
        if (this.isSendSms) {
            formData.append('isSendSms', "true");
            if (this._isSendSmsNow) {
                formData.append('isSendSmsNow', "true");
            }
        }
        if (this.isSendEmail) {
            formData.append('htmlMessageForSendEmail', this.chatMarkdownService.parse(chatMessage));
        }
        formData.append('subject', this.ticketData.ticket.subject);
        formData.append('personUsername', this.ticketData.ticket.username);
        formData.append('departmentId', "" + this.ticketData.ticket.department.id);
        formData.append('statusId', "" + this.ticketData.ticket.status.id);
        formData.append('assignTo', this.ticketData.ticket.assignedTo ? this.ticketData.ticket.assignedTo.username : "");
        formData.append('type', "message");
        if (postpone != null) {
            formData.append('postpone', postpone ? "true" : "false");
        }
        var error = false;
        var requiredFields = ['panelPrefix', 'message', 'subject', 'personUsername'];
        requiredFields.forEach(function (f) {
            var val = formData.get(f);
            if (!val || val == 'undefined') {
                _this.growlMessageService.error("\u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0443\u043A\u0430\u0437\u0430\u0442\u044C " + f);
                error = true;
            }
        });
        if (error) {
            return;
        }
        var create = function (message) {
            formData.set('message', message);
            _this.chatMessagesDataservice.create(_this._ticketPanel, formData);
        };
        if (chatMessage) {
            this.checkService.start({ text: chatMessage, ticket: this.ticketData.ticket, onSuccess: create });
            this.chatMessagesDataservice.processReply = true;
            return;
        }
        create(chatMessage);
    };
    ChatNewComponent.prototype.isProcessReply = function () {
        return this.chatMessagesDataservice.isProcessReply();
    };
    ChatNewComponent.prototype.createTicketInPanel = function (panel) {
        this.ticketData.ticket.panelPrefix = panel;
        this.showNotificationSettings();
    };
    ChatNewComponent.prototype.showNotificationSettings = function () {
        var _this = this;
        this._phone = "";
        this._time = "";
        this._customerEmail = "";
        this._isSendEmail = false;
        this._isSendSms = false;
        var panel = this.ticketData.ticket.panelPrefix;
        var username = this.ticketData.ticket.username;
        if (!username || !panel || panel == "?") {
            return;
        }
        var setPhoneAndTime = function (data) {
            _this._phone = data.phone;
            _this._time = data.time;
            _this._customerEmail = data.customerEmail;
            _this.store.ticketData.ticket.customerNote = data["customer"]["note"];
            _this.store.ticketData.setInfo(_this.ticketInfoBuilder.build(data["customer"]));
        };
        this.chatMessagesDataservice.getNotificationSettings(panel, username, setPhoneAndTime);
    };
    Object.defineProperty(ChatNewComponent.prototype, "phone", {
        get: function () {
            return this._phone;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatNewComponent.prototype, "customerEmail", {
        get: function () {
            return this._customerEmail;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatNewComponent.prototype, "time", {
        get: function () {
            if (this._isSendSmsNow) {
                return "сейчас";
            }
            return "в " + this._time + ":00 MSK";
        },
        enumerable: true,
        configurable: true
    });
    ChatNewComponent.prototype.invertSendSmsNow = function () {
        this._isSendSmsNow = !this._isSendSmsNow;
    };
    Object.defineProperty(ChatNewComponent.prototype, "isSendSms", {
        get: function () {
            if (this.isAbuse && this.phone) {
                this._isSendSms = true;
            }
            return this._isSendSms;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatNewComponent.prototype, "isSendEmail", {
        get: function () {
            if (this.isAbuse && this.customerEmail) {
                this._isSendEmail = true;
            }
            return this._isSendEmail;
        },
        enumerable: true,
        configurable: true
    });
    ChatNewComponent.prototype.invertIsSendSms = function () {
        this._isSendSms = !this._isSendSms;
    };
    ChatNewComponent.prototype.changeSendEmail = function () {
        this._isSendEmail = !this._isSendEmail;
    };
    Object.defineProperty(ChatNewComponent.prototype, "isAbuse", {
        get: function () {
            return this.ticketData.ticket.department.name == "Abuse";
        },
        enumerable: true,
        configurable: true
    });
    ChatNewComponent.prototype.sendMsgWithoutCheck = function () {
        this.checkService.doSuccess();
    };
    Object.defineProperty(ChatNewComponent.prototype, "literaIframe", {
        get: function () {
            return this.checkService.isChecking();
        },
        enumerable: true,
        configurable: true
    });
    ChatNewComponent.prototype.reloadIframe = function () {
        this.checkService.reload();
    };
    ChatNewComponent.prototype.closeIframe = function () {
        this.checkService.stop();
    };
    ChatNewComponent.prototype.selectTicketMask = function (event) {
    };
    return ChatNewComponent;
}());
export { ChatNewComponent };
