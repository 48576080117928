import {Component, OnDestroy, OnInit} from "@angular/core";
import {SearchService} from "../../../services/search.service";
import {RelativeMenuItem} from "../../../../shared/primeng/tieredmenu-relative.component";
import {Subject} from "rxjs";

@Component({
    selector: '[title-search]',
    templateUrl: './title-search.component.html'
})
export class TitleSearchComponent implements OnInit, OnDestroy {

    searchMenuItemClick: (event?: any) => void;
    searchMenuItems: RelativeMenuItem[];
    parent;

    searchModel: string;
    searchApplied: boolean;
    private ngDestroyed: Subject<void> = new Subject<void>();

    extendedSearch: boolean;
    smallScreen: boolean;

    constructor(private searchService: SearchService) {
        let _this = this;
    }

    get timer(): number {
        return this.searchService.timer;
    }

    toggleExtended($event) {
        this.searchService.extendedSearch.next(!this.extendedSearch)
    }

    makeItem(key: string, values: Array<any>): RelativeMenuItem {
        return {
            label: '+' + key + ':',
            items: values.map((obj) => {
                let value = obj.label || obj;
                return {label: value, data: {key: key, value: value}};
            })
        };
    }

    searchChange() {
        this.searchApplied = false;
        this.searchService.searchModel = this.searchModel;
    }

    searchSubmit() {
        this.searchService.search(this.searchModel);
    }

    clearSearch() {
        this.searchService.clearSearch();
    }

    onResize($event?): void {
        let mq1: MediaQueryList = window.matchMedia('screen and (min-width:80rem)');
        this.smallScreen = !mq1.matches;
    }

    ngOnInit() {
        this.parent = jQuery(".titleSearch__container")[0];

        this.searchService.searchEvents.takeUntil(this.ngDestroyed).subscribe(data => {
            this.searchModel = data;
            this.searchApplied = true;
        });

        this.searchService.extendedSearch.asObservable().takeUntil(this.ngDestroyed).subscribe(data => {
            this.extendedSearch = data;
        });

        this.onResize();
    }

    ngOnDestroy(): void {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    }
}
