<ng-container>
  <div class="sidebar" *mobxAutorun="{ detach: true }">
    <nav class="sidebar-nav">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" (click)="this.navigation.goToDashboard();">
            <i class="icon-arrow-left"></i> Назад
          </a>
        </li>

        <ng-container *ngIf="store.ticketData.info">
          <div class="p-1">
            <p class="stronger">
              <a href="{{store.ticketData.info.panelLinkToOrders}}" target="_blank">
                {{store.ticketData.info.username}}
              </a>
              <span class="badge badge-{{store.ticketData.ticket?.panelPrefix}}">
                {{store.ticketData.ticket?.panelPrefix}}
              </span>&nbsp;
              <a href="{{store.ticketData.info.customerPanelLinks}}" target="_blank">
                <i class="fa fa-link"></i>
              </a>
            </p>
            <div>
              <li>
                <i
                  class="fa {{store.ticketData.info.confirmedEmail ? 'fa-check-square darkgreen' : 'fa-close'}} fa-lg mt-4"></i>
                {{store.ticketData.info.email}}
              </li>
              <li>
                <i class="fa {{store.ticketData.info.activate ? 'fa-check-square darkgreen' : 'fa-close'}} fa-lg mt-4">
                </i>
                {{store.ticketData.info.phone ? store.ticketData.info.phone : 'Телефон не указан'}}
              </li>

              <li>
                <i
                  class="fa {{store.ticketData.ticket.info.confirmedRegInfo ? 'fa-check-square darkgreen' : 'fa-close'}} fa-lg mt-4"></i>
                {{store.ticketData.info.regInfoName ? store.ticketData.info.regInfoName : 'Без рег.инфы'}}
              </li>

            </div>
            <br/>

            <div *ngIf="store.ticketData.ticket.customerNote">
              <span class="info__title">Заметка о клиенте:</span>
              <li [innerHTML]="store.ticketData.ticket.customerNote" class="client-note-alert"></li>
              <br/>
            </div>

            <div *ngIf="store.ticketData.ticket.opNote">
              <span class="info__title">Заметка о заказе:</span>
              <li [innerHTML]="store.ticketData.ticket.opNote" class="client-note-alert"></li>
              <br/>
            </div>

            <div *ngIf="store.ticketData?.activeFlows.length > 0">
              <span class="info__title">Активные заявки:</span>
              <li *ngFor="let flow of store.ticketData?.activeFlows">
              <span>
                
                <a href="{{flow.url}}" target="_blank"><strong>{{flow.op}}</strong> {{flow.description}}</a>
              </span>
              </li>
              <br/>
            </div>

            <span class="info__title" *ngIf="store.ticketData.quickTickets.length > 0">Запросы:</span>
            <li class="nav-item quickTickets"
                *ngFor="let ticket of store.ticketData.quickTickets; let i = index"
                title="{{ticket?.subject}}">
              <a class="nav-link" routerLinkActive="active"
                 [routerLink]="['/support/chat', ticket?.panelPrefix, ticket?.id]">
                <span class="fa fa-ticket"></span>&nbsp;<span class="ticketName">{{ticket?.mask}}</span>
                <span *ngIf="ticket?.unreadMessages > 0" class="badge badge-danger">{{ticket?.unreadMessages}}</span>

                <span style="float: right">{{ticket.activityStr}}</span>
                <br/>
                <div class="ticketInfo">{{ticket?.subject}}</div>
              </a>
            </li>
            <br/>

            <span class="info__title" *ngIf="store.ticketData?.info?.orders.length > 0">Заказы:</span>
            <div>
              <li *ngFor="let op of store.ticketData?.info?.orders">
                <span *ngIf="op.url == null">{{op.name}}</span>
                <a *ngIf="op.url != null" href="{{op.url}}" target="_blank">{{op.name}}</a>
                <span *ngIf="op.owner != store.ticketData?.info?.username"> ({{op.owner}})</span>
              </li>
            </div>
          </div>
        </ng-container>
      </ul>
    </nav>
  </div>

