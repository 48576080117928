export class Tag {
    id: number;
    label: string;
    color: string;

    constructor(tag) {
        this.id = tag.id;
        this.label = tag.label;
        this.color = tag.color;
    }

    get value(): Tag {
        return this;
    }
}
