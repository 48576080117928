import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {RelativeMenuItem} from "../../../../shared/primeng/tieredmenu-relative.component";
import {ChatTextareaEventbus} from "./chat-textarea.eventbus";
import {Subject} from "rxjs";
import {ChatTextareaDirective} from "./chat-textarea.directive";
import {LoginDataservice, UserInfo} from "../../../../core/data/login.dataservice";

@Component({
    selector: 'chat-top-controls',
    templateUrl: './chat-top-controls.component.html'
})
export class ChatTopControlsComponent implements OnChanges {

    private _templateMenuItems: RelativeMenuItem[];
    downloadMenuItems: RelativeMenuItem[];
    insertMenuItems: RelativeMenuItem[];
    templateMenuItemClick: (event?: any) => void;
    insertSnippetItemClick: (event?: any) => void;
    parent;

    @Input() canAddNote: boolean;
    @Input() ticketPanel: string;

    private chatTextareaDirective: ChatTextareaDirective;
    private ngDestroyed: Subject<void> = new Subject<void>();
    private _userInfo: UserInfo;

    constructor(private chatTextareaEventbus: ChatTextareaEventbus,
                private loginDataservice: LoginDataservice,) {
        let _this = this;

        this.templateMenuItemClick = function (event?: any): void {
            let templateMessage: string = event.item.data + "\n\n";
            _this.chatTextareaDirective.insertTextWithPlaceholder(templateMessage);
            _this.chatTextareaDirective.focus();
        };

        this.insertSnippetItemClick = function (event?: any): void {
            _this.chatTextareaEventbus.snippetDialogSubject.next(true);
        };

        this.downloadMenuItems = [
            {label: 'PuTTY'},
            {label: 'WinMTR'},
            {label: 'WinSCP'},
            {label: 'FileZilla'},
        ];

        this.insertMenuItems = [
            {
                label: 'Код (сниппет)',
                command: this.insertSnippetItemClick,
            },
            {label: 'Цитата'},
            {label: 'Изображение'},
            {label: 'Файл'},
        ];

    }

    ngOnChanges(changes: SimpleChanges) {
        if (this._userInfo) {
            this._templateMenuItems = this._userInfo.predefinedRepliesInPanels[this.ticketPanel];
        }
    }

    ngOnInit() {
        this.parent = jQuery("main")[0];
        this.chatTextareaEventbus.chatTextarea.takeUntil(this.ngDestroyed).subscribe(data => {
            this.chatTextareaDirective = data;
        });
        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(data => {
            this._userInfo = data;
        });
        this._templateMenuItems = this._userInfo.predefinedRepliesInPanels[this.ticketPanel];
    }

    get templateMenuItems() {
        return this._templateMenuItems
    }

    ngOnDestroy(): void {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    }

    addNote() {
        this.chatTextareaEventbus.noteDialogSubject.next(true);
    }

    isSelectedPanel(): boolean {
        return this.ticketPanel != null && this.ticketPanel != "?"
    }
}
