var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { InputTextarea } from "primeng/components/inputtextarea/inputtextarea";
import { ElementRef, EventEmitter } from "@angular/core";
var InputTextarea2Directive = /** @class */ (function (_super) {
    __extends(InputTextarea2Directive, _super);
    function InputTextarea2Directive(el) {
        var _this = _super.call(this, el, null) || this;
        _this.el = el;
        _this.onResize = new EventEmitter();
        return _this;
    }
    InputTextarea2Directive.prototype.ngOnInit = function () {
        this.hDefault = this.el.nativeElement.offsetHeight;
    };
    InputTextarea2Directive.prototype.onKeydown = function (e) {
        if (this.autoResize) {
            this.resize(e);
        }
    };
    InputTextarea2Directive.prototype.resize = function (event) {
        var ta = this.el.nativeElement;
        ta.style.height = "" + this.hDefault + "px";
        if (ta.scrollHeight != ta.offsetHeight) {
            if (ta.scrollHeight < this.maxHeight) {
                var fontSize = jQuery(ta).css('font-size');
                var lineHeight = Math.floor(parseInt(fontSize.replace('px', '')) * 1.5);
                ta.style.height = "" + (ta.scrollHeight + lineHeight) + "px";
            }
            else {
                ta.style.height = "" + this.maxHeight + "px";
            }
            this.onResize.emit(event || {});
        }
    };
    return InputTextarea2Directive;
}(InputTextarea));
export { InputTextarea2Directive };
