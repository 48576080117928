import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {ChatComponent} from "./components/chat/chat.component";
import {ChatNewComponent} from "./components/chat/chat-new.component";
import {ChatMassReplyComponent} from "./components/chat/chat-mass-reply.component";

export const routes: Routes = [
    {
        path: '',
        data: {
            title: 'Support'
        },
        children: [
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {path: 'dashboard', component: DashboardComponent, data: {title: 'Dashboard', cid: "dash"}},
            {path: 'chat/:panel/new', component: ChatNewComponent, data: {title: 'Create', cid: "create"}},
            {path: 'chat/:panel/:id', component: ChatComponent, data: {title: 'Chat', cid: "chat"}},
            {path: 'chat/:panel/:id/:anchor', component: ChatComponent, data: {title: 'Chat', cid: "chat"}},
            {path: 'chatMassReply', component: ChatMassReplyComponent, data: {title: 'MassReply', cid: "chat"}},
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SupportRoutingModule {
}

