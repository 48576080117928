export class FormatUtil {

    public static pluralForm(value: number, form0: string, form1: string, form2: string, form5: string): string {
        let orig = value;
        if (Math.abs(value - (Math.floor(value))) > 0) { // определяем дробные числа
            return form5;
        }
        value = Math.floor(value);
        if (value == 0) return (form0 || form5).replace("{}", "" + orig);
        value = Math.abs(value) % 100;
        let r = value % 10;
        if (value > 10 && value < 20) return form5.replace("{}", "" + orig);
        if (r > 1 && r < 5) return form2.replace("{}", "" + orig);
        if (r == 1) return form1.replace("{}", "" + orig);
        return form5.replace("{}", "" + orig);
    }
}
