var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, observable } from "mobx-angular";
var SystemInfoStore = /** @class */ (function () {
    function SystemInfoStore() {
        this.value = SystemInfoStore._emptySystemInfo;
    }
    SystemInfoStore.prototype.set = function (systemInfo) {
        this.value = systemInfo;
    };
    SystemInfoStore.prototype.clear = function () {
        this.value = SystemInfoStore._emptySystemInfo;
    };
    SystemInfoStore._emptySystemInfo = {
        gitInfo: null,
        built: null,
        started: null,
        reCaptchaSiteKey: null,
        translateEnabled: false,
    };
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], SystemInfoStore.prototype, "value", void 0);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SystemInfoStore.prototype, "set", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], SystemInfoStore.prototype, "clear", null);
    return SystemInfoStore;
}());
export { SystemInfoStore };
