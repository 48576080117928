import {BrowserModule} from "@angular/platform-browser";
import {NgxCaptchaModule} from 'ngx-captcha';
import {ErrorHandler, NgModule} from "@angular/core";
import {AppComponent} from "./app.component";
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import {AppRoutingModule} from "./app-routing.module";
import {CoreModule} from "./core/core.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LoginComponent} from "./login/login.component";
import {HttpClientModule} from "@angular/common/http";
import {LogoutComponent} from "./login/logout.component";
import {GrowlModule} from "primeng/primeng";
import {SupportModule} from "./support/support.module";
import {MobxAngularModule} from "mobx-angular";
import {GlobalErrorHandler} from "./core/errors/global-error-handler";
import {ServerLoggingService} from "./core/errors/server-logging.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
    imports: [
        BrowserModule,
        NgxCaptchaModule,
        HttpClientModule,
        CoreModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        GrowlModule,
        SupportModule,
        MobxAngularModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        ServerLoggingService,
        GlobalErrorHandler,
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
