import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./login/login.component";
import {LogoutComponent} from "./login/logout.component";

export const routes: Routes = [{
    path: 'login',
    component: LoginComponent
}, {
    path: 'logout',
    component: LogoutComponent
}, {
    path: '',
    redirectTo: 'support',
    pathMatch: 'full',
}, {
    path: '',
    data: {title: ''},
    children: [{
        path: 'admin',
        loadChildren: './admin/admin.module#AdminModule'
    }, {
        path: 'support',
        loadChildren: './support/support.module#SupportModule'
    }]
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

