import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Links} from "../../../links";
import {Ticket} from "../domain/ticket.domain";
import {AdminService} from "../../core/services/admin.service";
import {GrowlMessageService} from "../../core/services/growl-message.service";
import {TicketBuilder} from "../domain/builder/ticket.builder";
import {Store} from "../store/store";
import {PanelTicketIds} from "./chat-messages.dataservice";

export interface MassReplyLoadTicketList {
    ticketsIdsGroupedByPanels: PanelTicketIds[],
    erase: boolean
}

@Injectable()
export class MassReplyDataservice {

    private uidCounter: number = 1;
    private inProgress: Array<string> = [];

    loadTicketListSubject: Subject<MassReplyLoadTicketList>;

    constructor(private http: HttpClient,
                private adminService: AdminService,
                private store: Store,
                private growlMessageService: GrowlMessageService,
                private ticketBuilder: TicketBuilder) {
        this.loadTicketListSubject = new Subject();
        this.loadTicketListSubject.asObservable().subscribe((p: MassReplyLoadTicketList) => {
            this.loadTicketList(p.ticketsIdsGroupedByPanels, p.erase);
        });
    }

    addProgress(name: string): string {
        let uid: string = name + this.uidCounter++;
        this.inProgress.push(uid);
        return uid;
    }

    endProgress(uid: string): boolean {
        let index = this.inProgress.indexOf(uid);
        if (index > -1) {
            this.inProgress.splice(0, index + 1);
            return this.inProgress.length == 0;
        } else {
            return false
        }
    }

    loadTicketList(ticketsIdsGroupedByPanels: PanelTicketIds[], erase: boolean) {
        if (erase) {
            this.store.massReplyTickets.set({tickets: [], loading: true});
        }
        let uid = this.addProgress("loadTicketList");
        try {
            let body = {ticketsIdsGroupedByPanels: ticketsIdsGroupedByPanels};
            return this.http.post(Links.ticketGetMassReplyTickets, body).filter((resp: Response) => {
                if (resp["unauthorized"] == "true") {
                    this.adminService.unauthorized();
                    return false;
                }
                if (resp["error"]) {
                    this.growlMessageService.error(resp["error"].message);
                    return false;
                }
                return true;
            }).map((resp: Response) => {
                let $tickets: Ticket[] = [];
                for (let ticketData of resp["list"]) {
                    let $ticket = this.ticketBuilder.build(ticketData);
                    $tickets.push($ticket);
                }
                return {tickets: $tickets, loading: false};
            }).subscribe(data => {
                if (this.endProgress(uid)) { // если запрашивается что-то еще, значит этот результат не нужен
                    this.store.massReplyTickets.set(data);
                }
            }, error => {
                console.log(error);
                this.endProgress(uid);
                this.growlMessageService.error("Could not load mass reply tickets list. " + error.message);
            });
        } catch (e) {
            this.endProgress(uid);
        }
    }

}
