var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, observable } from "mobx-angular";
import { Department } from "../domain/department.domain";
var Departments = /** @class */ (function () {
    function Departments(items) {
        var _this = this;
        this.list = [];
        items && items.forEach(function (item) { return _this.list.push(item); });
    }
    Departments.prototype.getById = function (id) {
        return this.list.find(function (e) { return e.id == id; });
    };
    return Departments;
}());
export { Departments };
var DepartmentsStore = /** @class */ (function () {
    function DepartmentsStore() {
        this.value = new Departments();
        this.set([
            DepartmentsStore.dep1,
            DepartmentsStore.dep2,
            DepartmentsStore.dep3,
            DepartmentsStore.dep4,
            DepartmentsStore.dep5,
            DepartmentsStore.dep6,
            DepartmentsStore.dep7,
            DepartmentsStore.dep8,
            DepartmentsStore.dep9,
            DepartmentsStore.dep10,
            DepartmentsStore.dep11,
            DepartmentsStore.dep12,
            DepartmentsStore.dep13,
            DepartmentsStore.dep14,
            DepartmentsStore.dep15,
            DepartmentsStore.dep16,
            DepartmentsStore.dep17,
            DepartmentsStore.dep18,
            DepartmentsStore.dep19,
        ].sort(function (a, b) {
            var x = a.id;
            var y = b.id;
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }));
    }
    DepartmentsStore.prototype.set = function (departments) {
        this.value = new Departments(departments);
    };
    DepartmentsStore.prototype.clear = function () {
        this.value = new Departments();
    };
    Object.defineProperty(DepartmentsStore.prototype, "active", {
        get: function () {
            return this.value.list.filter(function (d) { return d.active; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepartmentsStore.prototype, "all", {
        get: function () {
            return this.value.list;
        },
        enumerable: true,
        configurable: true
    });
    DepartmentsStore.prototype.getById = function (id) {
        return this.value.getById(id);
    };
    DepartmentsStore.dep1 = new Department(1, "Поддержка", true);
    DepartmentsStore.dep2 = new Department(2, "Администраторы", true);
    DepartmentsStore.dep3 = new Department(3, "Отдел продаж", true);
    DepartmentsStore.dep4 = new Department(4, "Бухгалтерия", true);
    DepartmentsStore.dep5 = new Department(5, "Рег-я доменов", true);
    DepartmentsStore.dep6 = new Department(6, "Abuse", true);
    DepartmentsStore.dep7 = new Department(7, "Юр. отдел", true);
    DepartmentsStore.dep8 = new Department(8, "System", false);
    DepartmentsStore.dep9 = new Department(9, "Relevate", false);
    DepartmentsStore.dep10 = new Department(10, "Spamassassin", false);
    DepartmentsStore.dep11 = new Department(11, "Возвраты", false);
    DepartmentsStore.dep12 = new Department(12, "Руководство", true);
    DepartmentsStore.dep13 = new Department(13, "Complaints", false);
    DepartmentsStore.dep14 = new Department(14, "Работа", false);
    DepartmentsStore.dep15 = new Department(15, "MAILER-DAEMON", false);
    DepartmentsStore.dep16 = new Department(16, "Copy", false);
    DepartmentsStore.dep17 = new Department(17, "Служебный", false);
    DepartmentsStore.dep18 = new Department(18, "Общие вопросы", false);
    DepartmentsStore.dep19 = new Department(19, "Other", false);
    __decorate([
        observable,
        __metadata("design:type", Departments)
    ], DepartmentsStore.prototype, "value", void 0);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], DepartmentsStore.prototype, "set", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DepartmentsStore.prototype, "clear", null);
    __decorate([
        computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], DepartmentsStore.prototype, "active", null);
    __decorate([
        computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], DepartmentsStore.prototype, "all", null);
    return DepartmentsStore;
}());
export { DepartmentsStore };
