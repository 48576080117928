export class DateUtil {
    public static parse(dt: string): Date {
        return new Date(dt.replace(/-/g, '/'))
    }

    public static parseDate(dt: string, format: string): Date {
        let date = null;
        if (format == "DD.MM.YYYY") {
            let dateParts = dt.split(".");

            date = new Date(+dateParts[2], (+dateParts[1] - 1), +dateParts[0]);
        }

        return date
    }
}
