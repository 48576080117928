import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Links} from "../../../links";
import {GrowlMessageService} from "../../core/services/growl-message.service";
import {CheckData} from "../services/litera.service";

@Injectable()
export class LiteraDataservice {

    constructor(private http: HttpClient,
                private growlMessageService: GrowlMessageService) {
    }

    check(checkData: CheckData) {
        let params = {
            message: checkData.text,
            ticketMask: checkData.ticket.mask,
            panelPrefix: checkData.ticket.panelPrefix
        };
        this.post(Links.startSpellingCheck, params, checkData.onStart);
    }

    result(checkData: CheckData, callback) {
        this.post(`${Links.literaResult}/${checkData.id}`, {}, callback);
    }

    private post(url: string, params, callback, onerror?) {
        this.http.post(url, params).filter((resp: Response) => {
            if (resp["error"]) {
                this.growlMessageService.error(resp["error"].message);
                if (onerror) {
                    onerror();
                }
                return false;
            }
            if (resp["alert"] && resp["alert"]["type"] == "error") {
                this.growlMessageService.error(resp["alert"]["message"]);
                return true;
            }
            return true;
        }).subscribe(data => {
            callback(data);
        }, error => {
            let data = error.error;
            if (data.message) {
                this.growlMessageService.error(data.message);
            } else {
                this.growlMessageService.error("Ошибка на сервере");
            }
            if (onerror) {
                onerror();
            }
        });
    }

}
