import { SystemInfoStore } from "../../core/store/system-info.store";
import { DepartmentsStore } from "./departments.store";
import { StatusesStore } from "./statuses.store";
import { SidebarFilterStore } from "./sidebar-filter.store";
import { TicketStore } from "./ticket.store";
import { TicketListStore } from "./ticket-list.store";
import { MassReplyTicketListStore } from "./mass-reply-ticket-list.store";
var Store = /** @class */ (function () {
    function Store(_systemInfoStore, _departmentsStore, _statusesStore, _ticketListStore, _massReplyTicketListStore, _sidebarFilterStore, _ticketStore) {
        this._systemInfoStore = _systemInfoStore;
        this._departmentsStore = _departmentsStore;
        this._statusesStore = _statusesStore;
        this._ticketListStore = _ticketListStore;
        this._massReplyTicketListStore = _massReplyTicketListStore;
        this._sidebarFilterStore = _sidebarFilterStore;
        this._ticketStore = _ticketStore;
    }
    Object.defineProperty(Store.prototype, "systemInfo", {
        get: function () {
            return this._systemInfoStore.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "departments", {
        get: function () {
            return this._departmentsStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "tickets", {
        get: function () {
            return this._ticketListStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "massReplyTickets", {
        get: function () {
            return this._massReplyTicketListStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "statuses", {
        get: function () {
            return this._statusesStore.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "sidebarFilter", {
        get: function () {
            return this._sidebarFilterStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "ticketData", {
        get: function () {
            return this._ticketStore;
        },
        enumerable: true,
        configurable: true
    });
    return Store;
}());
export { Store };
