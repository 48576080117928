import { OnDestroy, OnInit } from "@angular/core";
import { TicketService } from "../../../services/ticket.service";
import { ChatMessagesDataservice } from "../../../data/chat-messages.dataservice";
import { Subject } from "rxjs/Subject";
import { TicketDataservice } from "app/support/data/ticket.dataservice";
import { ActivatedRoute, Router } from "@angular/router";
import { ChatInfoShowLogsEvents } from "../../../services/chat-info.show-logs.events";
import { LoginDataservice } from "../../../../core/data/login.dataservice";
import { Store } from "../../../store/store";
import { AdminService } from "../../../../core/services/admin.service";
import { ChatTextareaEventbus } from "../reply/chat-textarea.eventbus";
import { StatusesStore } from "../../../store/statuses.store";
import { NavigationService } from "../../../services/navigation.service";
import { Dropdown } from "primeng/primeng";
import { ChatInfoShowOnlyNotesEvents } from "../../../services/chat-info.show-only-notes.events";
var ChatInfoComponent = /** @class */ (function () {
    function ChatInfoComponent(ticketService, ticketDataService, activatedRoute, router, adminService, chatInfoShowLogsEvents, chatInfoShowOnlyNotesEvents, loginDataservice, navigationService, chatTextareaEventbus, chatMessagesDataservice, _store) {
        this.ticketService = ticketService;
        this.ticketDataService = ticketDataService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.adminService = adminService;
        this.chatInfoShowLogsEvents = chatInfoShowLogsEvents;
        this.chatInfoShowOnlyNotesEvents = chatInfoShowOnlyNotesEvents;
        this.loginDataservice = loginDataservice;
        this.navigationService = navigationService;
        this.chatTextareaEventbus = chatTextareaEventbus;
        this.chatMessagesDataservice = chatMessagesDataservice;
        this._store = _store;
        this.ngDestroyed = new Subject();
    }
    Object.defineProperty(ChatInfoComponent.prototype, "store", {
        get: function () {
            return this._store;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatInfoComponent.prototype, "navigation", {
        get: function () {
            return this.navigationService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatInfoComponent.prototype, "currentLoggedInAdmin", {
        get: function () {
            return this.adminService.currentLoggedInAdmin;
        },
        enumerable: true,
        configurable: true
    });
    ChatInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._ticketPanel = this.activatedRoute.snapshot.params.panel;
        this.chatInfoShowLogsEvents.subscribe(this.ngDestroyed, function (data) {
            _this._showLogs = data;
        });
        this.chatInfoShowOnlyNotesEvents.subscribe(this.ngDestroyed, function (data) {
            _this._showOnlyNotes = data;
        });
        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(function (data) {
            _this._admins = data.adminsInPanels;
            _this._tags = data.tagsInPanels;
        });
        this.chatTextareaEventbus.chatTextarea.takeUntil(this.ngDestroyed).subscribe(function (data) {
            if (data != null) {
                _this.chatTextareaDirective = data;
            }
        });
    };
    ChatInfoComponent.prototype.ngOnDestroy = function () {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    };
    Object.defineProperty(ChatInfoComponent.prototype, "admins", {
        get: function () {
            return this._admins[this._ticketPanel];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatInfoComponent.prototype, "tags", {
        get: function () {
            return this._tags[this._ticketPanel];
        },
        enumerable: true,
        configurable: true
    });
    ChatInfoComponent.prototype.changeDepartment = function (ticketId, deptId) {
        if (ticketId > 0) {
            this.ticketDataService.changeDepartment(this._ticketPanel, ticketId, deptId, this.store.ticketData.ticket.version);
            this.store.ticketData.setAfterPropertyChangeInChat();
        }
        else {
            // new ticket
            this.store.ticketData.changeDepartment(this.store.departments.getById(deptId));
        }
    };
    ChatInfoComponent.prototype.changeStatus = function (ticketId, statusId) {
        if (ticketId > 0) {
            this.ticketDataService.changeStatus(this._ticketPanel, ticketId, statusId, this.store.ticketData.ticket.version);
            if (statusId == StatusesStore.STATUS_CLOSED.id) {
                this.navigationService.goToDashboard();
                this.store.ticketData.clear();
                return;
            }
            this.store.ticketData.setAfterPropertyChangeInChat();
        }
        else {
            // new ticket
            this.store.ticketData.changeStatus(this.store.statuses.getById(statusId));
        }
    };
    ChatInfoComponent.prototype.assignTo = function (ticketId, username) {
        if (ticketId > 0) {
            this.ticketDataService.assignTo(this._ticketPanel, ticketId, username, this.store.ticketData.ticket.version);
            // чтобы не застревало на выбранном элементе
            this.assignedToSelector.selectedOption = null;
            this.assignedToSelector.value = null;
        }
        else {
            // new ticket
            this.store.ticketData.assignTo(this.admins.find(function (a) { return a.username == username; }));
        }
    };
    ChatInfoComponent.prototype.changeTag = function (ticketId, tagId) {
        if (ticketId > 0) {
            this.ticketDataService.changeTag(this._ticketPanel, ticketId, tagId, this.store.ticketData.ticket.version);
        }
        else {
            // new ticket
            this.store.ticketData.changeTag(this.tags.find(function (tag) { return tag.id == tagId; }));
        }
    };
    Object.defineProperty(ChatInfoComponent.prototype, "showOnlyNotes", {
        get: function () {
            return this._showOnlyNotes;
        },
        set: function (show) {
            this.chatInfoShowOnlyNotesEvents.next(show);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatInfoComponent.prototype, "showLogs", {
        get: function () {
            return this._showLogs;
        },
        set: function (show) {
            this.chatInfoShowLogsEvents.next(show);
        },
        enumerable: true,
        configurable: true
    });
    ChatInfoComponent.prototype.scrollToPin = function (id) {
        this.showOnlyNotes = false;
        setTimeout(function () {
            var pos = $("#anchor" + id).position();
            $('html, body').animate({ scrollTop: pos.top }, 400);
        }, 100);
    };
    return ChatInfoComponent;
}());
export { ChatInfoComponent };
