import {NgModule} from "@angular/core";
import {ChatComponent} from "./components/chat/chat.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {SupportRoutingModule} from "./support-routing.module";
import {SharedModule} from "../shared/shared.module";
import {LayoutModule} from "../layouts/layout.module";
import {TicketService} from "./services/ticket.service";
import {SearchService} from "./services/search.service";
import {ChatMessagesDataservice} from "./data/chat-messages.dataservice";
import {ChatMarkdownService} from "./services/chat-markdown.service";
import {NavigationService} from "./services/navigation.service";
import {TicketListDataservice} from "./data/ticket-list.dataservice";
import {TicketDataservice} from "./data/ticket.dataservice";
import {DashboardLayoutComponent} from "./components/dashboard/dashboard-layout.component";
import {ChatLayoutComponent} from "./components/chat/chat-layout.component";
import {ChatInfoShowLogsEvents} from "./services/chat-info.show-logs.events";
import {ChatNewComponent} from "./components/chat/chat-new.component";
import {ChatMassReplyComponent} from "./components/chat/chat-mass-reply.component";
import {SidebarSupportComponent} from "./components/dashboard/sidebar/sidebar-support.component";
import {ExtendedSearchComponent} from "./components/dashboard/search/extended-search.component";
import {TitleSearchComponent} from "./components/dashboard/search/title-search.component";
import {AsideSupportService} from "./components/chat/aside/aside-support.service";
import {ChatTextareaEventbus} from "./components/chat/reply/chat-textarea.eventbus";
import {AsideSupportComponent} from "./components/chat/aside/aside-support.component";
import {ChatInfoComponent} from "./components/chat/aside/chat-info.component";
import {ChatSidebarComponent} from "app/support/components/chat/sidebar/chat-sidebar.component";
import {ChatSettingsComponent} from "./components/chat/aside/chat-settings.component";
import {ChatTopControlsComponent} from "./components/chat/reply/chat-top-controls.component";
import {ChatTextareaDirective} from "./components/chat/reply/chat-textarea.directive";
import {MobxAngularModule} from "mobx-angular";
import {Store} from "./store/store";
import {DepartmentsStore} from "./store/departments.store";
import {StatusesStore} from "./store/statuses.store";
import {SidebarFilterStore} from "./store/sidebar-filter.store";
import {AdminBuilder} from "./domain/builder/admin.builder";
import {MessageBuilder} from "./domain/builder/message.builder";
import {TicketBuilder} from "./domain/builder/ticket.builder";
import {TicketInfoBuilder} from "./domain/builder/ticket-info.builder";
import {TicketStore} from "./store/ticket.store";
import {TicketListStore} from "./store/ticket-list.store";
import {MessageComponent} from "./components/chat/message.component";
import {ChatInfoShowOnlyNotesEvents} from "./services/chat-info.show-only-notes.events";
import {LiteraDataservice} from "./data/litera.dataservice";
import {CheckService} from "./services/litera.service";
import {ChatAttachmentsComponent} from "./components/chat/reply/chat-attachments.component";
import {MassReplyDataservice} from "./data/mass-reply.dataservice";
import {MassReplyTicketListStore} from "./store/mass-reply-ticket-list.store";
import {TicketPreviewComponent} from "./components/chat/ticket-preview.component";

/* Тикетница (Cookbook/NgModule/Domain Feature Module) */

@NgModule({
    imports: [
        SharedModule,
        LayoutModule,
        SupportRoutingModule,
        MobxAngularModule,
    ],
    providers: [
        SearchService,
        AsideSupportService,
        TicketService,
        ChatMarkdownService,
        CheckService,
        ChatInfoShowLogsEvents,
        ChatInfoShowOnlyNotesEvents,

        ChatTextareaEventbus,

        NavigationService,
        ChatMessagesDataservice,
        TicketListDataservice,
        MassReplyDataservice,
        TicketDataservice,
        LiteraDataservice,
        DepartmentsStore,
        StatusesStore,
        SidebarFilterStore,
        TicketStore,
        TicketListStore,
        MassReplyTicketListStore,
        Store,

        AdminBuilder,
        MessageBuilder,
        TicketBuilder,
        TicketInfoBuilder,
    ],
    declarations: [
        SidebarSupportComponent,
        AsideSupportComponent,
        TitleSearchComponent,
        ExtendedSearchComponent,

        ChatComponent,
        ChatNewComponent,
        ChatMassReplyComponent,
        ChatLayoutComponent,
        ChatInfoComponent,
        ChatSettingsComponent,
        ChatSidebarComponent,
        ChatTopControlsComponent,
        ChatAttachmentsComponent,
        MessageComponent,
        TicketPreviewComponent,
        DashboardComponent,
        DashboardLayoutComponent,
        ChatTextareaDirective
    ]
})
export class SupportModule {
}
