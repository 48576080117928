var Tag = /** @class */ (function () {
    function Tag(tag) {
        this.id = tag.id;
        this.label = tag.label;
        this.color = tag.color;
    }
    Object.defineProperty(Tag.prototype, "value", {
        get: function () {
            return this;
        },
        enumerable: true,
        configurable: true
    });
    return Tag;
}());
export { Tag };
