import { Subject } from "rxjs";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
var ChatTextareaEventbus = /** @class */ (function () {
    function ChatTextareaEventbus() {
        this.snippetDialogSubject = new Subject();
        this.noteDialogSubject = new Subject();
        this._chatTextarea = new BehaviorSubject(null);
        this.chatTextarea = this._chatTextarea.asObservable();
    }
    Object.defineProperty(ChatTextareaEventbus.prototype, "chatTextareaBehaviorSubject", {
        get: function () {
            return this._chatTextarea;
        },
        enumerable: true,
        configurable: true
    });
    return ChatTextareaEventbus;
}());
export { ChatTextareaEventbus };
