<div layout-growl style="z-index: 9999;position: absolute">
  <p-growl [value]="msgs" [sticky]="true" (onClose)="removeGrowlMsg($event)"></p-growl>
</div>

<div layout-modal-backdrop>
  <ng-container *ngIf="store.ticketData.ticketChanged">

    <div style="display: block">
      <div class="modal-backdrop"></div>

      <div class="ticket__updateInfo">

        <div>
          <h2>Тикет был изменен!</h2>
          <span (click)="reloadData($event)" class="btn btn-primary">(Esc) Обновить данные на странице</span>
        </div>
      </div>

    </div>
  </ng-container>
</div>

<chat-layout *ngIf="ticketData == null">
  <form layout-title class="form-title px-h hidden-md-down col">
    <div class="chat-data-title">Загрузка...</div>
  </form>

  <ol layout-subtitle class="breadcrumb hidden-lg-up" style="margin-bottom: 0;">
    <div class="chat-data-title">Загрузка...</div>
  </ol>
</chat-layout>

<chat-layout *ngIf="ticketData != null">

  <form layout-title class="form-title px-h hidden-md-down col">
    <div class="chat-data-title">
      <span class="badge badge-{{ticketData.ticket.panelPrefix}}">{{ticketData.ticket.panelPrefix}}</span>&nbsp;
      <span (click)="selectTicketMask($event)">{{ticketData.ticket.mask}}</span> &mdash; {{ticketData.ticket.subject}}
    </div>
  </form>

  <ol layout-subtitle class="breadcrumb hidden-lg-up" style="margin-bottom: 0;">
    <div class="chat-data-title">{{ticketData.ticket.mask}} &mdash; {{ticketData.ticket.subject}}</div>
  </ol>

  <ul layout-sidebar class="nav">
    <chat-sidebar></chat-sidebar>
  </ul>

  <aside-support layout-aside></aside-support>

  <div layout-main class="animated fadeIn fast" (mouseup)="selectQuote()">
    <div class="chat-pad mx-auto">

      <div class="chat">
        <div class="chat-history">
          <div class="chat-ul">
            <div *ngFor="let ticketMessage of messages; let i = index" class="clearfix"
                 [class.message-deleted]="!ticketMessage.active">
              <message [ticketMessage]="ticketMessage" (onOpenRemoveMsgDialog)="openRemoveMsgDialog($event)"></message>
            </div>

            <div class="text-center mb-1" *ngIf="messages.length > 0">
              <button class="btn-link" (click)="scrollTop()">⬆️&nbsp; в начало</button>
            </div>

            <div class="typingAdmins">
              <div *ngIf="typingAdmins && typingAdmins.length > 0">
                <div class="spinner">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>
                <div class="typingAdmins__info">
                  <span *ngFor="let typingAdmin of typingAdmins; let isLast=last">
                    {{typingAdmin}}{{isLast ? "" : ", "}}
                  </span>
                  <span>{{typingAdmins.length == 1 ? " печатает" : "печатают"}}</span>
                </div>
              </div>
            </div>

            <div file-drop class="chat-message clearfix">
              <form (submit)="postMessage($event, chatMessage.value, true)" class="message__form">
                
                <input type="hidden" #chatMessageId value="">

                <chat-top-controls [canAddNote]="true"
                                   [ticketPanel]="ticketData.ticket.panelPrefix"></chat-top-controls>

                <textarea chatTextarea #chatMessage name="message-to-send" id="message-to-send"
                          placeholder=""
                          (keydown.meta.enter)="postMessageByHotKey($event, chatMessage.value, true);"
                          (keydown.control.enter)="postMessageByHotKey($event, chatMessage.value, true);"
                          rows="5" [maxHeight]="400" autoResize="autoResize"></textarea>
                <br/>

                <chat-attachments></chat-attachments>

                <div class="sent-buttons">
                  <div class="pull-right" *ngIf="!this.store.ticketData.afterPropertyChangeInChat">
                    <div style="display: inline-block" [class.hidden]="isProcessReply()">
                      <a class="reply-submit-not-pending pseudo-link"
                         (click)="postMessage($event, chatMessage.value, null);">
                        Добавить ответ
                      </a>
                      или&nbsp;
                      <a class="reply-submit-not-pending pseudo-link"
                         (click)="postMessage($event, chatMessage.value, false);">
                        Ответить и в работу
                      </a>
                      или&nbsp;
                    </div>
                    <button type="submit" class="btn btn-primary reply-submit" [disabled]="isProcessReply()">
                      {{isProcessReply() ? 'Отправляется...': 'Ответить и отложить'}}
                    </button>
                  </div>

                  <div class="pull-right" *ngIf="this.store.ticketData.afterPropertyChangeInChat">
                    <button type="submit" class="btn btn-primary reply-submit" [disabled]="isProcessReply()">
                      {{isProcessReply() ? 'Отправляется...': 'Ответить'}}
                    </button>
                  </div>
                </div>
                
              </form>
            </div>
            <div style="height: 20px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div layout-dialogs>
    <p-dialog #noteDialog [visible]="noteDialogDisplay" [responsive]="true" [draggable]="false"
              class="noteDialog" header="Новая заметка" modal="modal" width="600">
      <form>
        <p>
          <textarea pInputTextarea2 #noteText rows="10" [maxHeight]="500" autoResize="autoResize"></textarea>
        </p>
        <p-footer>
          <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button (click)="postNote($event, noteText.value, true); noteText.value='';" pButton icon="fa-thumb-tack"
                    label="Отправить и закрепить"></button>
            <button (click)="postNote($event, noteText.value, false); noteText.value='';" pButton icon="fa-check"
                    label="Отправить"></button>
            <button (click)="closeNoteDialog()" class="ui-button-secondary" pButton icon="fa-close"
                    label="Отменить"></button>
          </div>
        </p-footer>
      </form>
    </p-dialog>

    <p-dialog [(visible)]="removeMsgDialog" [responsive]="true" [draggable]="false"
              header="Удалить сообщение?" modal="modal" width="400">
      <div class="ui-dialog-buttonpane ui-helper-clearfix">
        <button type="submit" pButton icon="fa-check" label="Да" (click)="removeMessage()"></button>
        <button type="button" class="ui-button-secondary" (click)="closeRemoveMsgDialog()" pButton
                icon="fa-close"
                label="Нет"></button>
      </div>
    </p-dialog>
    
    <p-dialog [visible]="hasTranslatedText()" (onHide)="removeTranslatedText()">
      <div innerHtml="{{this.store.ticketData.translatedText}}" class="message__text">
        {{this.store.ticketData.translatedText}}
      </div>
    </p-dialog>

  </div>


</chat-layout>

<div class="quote-block" *ngIf="ticketData != null">
  <div class="quote-btn" (click)="quotation()">Цитировать</div>
  <div class="quote-btn" (click)="translateQuoteText()">Перевести</div>
  <div class="quote-arrow"></div>
</div>

<div class="litera" layout-modal-backdrop style="display: none" *ngIf="literaIframe">
  <iframe frameborder="0" class="iframe-litera"></iframe>
  <div class="iframe-litera-close">
      <a class="pseudo-link correction-skip" style="float: left; margin-top: 1em" (click)="sendMsgWithoutCheck()">
        Отправить без проверки
      </a>
      <span style="float: right" class="fa fa-times fa-2x pointer" (click)="closeIframe()"></span>
      <span style="float: right;margin-right: 10px;" class="fa fa-refresh fa-2x pointer" (click)="reloadIframe()"></span>
  </div>
</div>
