import {Injectable} from "@angular/core";
import {action, observable} from "mobx-angular";

export interface GitInfo {
    revision: string;
    branch: string;
    lastCommitTime: string;
}

export interface SystemInfo {
    gitInfo: GitInfo;
    built: string;
    started: string;
    reCaptchaSiteKey: string;
    translateEnabled: boolean;
}

@Injectable()
export class SystemInfoStore {

    private static _emptySystemInfo: SystemInfo = {
        gitInfo: null,
        built: null,
        started: null,
        reCaptchaSiteKey: null,
        translateEnabled: false,
    };

    @observable
    value: SystemInfo = SystemInfoStore._emptySystemInfo;

    constructor() {
    }

    @action
    set(systemInfo: SystemInfo) {
        this.value = systemInfo;
    }

    @action
    clear() {
        this.value = SystemInfoStore._emptySystemInfo;
    }
}
