var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, observable } from "mobx-angular";
import { Message } from "../domain/message.domain";
import { Ticket } from "../domain/ticket.domain";
import { Subject } from "rxjs/Subject";
import { TicketInfo } from "../domain/ticketInfo.domain";
import { Department } from "../domain/department.domain";
import { Status } from "../domain/status.domain";
import { Admin } from "../domain/admin.domain";
import { Tag } from "../domain/tag.domain";
var TicketStore = /** @class */ (function () {
    function TicketStore() {
        this._changed = new Subject();
        this.changed = this._changed.asObservable();
        this.value = null;
        this.actualVersion = -1;
        this.typingAdmins = [];
        this.activeFlows = [];
        this.afterPropertyChangeInChat = false; // IHC-6682 только для текущего тикета, до отправки ответа
        this.translatedText = null;
    }
    TicketStore.prototype.setAfterPropertyChangeInChat = function () {
        this.afterPropertyChangeInChat = true;
    };
    TicketStore.prototype.clearAfterPropertyChangeInChat = function () {
        this.afterPropertyChangeInChat = false;
    };
    TicketStore.prototype.set = function (ticketData) {
        if (!this.value || !this.value.ticket || this.value.ticket.id != ticketData.ticket.id) {
            // тикет сменился, забываем
            this.afterPropertyChangeInChat = false;
        }
        this.value = ticketData;
        this.actualVersion = ticketData.ticketVersion;
        this.setActiveFlows(ticketData["activeFlows"] || []);
        this.setTypingAdmins([]);
        this._changed.next(ticketData);
    };
    TicketStore.prototype.setInfo = function (info) {
        this.value.ticket.info = info;
    };
    TicketStore.prototype.setSync = function (sync) {
        if (this.value && this.value.ticket && sync["ticketId"] === this.value.ticket.id) {
            this.setTypingAdmins(sync["typingAdmins"] || []);
            this.setActiveFlows(sync["activeFlows"] || []);
            this.setActualVersion(sync["ticketVersion"] || 0);
            this.value.ticket.unread = sync["unread"] || false;
        }
    };
    TicketStore.prototype.setActualVersion = function (actualVersion) {
        this.actualVersion = actualVersion;
    };
    TicketStore.prototype.setTypingAdmins = function (typingAdmins) {
        this.typingAdmins = typingAdmins;
    };
    TicketStore.prototype.setActiveFlows = function (flows) {
        this.activeFlows = flows;
    };
    TicketStore.prototype.clear = function () {
        this.afterPropertyChangeInChat = false;
        this.value = null;
        this.actualVersion = -1;
        this.setActiveFlows([]);
        this.setTypingAdmins([]);
    };
    TicketStore.prototype.changeDepartment = function (dep) {
        this.value.ticket.department = dep;
    };
    TicketStore.prototype.changeStatus = function (status) {
        this.value.ticket.status = status;
    };
    TicketStore.prototype.assignTo = function (admin) {
        this.value.ticket.assignedTo = admin;
    };
    TicketStore.prototype.changeTag = function (tag) {
        this.value.ticket.tag = tag;
    };
    TicketStore.prototype.translateMessage = function (messageId, translatedText) {
        this.value.messages.filter(function (m) { return m.id == messageId; }).forEach(function (m) {
            m.textTranslated = translatedText;
            m.translated = true;
        });
    };
    TicketStore.prototype.translateText = function (translatedText) {
        this.translatedText = translatedText;
    };
    Object.defineProperty(TicketStore.prototype, "ticket", {
        get: function () {
            if (this.value == null) {
                return null;
            }
            return this.value.ticket;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketStore.prototype, "isNewTicket", {
        get: function () {
            if (this.value == null || this.value.ticket == null) {
                return false;
            }
            return this.value.ticket.id < 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketStore.prototype, "quickTickets", {
        get: function () {
            if (this.value == null) {
                return [];
            }
            return this.value.quickTickets || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketStore.prototype, "info", {
        get: function () {
            if (this.ticket == null) {
                return null;
            }
            return this.ticket.info;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketStore.prototype, "pinnedNotes", {
        get: function () {
            return this.value.messages.filter(function (m) { return m.type == "note" && m.pinned && m.active; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketStore.prototype, "lastMessage", {
        get: function () {
            return this.value.messages.slice().reverse().find(function (m) { return (m.type != "log") && m.active; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketStore.prototype, "ticketChanged", {
        get: function () {
            if (!this.value) {
                return false;
            }
            return this.actualVersion != this.value.ticketVersion;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketStore.prototype, "countNotes", {
        get: function () {
            return this.value.messages.filter(function (m) { return m.type == "note" && m.active; }).length;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], TicketStore.prototype, "value", void 0);
    __decorate([
        observable,
        __metadata("design:type", Number)
    ], TicketStore.prototype, "actualVersion", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], TicketStore.prototype, "typingAdmins", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], TicketStore.prototype, "activeFlows", void 0);
    __decorate([
        observable,
        __metadata("design:type", Boolean)
    ], TicketStore.prototype, "afterPropertyChangeInChat", void 0);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "setAfterPropertyChangeInChat", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "clearAfterPropertyChangeInChat", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "set", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [TicketInfo]),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "setInfo", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "setSync", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "setActualVersion", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "setTypingAdmins", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "setActiveFlows", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "clear", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Department]),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "changeDepartment", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Status]),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "changeStatus", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Admin]),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "assignTo", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Tag]),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "changeTag", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, String]),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "translateMessage", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], TicketStore.prototype, "translateText", null);
    __decorate([
        computed,
        __metadata("design:type", Ticket),
        __metadata("design:paramtypes", [])
    ], TicketStore.prototype, "ticket", null);
    __decorate([
        computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], TicketStore.prototype, "isNewTicket", null);
    __decorate([
        computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], TicketStore.prototype, "quickTickets", null);
    __decorate([
        computed,
        __metadata("design:type", TicketInfo),
        __metadata("design:paramtypes", [])
    ], TicketStore.prototype, "info", null);
    __decorate([
        computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], TicketStore.prototype, "pinnedNotes", null);
    __decorate([
        computed,
        __metadata("design:type", Message),
        __metadata("design:paramtypes", [])
    ], TicketStore.prototype, "lastMessage", null);
    __decorate([
        computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], TicketStore.prototype, "ticketChanged", null);
    __decorate([
        computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], TicketStore.prototype, "countNotes", null);
    return TicketStore;
}());
export { TicketStore };
