import { ElementRef, OnInit } from "@angular/core";
var TooltipComponent = /** @class */ (function () {
    /************************************************************* LIFE CYCLE *************************************************************/
    function TooltipComponent(_host) {
        this._host = _host;
    }
    TooltipComponent.prototype.ngOnInit = function () {
        this._host.nativeElement.style.display = "none";
    };
    Object.defineProperty(TooltipComponent.prototype, "element", {
        /************************************************************* ACCESSOR *************************************************************/
        get: function () {
            return this._host.nativeElement;
        },
        enumerable: true,
        configurable: true
    });
    return TooltipComponent;
}());
export { TooltipComponent };
