<div class="input-group" (window:resize)="onResize($event)">
  <div class="input-group-btn titleSearch__container" dropdown>
    <button #btn type="button"
            class="btn panel-toggle"
            (click)="toggleExtended($event)">
      <i class="fa fa-toggle-right" [class.hidden]="extendedSearch"></i>
      <i class="fa fa-toggle-down" [class.hidden]="!extendedSearch"></i>
      &nbsp;<span *ngIf="!smallScreen">Расширенный поиск</span>
    </button>
  </div>
  <input
    type="text"
    class="form-control"
    [class.searchApplied]="searchApplied"
    autofocus="autofocus"
    placeholder="Поиск"
    [(ngModel)]="searchModel"
    (keyup)="searchChange()"
    (keyup.enter)="searchSubmit()"
  >
  <span class="input-group-btn">
      <button class="btn btn-primary" type="button" (click)="searchSubmit()">
       {{timer}} <i class="fa fa-refresh"></i>
      </button>
    </span>
  <span class="input-group-btn">
      <button class="btn btn-primary" type="button" (click)="clearSearch()">
        <i class="fa fa-remove"></i>
      </button>
    </span>
</div>
