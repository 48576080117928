/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./tooltip.component";
var styles_TooltipComponent = [];
var RenderType_TooltipComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TooltipComponent, data: {} });
export { RenderType_TooltipComponent as RenderType_TooltipComponent };
export function View_TooltipComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_TooltipComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tooltip", [], null, null, null, View_TooltipComponent_0, RenderType_TooltipComponent)), i0.ɵdid(1, 114688, null, 0, i1.TooltipComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TooltipComponentNgFactory = i0.ɵccf("tooltip", i1.TooltipComponent, View_TooltipComponent_Host_0, {}, {}, ["*"]);
export { TooltipComponentNgFactory as TooltipComponentNgFactory };
