export class LinkUtil {
    public static replaceSupportControllerLinks(text: string, panel: string): string {
        let baseUrl = window.location.origin;

        return text.replace(
            /href="((https?|ftp):([\/\\])+[\w\d:#@%/;\$()~_?\+\-=\\\.&]*)\/support\/viewTicket/ig,
            "href=\"" + baseUrl + "/#/support/chat/" + panel
        );
    }

    public static escape(html: string): string {
        return html.replace(/#/g, '%23');
    }
}
