import {Injectable} from "@angular/core";
import {MarkdownService} from "angular2-markdown";
import {ChatTextareaDirective} from "../components/chat/reply/chat-textarea.directive";

declare var require: any;
var marked = require("marked");

@Injectable()
export class ChatMarkdownService {

    eMarkdown: any;

    constructor(private mdService: MarkdownService) {
    }

    initTextareaEditor(chat: ChatTextareaDirective) {
        let __this = this;
        (jQuery(chat.textArea) as any).markdown({
            autofocus: false,
            hideable: false,
            savable: false,
            width: 'inherit',
            height: 'inherit',
            resize: 'none',
            iconlibrary: 'fa',
            language: 'en',
            initialstate: 'editor',
            hiddenButtons: ["cmdBold", "cmdItalic", "cmdHeading", "cmdList", "cmdListO"],
            parser: function (text) {
                return __this.parse(text);
            },
            fullscreen: {enable: false},
            onShow: function (markdown) {
                markdown.$textarea.focus();
            },
            onPreview: function (e) {
                __this.eMarkdown = e;
                setTimeout(function () {
                    Prism.highlightAll(false);
                }, 1);
                return null;
            }
        });
    }

    hidePreview() {
        if (this.eMarkdown) {
            this.eMarkdown.hidePreview();
        }
    }

    parse(text) {
        return this.mdService.compile(this.escape(text, true));
    }

    private escape(html, encode) {
        return html
            .replace(!encode ? /&(?!#?\w+;)/g : /&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;');
    }

    private unescape(html) {
        // explicitly match decimal, hex, and named HTML entities
        return html.replace(/&(#(?:\d+)|(?:#x[0-9A-Fa-f]+)|(?:\w+));?/g, function (_, n) {
            n = n.toLowerCase();
            if (n === 'colon') return ':';
            if (n.charAt(0) === '#') {
                return n.charAt(1) === 'x'
                    ? String.fromCharCode(parseInt(n.substring(2), 16))
                    : String.fromCharCode(+n.substring(1));
            }
            return '';
        });
    }
}
