export class Links {
    public static errorLog = '/frontLog/errorLog';
    public static debugLog = '/frontLog/debugLog';
    public static systemInfo = '/info/version';
    public static login = '/login/login';
    public static info = '/login/info';
    public static logout = '/login/logout';
    public static ticketList = '/ticket/list';
    public static ticketSearch = '/ticket/search';
    public static ticketNewMessage = '/ticket/newMessage';
    public static ticketMassReply = '/ticket/newMassMessage';
    public static ticketGetMassReplyTickets = '/ticket/getMassReplyTickets';
    public static ticketCreateMessage = '/ticket/createTicketMessage';
    public static ticketMessages = '/ticket/messages';
    public static getNewTicketData = '/ticket/getNewTicketData';
    public static assignTo = '/ticket/assignTo';
    public static changeDepartment = '/ticket/changeDepartment';
    public static changeStatus = '/ticket/changeStatus';
    public static removeMessage = '/ticket/removeMessage';
    public static pinMessage = '/ticket/pinMessage';
    public static unpinMessage = '/ticket/unpinMessage';
    public static changeTags = '/ticket/changeTags';
    public static ticketSync = '/ticket/sync';
    public static serverLogError = '/serverLog/error';
    public static getNotificationSettings = '/ticket/getNotificationSettings';
    public static literaCheck = '/litera/check';
    public static literaResult = '/litera/result';
    public static startSpellingCheck = '/ticket/startSpellingCheck';
    public static translate = '/ticket/translate';
    public static changeMassStatus = '/ticket/changeMassStatus';
}
