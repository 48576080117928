import {Injectable} from "@angular/core";
import {Store} from "../../store/store";
import {TicketInfo} from "../ticketInfo.domain";

@Injectable()
export class TicketInfoBuilder {

    constructor(private store: Store) {
    }

    build(data): TicketInfo {
        let $ticketInfo = new TicketInfo();
        $ticketInfo.ticketId = data["ticketId"];
        $ticketInfo.username = data["username"];
        $ticketInfo.email = data["email"];
        $ticketInfo.phone = data["phone"];
        $ticketInfo.orders = data["orders"];
        $ticketInfo.regInfoName = data["regInfoName"];
        $ticketInfo.confirmedEmail = data["confirmedEmail"];
        $ticketInfo.activate = data["activate"];
        $ticketInfo.confirmedRegInfo = data["confirmedRegInfo"];
        $ticketInfo.panelLinkToOrders = data["panelLinkToOrders"];
        $ticketInfo.customerPanelLinks = data["customerPanelLinks"];
        return $ticketInfo
    }
}
