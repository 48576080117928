import { OnDestroy, OnInit } from "@angular/core";
import { ChatMessagesDataservice } from "../../../data/chat-messages.dataservice";
import { Subject } from "rxjs/Subject";
import { NavigationService } from "../../../services/navigation.service";
import { Store } from "../../../store/store";
var ChatSidebarComponent = /** @class */ (function () {
    function ChatSidebarComponent(chatMessagesDataservice, navigationService, _store) {
        this.chatMessagesDataservice = chatMessagesDataservice;
        this.navigationService = navigationService;
        this._store = _store;
        this.ngDestroyed = new Subject();
    }
    Object.defineProperty(ChatSidebarComponent.prototype, "store", {
        get: function () {
            return this._store;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatSidebarComponent.prototype, "navigation", {
        get: function () {
            return this.navigationService;
        },
        enumerable: true,
        configurable: true
    });
    ChatSidebarComponent.prototype.ngOnInit = function () {
    };
    ChatSidebarComponent.prototype.ngOnDestroy = function () {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    };
    return ChatSidebarComponent;
}());
export { ChatSidebarComponent };
