import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Message} from "../domain/message.domain";
import {Links} from "../../../links";
import {GrowlMessageService} from "../../core/services/growl-message.service";
import {AdminService} from "../../core/services/admin.service";
import {TicketBuilder} from "../domain/builder/ticket.builder";
import {MessageBuilder} from "../domain/builder/message.builder";
import {Store} from "../store/store";

export interface ReplayLoadMessagesData {
    panel: string,
    ticketId: number
}

export interface PanelTicketIds {
    panelPrefix: string;
    ids: number[];
}

export interface MassReplyAnswer {
    panelPrefix: string;
    sended: boolean;
}

export interface MessageSent {
    sent: boolean;
    goToDashboard: boolean;
}

@Injectable()
export class ChatMessagesDataservice {

    private _processReply: boolean = false;
    replayData: ReplayLoadMessagesData;

    messageSent: Subject<MessageSent> = new Subject();

    constructor(private http: HttpClient,
                private adminService: AdminService,
                private growlMessageService: GrowlMessageService,
                private ticketBuilder: TicketBuilder,
                private messageBuilder: MessageBuilder,
                private store: Store) {
    }

    ticketSynchronization(formData) {
        this.http.post(Links.ticketSync, formData).filter((resp: Response) => {
            return true;
        }).map((resp: Response) => {
            if (resp["error"]) {
                this.growlMessageService.error(resp["error"].message);
            }
            this.store.ticketData.setSync(resp);
        }).subscribe(data => {
            }, error => {
                console.log(error);
            }
        );
    }

    reply(panel: string, ticketId: number, formData, isMessage: boolean, goToDashboard: boolean = true, processReply: boolean = true) {
        this._processReply = processReply;
        this.http.post(Links.ticketNewMessage, formData).filter((resp: Response) => {
            if (resp["unauthorized"] == "true") {
                this.adminService.unauthorized();
                return false;
            }
            return true;
        }).map((resp: Response) => {
            if (resp["error"]) {
                this.growlMessageService.error(resp["error"].message);
            }
            this.loadMessages(panel, ticketId, false);
            if (isMessage) {
                this.messageSent.next({sent: true, goToDashboard: goToDashboard});
            }
        }).subscribe(data => {
            }, error => {
                console.log(error);
                let data = error.error;
                if (data.error.message) {
                    this.growlMessageService.error(data.error.message);
                } else {
                    this.growlMessageService.error("Could not post reply. " + error.message);
                }
            }
        );
    }

    massReply(message: string, postpone: boolean, ticketsIdsGroupedByPanels: PanelTicketIds[], processReply: boolean = true) {
        this._processReply = processReply;

        let body = {message: message, postpone: postpone, ticketsIdsGroupedByPanels: ticketsIdsGroupedByPanels};

        this.http.post(Links.ticketMassReply, body).filter((resp: Response) => {
            if (resp["unauthorized"] == "true") {
                this.adminService.unauthorized();
                return false;
            }
            return true;
        }).map((resp: Response) => {
            if (resp["error"]) {
                this.growlMessageService.error(resp["error"].message);
            }
            if (resp["answers"]) {
                let answers: MassReplyAnswer[] = resp["answers"];
                answers.forEach(answer => {
                    if (answer.sended) {
                        let successMsg: string = `В ${answer.panelPrefix} сообщения отправлены`;
                        this.growlMessageService.success("", successMsg);
                    } else {
                        let errorMsg: string = `В ${answer.panelPrefix} не удалось отправить сообщения`;
                        this.growlMessageService.error(errorMsg);
                    }
                });
            }
            this.messageSent.next({sent: true, goToDashboard: true});
            this._processReply = false;
        }).subscribe(data => {
            }, error => {
                console.log(error);
                this.growlMessageService.error("Could not post reply. " + error.message);
                this._processReply = false;
            }
        );
    }

    create(panel: string, formData) {
        this._processReply = true;
        this.http.post(Links.ticketCreateMessage, formData).filter((resp: Response) => {
            if (resp["unauthorized"] == "true") {
                this.adminService.unauthorized();
                return false;
            }
            return true;
        }).map((resp: Response) => {
            if (resp["error"]) {
                this.growlMessageService.error(resp["error"].message);
            }
            this.messageSent.next({sent: true, goToDashboard: true});
        }).subscribe(data => {
            }, error => {
                console.log(error);
                this.growlMessageService.error("Could not post reply. " + error.message);
                this._processReply = false;
            }
        );
    }

    filterErrors(resp: Response): boolean {
        if (resp["unauthorized"] == "true") {
            this.adminService.unauthorized();
            return false;
        }
        if (resp["error"]) {
            this.growlMessageService.error(resp["error"].message);
            return false;
        }
        return true;
    }

    parseMessages(resp: Response) {
        let $messages: Message[] = [];
        for (let messageData of resp["list"]) {
            let $message = this.messageBuilder.build(messageData, resp["ticket"]["panelPrefix"]);
            $messages.push($message)
        }

        let ticketData = resp["ticket"];
        let c = ticketData["customer"];
        let $ticket = this.ticketBuilder.build(ticketData);
        let quickTickets = resp["quickTickets"].map((data: any) => this.ticketBuilder.build(data));
        let ticketVersion = resp["ticketVersion"];

        return {
            messages: $messages,
            ticket: $ticket,
            username: $ticket.username,
            quickTickets: quickTickets,
            ticketVersion: ticketVersion,
            activeFlows: resp["activeFlows"]
        };
    }

    loadMessages(panel: string, ticketId: number, erase: boolean) {
        this.replayData = {panel: panel, ticketId: ticketId};
        if (erase) {
            this.store.ticketData.clear();
        }
        return this.http.post(Links.ticketMessages, {id: ticketId, panel: panel})
            .filter((resp: Response) => this.filterErrors(resp))
            .map((resp: Response) => this.parseMessages(resp))
            .subscribe(data => {
                this.store.ticketData.set(data);
                this._processReply = false;
            }, error => {
                console.log(error);
                this.growlMessageService.error("Could not load ticket data. " + error.message);
                this._processReply = false;
            });
    }

    getNewTicketData(panel: string, personId: number) {
        this.replayData = null;
        this.store.ticketData.clear();
        return this.http.post(Links.getNewTicketData, {id: personId, panel: panel})
            .filter((resp: Response) => this.filterErrors(resp))
            .map((resp: Response) => this.parseMessages(resp))
            .subscribe(data => {
                this.store.ticketData.set(data);
                this._processReply = false;
            }, error => {
                console.log(error);
                this.growlMessageService.error("Could not create ticket. " + error.message);
                this._processReply = false;
            });
    }

    replayLoadMessages() {
        if (this.replayData) {
            this.loadMessages(this.replayData.panel, this.replayData.ticketId, false);
        }
    }

    isProcessReply() {
        return this._processReply;
    }

    set processReply(processReply: boolean) {
        this._processReply = processReply;
    }

    removeMessage(id: number, ticketId: number, panel: string) {
        this.http.post(Links.removeMessage, {id: id, panel: panel, ticketId: ticketId}).filter((resp: Response) => {
            if (resp["unauthorized"] == "true") {
                this.adminService.unauthorized();
                return false;
            }
            if (resp["error"]) {
                this.growlMessageService.error(resp["error"].message);
                return false;
            }
            if (resp["alert"].message) {
                this.growlMessageService.error(resp["alert"].message);
            }
            return true;
        }).subscribe(data => {
            this.loadMessages(panel, ticketId, false);
        }, error => {
            console.log(error);
            this.growlMessageService.error("Could not remove message. " + error.message);
        });
    }

    pinMessage(messageId: number, ticketId: number, panel: string) {
        this.http.post(Links.pinMessage, {id: messageId, ticketId: ticketId, panel: panel}).filter((resp: Response) => {
            if (resp["unauthorized"] == "true") {
                this.adminService.unauthorized();
                return false;
            }
            if (resp["error"]) {
                this.growlMessageService.error(resp["error"].message);
                return false;
            }
            return true;
        }).subscribe(data => {
            this.loadMessages(panel, ticketId, false);
        }, error => {
            console.log(error);
            this.growlMessageService.error("Could not pin message. " + error.message);
        });
    }

    unpinMessage(messageId: number, ticketId: number, panel: string) {
        this.http.post(Links.unpinMessage, {id: messageId, ticketId: ticketId, panel: panel}).filter((resp: Response) => {
            if (resp["unauthorized"] == "true") {
                this.adminService.unauthorized();
                return false;
            }
            if (resp["error"]) {
                this.growlMessageService.error(resp["error"].message);
                return false;
            }
            return true;
        }).subscribe(data => {
            this.loadMessages(panel, ticketId, false);
        }, error => {
            console.log(error);
            this.growlMessageService.error("Could not unpin message. " + error.message);
        });
    }

    getNotificationSettings(panel: string, username: string, setPhoneAndTime) {
        return this.http.post(Links.getNotificationSettings, {panel: panel, username: username})
            .filter((resp: Response) => this.filterErrors(resp))
            .map((resp: Response) => {
                return {phone: resp['phone'], time: resp['time'], customerEmail: resp['customerEmail'], customer: resp['customer']}
            }).subscribe(setPhoneAndTime, error => {
                console.log(error);
                this.growlMessageService.error("Could not get notification settings. " + error.message);
            });
    }

    translate(messageId: number, message: string) {
        this.http.post(Links.translate, {message: message}).filter((resp: Response) => {
            if (resp["unauthorized"] == "true") {
                this.adminService.unauthorized();
                return false;
            }
            if (resp["error"]) {
                this.growlMessageService.error(resp["error"].message);
                return false;
            }
            return true;
        }).subscribe((data: JSON) => {
            // @ts-ignore
            const translatedMessage = data.translatedMessage;
            if (messageId == null) {
                this.store.ticketData.translateText(translatedMessage);
            } else {
                this.store.ticketData.translateMessage(messageId, translatedMessage);
            }
        }, error => {
            console.log(error);
            this.growlMessageService.error("Could not translate message. " + error.message);
        });
    }
}
