import {Component} from "@angular/core";
import {AsideSupportService} from "./aside-support.service";

@Component({
    selector: 'aside-support',
    templateUrl: './aside-support.component.html'
})
export class AsideSupportComponent {
    private _tabs = [
        {name: 'info', icon: 'icon-info'},
        {name: 'settings', icon: 'icon-settings'}
    ];

    constructor(public asideSupportService: AsideSupportService) {
    }

    get tabs() {
        return this._tabs.filter(tab =>
            this.asideSupportService.showTabs.indexOf(tab.name) >= 0
        );
    }
}
