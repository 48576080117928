import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Links} from "../../../links";
import {GrowlMessageService} from "../../core/services/growl-message.service";
import {ChatMessagesDataservice} from "./chat-messages.dataservice";
import {TicketListDataservice} from "./ticket-list.dataservice";
import {Router} from "@angular/router";
import {NavigationService} from "../services/navigation.service";
import {AdminService} from "../../core/services/admin.service";
import {SearchService} from "../services/search.service";

export interface PanelTicketIds {
    panelPrefix: string;
    ids: number[];
}

export interface ChangeMassStatusAnswer {
    panelPrefix: string;
    changed: boolean;
}

@Injectable()
export class TicketDataservice {

    constructor(private http: HttpClient,
                private chatMessagesDataservice: ChatMessagesDataservice,
                private ticketListDataservice: TicketListDataservice,
                private router: Router,
                private navigationService: NavigationService,
                private growlMessageService: GrowlMessageService,
                private adminService: AdminService,
                private searchService: SearchService) {
    }

    private _processChangeMassStatus: boolean = false;

    assignTo(panel: string, ticketId: number, username: string, ticketVersion: number, followRedirect: boolean = false) {
        this.post(Links.assignTo, {
            panel: panel,
            ticketId: ticketId,
            ticketVersion: ticketVersion,
            username: username
        }, followRedirect);
    }

    changeStatus(panel: string, ticketId: number, status: number, ticketVersion: number) {
        this.post(Links.changeStatus, {
            panel: panel,
            ticketId: ticketId,
            ticketVersion: ticketVersion,
            status: status
        });
    }

    changeMassStatus(ticketsIdsGroupedByPanels: PanelTicketIds[], status: number) {
        if (this._processChangeMassStatus) {
            return;
        }
        this._processChangeMassStatus = true;
        this.http.post(Links.changeMassStatus, {
            ticketsIdsGroupedByPanels: ticketsIdsGroupedByPanels,
            status: status
        }).filter((resp: Response) => {
            if (resp["unauthorized"] == "true") {
                this.adminService.unauthorized();
                return false;
            }
            return true;
        }).map((resp: Response) => {
            if (resp["error"]) {
                this.growlMessageService.error(resp["error"].message);
            }
            if (resp["answers"]) {
                let answers: ChangeMassStatusAnswer[] = resp["answers"];
                answers.forEach(answer => {
                    if (answer.changed) {
                        let successMsg: string = `В ${answer.panelPrefix} статус обновлен`;
                        this.growlMessageService.success("", successMsg);
                    } else {
                        let errorMsg: string = `В ${answer.panelPrefix} не удалось обновить статус`;
                        this.growlMessageService.error(errorMsg);
                    }
                });
            }
            this._processChangeMassStatus = false;
            this.searchService.search(this.searchService.searchModel, false)
        }).subscribe(null, error => {
                console.log(error);
                this._processChangeMassStatus = false;
            }
        );
    }

    isProcessChangeMassStatus() {
        return this._processChangeMassStatus;
    }

    changeDepartment(panel: string, ticketId: number, departmentId: number, ticketVersion: number) {
        this.post(Links.changeDepartment, {
            panel: panel,
            ticketId: ticketId,
            ticketVersion: ticketVersion,
            departmentId: departmentId
        });
    }

    changeTag(panel: string, ticketId: number, tagId: number, ticketVersion: number) {
        this.post(Links.changeTags, {
            panel: panel,
            ticketId: ticketId,
            ticketVersion: ticketVersion,
            tagsIds: tagId != null ? [tagId] : []
        });
    }

    private post(url: string, params, followRedirect: boolean = false) {
        this.http.post(url, params).filter((resp: Response) => {
            if (resp["error"]) {
                this.growlMessageService.error(resp["error"].message);
                return false; //вызов subscribe
            }
            if (resp["alert"] && resp["alert"]["type"] == "error") {
                this.growlMessageService.error(resp["alert"]["message"]);
                return true; //вызов subscribe
            }
            return true;
        }).subscribe(data => {
            this.chatMessagesDataservice.replayLoadMessages();
            this.ticketListDataservice.replayLoadTicketList();

            if (!(data["alert"] && data["alert"]["type"] == "error")) {
                //todo сделать вывод сообщений из панели
                this.growlMessageService.success("Сделано", "Операция выполнена")
            }
            if (followRedirect && data["data"] && data["data"]["redirectTo"]) {
                this.navigationService.goTo(data["data"]["redirectTo"]);
            }
            if (followRedirect && data["data"] && data["data"]["openWindow"]) {
                this.navigationService.openWindow(data["data"]["openWindow"]);
            }
        }, error => {
            let data = error.error;
            if (data.error.message) {
                this.growlMessageService.error(data.error.message);
            } else {
                this.growlMessageService.error("Ошибка на сервере");
            }
        });
    }

}
