<div class="reply-controls">
  <span>
  <p-tieredMenu-relative #templateMenu [model]="templateMenuItems" [popup]="true"
                         [containerParent]="parent" [sharedCommand]="templateMenuItemClick"></p-tieredMenu-relative>
  <button #btn type="button" class="ui-button-secondary" pButton icon="fa fa-magic" label="Шаблоны"
          [disabled]="!isSelectedPanel()"
          (click)="templateMenu.toggle($event)"></button>
</span>

  <span>
  <p-tieredMenu-relative #downloadMenu [model]="downloadMenuItems" [popup]="true"
                         [containerParent]="parent"></p-tieredMenu-relative>
  <button #btn [disabled]="true" type="button" class="ui-button-secondary" pButton icon="fa fa-download" label="Загрузки"
          (click)="downloadMenu.toggle($event)"></button>  
</span>

  <span>
  <p-tieredMenu-relative #insertMenu [model]="insertMenuItems" [popup]="true"
                         [containerParent]="parent"></p-tieredMenu-relative>
  <button #btn [disabled]="true" type="button" class="ui-button-secondary" pButton icon="fa fa-inbox" label="Вставить"
          (click)="insertMenu.toggle($event)"></button>  
</span>

  <span class="float-md-right" *ngIf="canAddNote">
  <a class="pseudo-link" (click)="addNote()">Заметка</a>  
</span>

</div>
