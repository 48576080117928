import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Links} from "../../../links";
import {Ticket} from "../domain/ticket.domain";
import {AdminService} from "../../core/services/admin.service";
import {GrowlMessageService} from "../../core/services/growl-message.service";
import {TicketBuilder} from "../domain/builder/ticket.builder";
import {Store} from "../store/store";

export interface LoadTicketList {
    searchMap: any,
    erase: boolean
}

@Injectable()
export class TicketListDataservice {

    private uidCounter: number = 1;
    private inProgress: Array<string> = [];
    private replaySearchMap: any;

    loadTicketListSubject: Subject<LoadTicketList>;

    constructor(private http: HttpClient,
                private adminService: AdminService,
                private store: Store,
                private growlMessageService: GrowlMessageService,
                private ticketBuilder: TicketBuilder) {
        this.loadTicketListSubject = new Subject();
        this.loadTicketListSubject.asObservable().subscribe((p: LoadTicketList) => {
            this.loadTicketList(p.searchMap, p.erase);
        });
    }

    addProgress(name: string): string {
        let uid: string = name + this.uidCounter++;
        this.inProgress.push(uid);
        return uid;
    }

    endProgress(uid: string): boolean {
        let index = this.inProgress.indexOf(uid);
        if (index > -1) {
            this.inProgress.splice(0, index + 1);
            return this.inProgress.length == 0;
        } else {
            return false
        }
    }

    loadTicketList(searchMap: any, erase: boolean) {
        this.replaySearchMap = searchMap;
        if (erase) {
            this.store.tickets.set({tickets: [], loading: true});
        }
        let uid = this.addProgress("loadTicketList");
        try {
            return this.http.post(Links.ticketSearch, searchMap).filter((resp: Response) => {
                if (resp["unauthorized"] == "true") {
                    this.adminService.unauthorized();
                    return false;
                }
                if (resp["error"]) {
                    this.growlMessageService.error(resp["error"].message);
                    return false;
                }
                return true;
            }).map((resp: Response) => {
                let $tickets: Ticket[] = [];
                for (let ticketData of resp["list"]) {
                    let $ticket = this.ticketBuilder.build(ticketData.ticket);
                    $tickets.push($ticket);
                }
                if (resp["cannotLoadTicketsFrom"]) {
                    const cannotLoadTicketsFrom: string[] = resp["cannotLoadTicketsFrom"];
                    if (cannotLoadTicketsFrom.length > 0) {
                        this.growlMessageService.error("Can not load tickets from: " + cannotLoadTicketsFrom);
                    }
                }
                return {tickets: $tickets, count: resp["count"], loading: false, counters: resp["counters"]};
            }).subscribe(data => {
                if (this.endProgress(uid)) { // если запрашивается что-то еще, значит этот результат не нужен
                    this.store.tickets.set(data);
                }
            }, error => {
                console.log(error);
                this.endProgress(uid);
                this.growlMessageService.error("Could not load tickets list. " + error.message);
            });
        } catch (e) {
            this.endProgress(uid);
        }
    }

    replayLoadTicketList() {
        if (this.replaySearchMap) {
            this.loadTicketList(this.replaySearchMap, false);
        }
    }
}
