import {Injectable} from "@angular/core";
import {action, computed, observable} from "mobx-angular";
import {Department} from "../domain/department.domain";

export class Departments {
    list: Array<Department> = [];

    constructor(items?: Department[]) {
        items && items.forEach(item => this.list.push(item));
    }

    getById(id: number): Department {
        return this.list.find((e) => e.id == id);
    }
}

@Injectable()
export class DepartmentsStore {

    static dep1 = new Department(1, "Поддержка", true);
    static dep2 = new Department(2, "Администраторы", true);
    static dep3 = new Department(3, "Отдел продаж", true);
    static dep4 = new Department(4, "Бухгалтерия", true);
    static dep5 = new Department(5, "Рег-я доменов", true);
    static dep6 = new Department(6, "Abuse", true);
    static dep7 = new Department(7, "Юр. отдел", true);

    static dep8 = new Department(8, "System", false);
    static dep9 = new Department(9, "Relevate", false);
    static dep10 = new Department(10, "Spamassassin", false);
    static dep11 = new Department(11, "Возвраты", false);
    static dep12 = new Department(12, "Руководство", true);
    static dep13 = new Department(13, "Complaints", false);
    static dep14 = new Department(14, "Работа", false);
    static dep15 = new Department(15, "MAILER-DAEMON", false);
    static dep16 = new Department(16, "Copy", false);
    static dep17 = new Department(17, "Служебный", false);
    static dep18 = new Department(18, "Общие вопросы", false);
    static dep19 = new Department(19, "Other", false);

    @observable
    value: Departments = new Departments();

    constructor() {
        this.set([
            DepartmentsStore.dep1,
            DepartmentsStore.dep2,
            DepartmentsStore.dep3,
            DepartmentsStore.dep4,
            DepartmentsStore.dep5,
            DepartmentsStore.dep6,
            DepartmentsStore.dep7,
            DepartmentsStore.dep8,
            DepartmentsStore.dep9,
            DepartmentsStore.dep10,
            DepartmentsStore.dep11,
            DepartmentsStore.dep12,
            DepartmentsStore.dep13,
            DepartmentsStore.dep14,
            DepartmentsStore.dep15,
            DepartmentsStore.dep16,
            DepartmentsStore.dep17,
            DepartmentsStore.dep18,
            DepartmentsStore.dep19,
        ].sort(function (a, b) {
            let x = a.id;
            let y = b.id;
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }))
    }

    @action
    set(departments: Department[]) {
        this.value = new Departments(departments);
    }

    @action
    clear() {
        this.value = new Departments();
    }

    @computed
    get active() {
        return this.value.list.filter((d) => d.active);
    }

    @computed
    get all() {
        return this.value.list;
    }

    getById(id: number): Department {
        return this.value.getById(id);
    }
}
