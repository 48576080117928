import {Component, ElementRef, Renderer2} from '@angular/core';
import "jquery";
import {FileDropDirective} from "../../../../shared/file-drop.directive";
import {Subject} from "rxjs";

declare var $: any;

@Component({
    selector: 'chat-attachments',
    templateUrl: './chat-attachments.component.html'
})
export class ChatAttachmentsComponent {

    private ngDestroyed: Subject<void> = new Subject<void>();

    constructor(private renderer: Renderer2,
                private elementRef: ElementRef) {
    }

    ngOnInit() {
        FileDropDirective.onDropFileList.asObservable().takeUntil(this.ngDestroyed).subscribe(fileList => {
            let filesBlocks = $('.attachments div');
            let $inputs = $(filesBlocks).find("input");
            if ($inputs.length == 1 && (!$inputs[0].files || $inputs[0].files.length == 0)) {
                $inputs[0].files = fileList;
            } else {
                let fileBlock = this.addAttachment();
                $(fileBlock).find("input")[0].files = fileList;
            }
        });
    }

    ngOnDestroy(): void {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    }

    addAttachment() {
        let attachmentsBlock = this.elementRef.nativeElement.querySelector('.attachments');
        let fileBlock = attachmentsBlock.children[0].cloneNode(true);
        this.renderer.setProperty(fileBlock.children[0], 'value', null);
        this.renderer.listen(fileBlock.children[1], 'click', (event) => {
            this.removeAttachment(event)
        });
        this.renderer.appendChild(attachmentsBlock, fileBlock);
        return fileBlock;
    }

    removeAttachment(event: Event) {
        let filesBlocks = $('.attachments div');
        if (filesBlocks.length == 1) {
            filesBlocks.find('input').prop('value', null);
            return;
        }
        $(event.target).parent().remove();
    }

}
