import {Component, OnDestroy, OnInit} from "@angular/core";
import {LoginDataservice, UserInfo} from "./core/data/login.dataservice";
import {Subject} from "rxjs";
import {SystemInfoDataservice} from "./core/data/system-info.dataservice";

@Component({
    selector: 'body',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    private userInfo: UserInfo;
    private ngDestroyed: Subject<void> = new Subject<void>();

    constructor(private loginDataservice: LoginDataservice,
                private systemInfoDataservice: SystemInfoDataservice) {
    }

    ngOnInit() {
        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(data => this.userInfo = data);
        this.loginDataservice.loadUserInfo();
        this.systemInfoDataservice.loadSystemInfo();
    }

    ngOnDestroy(): void {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    }

}
