import {Directive, ElementRef, Input, OnChanges, OnInit} from "@angular/core";
import {TooltipComponent} from "./tooltip.component";


declare var $: any;

@Directive({
    selector: '[tooltip]'
})
export class TooltipDirective implements OnInit, OnChanges {

    /************************************************************* BINDINGS *************************************************************/

    @Input()
    public tooltip: string | TooltipComponent;

    @Input()
    public tooltipPosition: string = "top";

    @Input()
    public tooltipMinWidth: number = 0;

    @Input()
    public tooltipMaxWidth: number = null;

    @Input()
    public tooltipInteractive: boolean = false;

    @Input()
    public tooltipDelay: number = 300;

    @Input()
    public tooltipWhite: boolean = false;

    @Input()
    public tooltipGroup: string = "tooltip-grouped";

    /************************************************************* LIFE CYCLE *************************************************************/

    constructor(private _elementRef: ElementRef) {
    }

    public ngOnInit(): void {
        if (this.tooltip instanceof TooltipComponent) {
            this._setTooltip($((this.tooltip as TooltipComponent).element).find('div:first'));
        }
    }

    public ngOnChanges(): void {
        if (!(this.tooltip instanceof TooltipComponent)) {
            this._setTooltip(this.tooltip as string);
        }
    }

    /************************************************************* PRIVATE *************************************************************/

    private _setTooltip(content: string): void {
        $(this._elementRef.nativeElement).tooltipster(this._getTooltipOptions(content));
        $.tooltipster.group(this.tooltipGroup);
    }

    /**
     *
     * @param content
     */
    private _getTooltipOptions(content: string | object): any {
        let options: any = {
            content: content,
            position: this.tooltipPosition,
            minWidth: this.tooltipMinWidth,
            maxWidth: this.tooltipMaxWidth,
            delay: this.tooltipDelay,
            theme: this.tooltipWhite ? "tooltipster-light" : "tooltipster-borderless",
            interactive: this.tooltipInteractive,
            repositionOnScroll: true,
            animationDuration: [100, 100]
        };

        if (typeof content === 'object') {
            options.contentCloning = false;
        }
        else {
            options.multiple = true;
            options.contentAsHTML = true;
        }

        return options;
    }
}
