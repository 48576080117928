import {Injectable} from "@angular/core";
import {Ticket} from "../ticket.domain";
import {Store} from "../../store/store";
import {Tag} from "../tag.domain";
import {DateUtil} from "../../../date-util";
import {AdminBuilder} from "./admin.builder";
import {TicketInfoBuilder} from "./ticket-info.builder";
import {MessageBuilder} from "./message.builder";

@Injectable()
export class TicketBuilder {

    constructor(private store: Store,
                private adminBuilder: AdminBuilder,
                private messageBuilder: MessageBuilder,
                private ticketInfoBuilder: TicketInfoBuilder) {
    }

    build(dataFromServer): Ticket {
        let $ticket = new Ticket();
        $ticket.id = dataFromServer["id"];
        $ticket.version = dataFromServer["version"];
        $ticket.mask = dataFromServer["mask"];
        $ticket.username = dataFromServer["username"];
        $ticket.adminAnswersNumber = dataFromServer["adminAnswersNumber"];
        $ticket.panelPrefix = dataFromServer["panelPrefix"];
        $ticket.subject = dataFromServer["subject"];
        $ticket.department = this.store.departments.getById(+dataFromServer["departmentId"]);
        $ticket.status = this.store.statuses.getById(+dataFromServer["status"]);
        $ticket.date = DateUtil.parse(dataFromServer["created"]);
        $ticket.activity = DateUtil.parse(dataFromServer["lastActivity"]);
        $ticket.activityDiff = dataFromServer["lastActivityDiff"];
        $ticket.messagesCount = dataFromServer["messagesCount"];
        $ticket.unreadMessages = 0;
        $ticket.url = dataFromServer["url"];

        $ticket.tag = dataFromServer["tags"] ? new Tag(dataFromServer["tags"][0]) : null;
        $ticket.assignedTo = dataFromServer["assignedTo"] ? this.adminBuilder.build(dataFromServer["assignedTo"]) : null;
        $ticket.draftText = dataFromServer["draftText"];
        $ticket.unread = dataFromServer["unread"];

        let additionalData = dataFromServer["customer"];
        if (additionalData) {
            $ticket.customerNote = additionalData["note"];
            additionalData["ticketId"] = $ticket.id;
            $ticket.info = this.ticketInfoBuilder.build(additionalData)
        }

        let op = dataFromServer["op"];
        if (op) {
            $ticket.opNote = dataFromServer["op"]["note"];
        }

        let lastMessage = dataFromServer["lastMessage"];
        if (lastMessage) {
            $ticket.lastMessage = this.messageBuilder.build(lastMessage, $ticket.panelPrefix);
        }

        return $ticket;
    }

}
