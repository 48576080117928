var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, forwardRef } from "@angular/core";
import { Dropdown } from "primeng/primeng";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
export var DROPDOWN_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return DropdownEditorComponent; }),
    multi: true
};
var DropdownEditorComponent = /** @class */ (function (_super) {
    __extends(DropdownEditorComponent, _super);
    function DropdownEditorComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onHide = new EventEmitter();
        return _this;
    }
    DropdownEditorComponent.prototype.hide = function () {
        var _this = this;
        _super.prototype.hide.call(this);
        this.onHide.emit({});
        setTimeout(function () {
            _super.prototype.unbindDocumentClickListener.call(_this);
            _this.panelVisible = true;
        }, 1);
    };
    DropdownEditorComponent.prototype.unbindDocumentClickListener = function () {
        _super.prototype.unbindDocumentClickListener.call(this);
        this.hide();
    };
    DropdownEditorComponent.prototype.onKeydown = function (event) {
        switch (event.which) {
            case 32: //space
            case 13: //enter
            case 27: //escape
            case 9: //tab
                this.hide();
        }
    };
    return DropdownEditorComponent;
}(Dropdown));
export { DropdownEditorComponent };
