import {InputTextarea} from "primeng/components/inputtextarea/inputtextarea";
import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from "@angular/core";

@Directive({
    selector: '[pInputTextarea2]',
    host: {
        '[class.ui-inputtext]': 'true',
        '[class.ui-corner-all]': 'true',
        '[class.ui-state-default]': 'true',
        '[class.ui-widget]': 'true',
        '[class.ui-state-filled]': 'filled'
    }
})
export class InputTextarea2Directive extends InputTextarea {

    hDefault: number;

    @Input() maxHeight: number;

    @Output() onResize: EventEmitter<any> = new EventEmitter();

    constructor(public el: ElementRef) {
        super(el, null);
    }

    ngOnInit() {
        this.hDefault = this.el.nativeElement.offsetHeight;
    }

    @HostListener('keydown', ['$event'])
    onKeydown(e) {
        if (this.autoResize) {
            this.resize(e);
        }
    }

    resize(event?: Event) {
        let ta = this.el.nativeElement;
        ta.style.height = "" + this.hDefault + "px";
        if (ta.scrollHeight != ta.offsetHeight) {
            if (ta.scrollHeight < this.maxHeight) {
                let fontSize = jQuery(ta).css('font-size');
                let lineHeight = Math.floor(parseInt(fontSize.replace('px', '')) * 1.5);
                ta.style.height = "" + (ta.scrollHeight + lineHeight) + "px";
            } else {
                ta.style.height = "" + this.maxHeight + "px";
            }
            this.onResize.emit(event || {});
        }
    }
}
