import {Injectable} from "@angular/core";
import {Links} from "../../../links";

declare var $: any;

export interface ErrorLog {
    message: string;
    url: string;
    stack: string;
}

@Injectable()
export class ServerLoggingService {

    constructor() {
    }

    public log(error: ErrorLog): void {
        $.post(Links.serverLogError, error);
    }

}
