import {AppSettings} from "app-settings";
import {LinkUtil} from "../../link-util";

export class Message {
    id: number;
    active: boolean;
    evaluation: number;
    evaluationComment: string;
    pinned: boolean;
    ticketId: number;
    panelPrefix: string;
    author: string;
    date: Date;
    text: string;
    textTranslated: string;
    type: string; // customer, admin, note, log
    ticketInfo: string;
    attachments: Array<object> = [];
    translated: boolean;

    get authorType() {
        return this.type;
    }

    get isCustomerMessage(): boolean {
        return ['customer'].indexOf(this.authorType) >= 0
    }

    get isAdminMessage(): boolean {
        return ['admin'].indexOf(this.authorType) >= 0
    }

    get isNote(): boolean {
        return ['note'].indexOf(this.authorType) >= 0
    }

    get isLog(): boolean {
        return ['log'].indexOf(this.authorType) >= 0
    }

    get isTranslated(): boolean {
        return this.translated
    }

    get editableAndDeletable(): boolean {
        return (this.authorType != 'log') &&
            this.date.getTime() + AppSettings.TIME_FOR_EDIT_MESSAGE > new Date().getTime()
    }

    setAttachments(atts: Array<object>, panel: string) {
        if (atts) {
            atts.forEach(item => {
                var fileName = LinkUtil.escape(item['fileName']);
                item['url'] = "/ticket/attachment?attachmentId=" + item['id'] + "&name=" + fileName + "&panel=" + panel
            });

            this.attachments = atts
        }
    }
}
