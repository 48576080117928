import {Component, ElementRef, OnInit} from "@angular/core";

@Component({
    selector: "tooltip",
    template: "<ng-content></ng-content>"
})
export class TooltipComponent implements OnInit {

    /************************************************************* LIFE CYCLE *************************************************************/

    constructor(private _host: ElementRef) {
    }

    public ngOnInit(): void {
        this._host.nativeElement.style.display = "none";
    }

    /************************************************************* ACCESSOR *************************************************************/

    public get element(): HTMLElement {
        return this._host.nativeElement;
    }
}

