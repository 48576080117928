/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./chat-info.component.ngfactory";
import * as i2 from "./chat-info.component";
import * as i3 from "../../../services/ticket.service";
import * as i4 from "../../../data/ticket.dataservice";
import * as i5 from "@angular/router";
import * as i6 from "../../../../core/services/admin.service";
import * as i7 from "../../../services/chat-info.show-logs.events";
import * as i8 from "../../../services/chat-info.show-only-notes.events";
import * as i9 from "../../../../core/data/login.dataservice";
import * as i10 from "../../../services/navigation.service";
import * as i11 from "../reply/chat-textarea.eventbus";
import * as i12 from "../../../data/chat-messages.dataservice";
import * as i13 from "../../../store/store";
import * as i14 from "./chat-settings.component.ngfactory";
import * as i15 from "./chat-settings.component";
import * as i16 from "ngx-bootstrap/tabs/tab.directive";
import * as i17 from "ngx-bootstrap/tabs/tabset.component";
import * as i18 from "ngx-bootstrap/tabs/tab-heading.directive";
import * as i19 from "@angular/common";
import * as i20 from "../../../../../../node_modules/ngx-bootstrap/tabs/tabset.component.ngfactory";
import * as i21 from "ngx-bootstrap/tabs/tabset.config";
import * as i22 from "./aside-support.component";
import * as i23 from "./aside-support.service";
var styles_AsideSupportComponent = [];
var RenderType_AsideSupportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AsideSupportComponent, data: {} });
export { RenderType_AsideSupportComponent as RenderType_AsideSupportComponent };
function View_AsideSupportComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AsideSupportComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "chat-info", [], null, null, null, i1.View_ChatInfoComponent_0, i1.RenderType_ChatInfoComponent)), i0.ɵdid(2, 245760, null, 0, i2.ChatInfoComponent, [i3.TicketService, i4.TicketDataservice, i5.ActivatedRoute, i5.Router, i6.AdminService, i7.ChatInfoShowLogsEvents, i8.ChatInfoShowOnlyNotesEvents, i9.LoginDataservice, i10.NavigationService, i11.ChatTextareaEventbus, i12.ChatMessagesDataservice, i13.Store], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AsideSupportComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "chat-settings", [], null, null, null, i14.View_ChatSettingsComponent_0, i14.RenderType_ChatSettingsComponent)), i0.ɵdid(2, 49152, null, 0, i15.ChatSettingsComponent, [], null, null)], null, null); }
function View_AsideSupportComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "tab", [], [[1, "id", 0], [2, "active", null], [2, "tab-pane", null]], null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i16.TabDirective, [i17.TabsetComponent, i0.ElementRef, i0.Renderer2], { removable: [0, "removable"] }, null), (_l()(), i0.ɵand(0, null, null, 1, null, View_AsideSupportComponent_2)), i0.ɵdid(3, 16384, null, 0, i18.TabHeadingDirective, [i0.TemplateRef, i16.TabDirective], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "p-1"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AsideSupportComponent_3)), i0.ɵdid(6, 16384, null, 0, i19.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AsideSupportComponent_4)), i0.ɵdid(8, 16384, null, 0, i19.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = true; _ck(_v, 1, 0, currVal_3); var currVal_4 = (_v.context.$implicit.name == "info"); _ck(_v, 6, 0, currVal_4); var currVal_5 = (_v.context.$implicit.name == "settings"); _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).id; var currVal_1 = i0.ɵnov(_v, 1).active; var currVal_2 = i0.ɵnov(_v, 1).addClass; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_AsideSupportComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "tabset", [], [[2, "tab-container", null]], null, null, i20.View_TabsetComponent_0, i20.RenderType_TabsetComponent)), i0.ɵdid(1, 180224, null, 0, i17.TabsetComponent, [i21.TabsetConfig, i0.Renderer2], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AsideSupportComponent_1)), i0.ɵdid(3, 278528, null, 0, i19.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tabs; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).clazz; _ck(_v, 0, 0, currVal_0); }); }
export function View_AsideSupportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aside-support", [], null, null, null, View_AsideSupportComponent_0, RenderType_AsideSupportComponent)), i0.ɵdid(1, 49152, null, 0, i22.AsideSupportComponent, [i23.AsideSupportService], null, null)], null, null); }
var AsideSupportComponentNgFactory = i0.ɵccf("aside-support", i22.AsideSupportComponent, View_AsideSupportComponent_Host_0, {}, {}, []);
export { AsideSupportComponentNgFactory as AsideSupportComponentNgFactory };
