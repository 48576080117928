export class Status {
    id: number;
    name: string;
    selected: boolean;
    color: string;
    action: string;

    constructor(id: number, name: string, selected: boolean, action: string, color: string) {
        this.id = id;
        this.name = name;
        this.selected = selected;
        this.action = action;
        this.color = color;
    }

    get label(): string {
        return this.name;
    }

    get value(): Status {
        return this;
    }
}
