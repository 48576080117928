import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LaddaModule} from "angular2-ladda/module/module";
import {NAV_DROPDOWN_DIRECTIVES} from "./shared/nav-dropdown.directive";
import {BreadcrumbsComponent} from "./shared/breadcrumb.component";
import {SIDEBAR_TOGGLE_DIRECTIVES} from "./shared/sidebar.directive";
import {AsideToggleDirective} from "./shared/aside.directive";
import {RouterModule} from "@angular/router";
import {MobileSidebarsCleanerService} from "./mobile-sidebars-cleaner.service";
import {LayoutComponent} from "./layout.component";
import {SharedModule} from "../shared/shared.module";

/* Routing Module */

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        LaddaModule,
        SharedModule,
    ],
    declarations: [
        LayoutComponent,
        NAV_DROPDOWN_DIRECTIVES,
        BreadcrumbsComponent,
        SIDEBAR_TOGGLE_DIRECTIVES,
        AsideToggleDirective,
    ],
    providers: [
        MobileSidebarsCleanerService
    ],
    exports: [
        CommonModule,
        RouterModule,
        LaddaModule,
        LayoutComponent,
        NAV_DROPDOWN_DIRECTIVES,
        BreadcrumbsComponent,
        SIDEBAR_TOGGLE_DIRECTIVES,
        AsideToggleDirective,
    ]
})
export class LayoutModule {
}
