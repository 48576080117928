import "jquery";
import { EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Message } from "../../domain/message.domain";
import { NavigationService } from "../../services/navigation.service";
import { Subject } from "rxjs/Subject";
import { ChatTextareaEventbus } from "./reply/chat-textarea.eventbus";
import { Store } from "../../store/store";
import { ChatMessagesDataservice } from "../../data/chat-messages.dataservice";
import { ChatInfoShowOnlyNotesEvents } from "../../services/chat-info.show-only-notes.events";
var MessageComponent = /** @class */ (function () {
    function MessageComponent(activatedRoute, chatMessagesDataservice, chatTextareaEventbus, navigationService, chatInfoShowOnlyNotesEvents, _store) {
        this.activatedRoute = activatedRoute;
        this.chatMessagesDataservice = chatMessagesDataservice;
        this.chatTextareaEventbus = chatTextareaEventbus;
        this.navigationService = navigationService;
        this.chatInfoShowOnlyNotesEvents = chatInfoShowOnlyNotesEvents;
        this._store = _store;
        this.noRemove = false;
        this.onOpenRemoveMsgDialog = new EventEmitter();
        this.ngDestroyed = new Subject();
    }
    Object.defineProperty(MessageComponent.prototype, "store", {
        get: function () {
            return this._store;
        },
        enumerable: true,
        configurable: true
    });
    MessageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.chatTextareaEventbus.chatTextarea.takeUntil(this.ngDestroyed).subscribe(function (data) {
            if (data != null) {
                _this.chatTextareaDirective = data;
            }
        });
        this.chatInfoShowOnlyNotesEvents.subscribe(this.ngDestroyed, function (data) { return _this._showOnlyNotes = data; });
    };
    MessageComponent.prototype.ngOnDestroy = function () {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    };
    MessageComponent.prototype.openRemoveMsgDialog = function (id) {
        this.onOpenRemoveMsgDialog.emit(id);
    };
    Object.defineProperty(MessageComponent.prototype, "panelPrefix", {
        get: function () {
            return this.store.ticketData.ticket.panelPrefix;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageComponent.prototype, "showAnchor", {
        get: function () {
            return this.activatedRoute.snapshot.params.anchor;
        },
        enumerable: true,
        configurable: true
    });
    MessageComponent.prototype.makeUrl = function (anchor) {
        return this.navigationService.makeChatUrl(this.ticketMessage.panelPrefix, this.ticketMessage.ticketId, anchor);
    };
    MessageComponent.prototype.decodeText = function (html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    };
    MessageComponent.prototype.pin = function (id) {
        this.chatMessagesDataservice.pinMessage(id, this.ticketMessage.ticketId, this.panelPrefix);
    };
    MessageComponent.prototype.unpin = function (id) {
        this.chatMessagesDataservice.unpinMessage(id, this.ticketMessage.ticketId, this.panelPrefix);
    };
    MessageComponent.prototype.scrollToPin = function (id) {
        if (!this.isOnlyNotesAndActive)
            return;
        this.chatInfoShowOnlyNotesEvents.next(false);
        setTimeout(function () {
            var pos = $("#anchor" + id).position();
            $('html, body').animate({ scrollTop: pos.top }, 400);
        }, 100);
    };
    Object.defineProperty(MessageComponent.prototype, "isOnlyNotesAndActive", {
        get: function () {
            return this._showOnlyNotes && this.ticketMessage.active;
        },
        enumerable: true,
        configurable: true
    });
    MessageComponent.prototype.translateMessage = function (messageId, message) {
        this.chatMessagesDataservice.translate(messageId, message);
    };
    return MessageComponent;
}());
export { MessageComponent };
