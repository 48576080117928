import {NgModule} from "@angular/core";
import {AdminService} from "./services/admin.service";
import {SharedModule} from "../shared/shared.module";
import {GrowlMessageService} from "./services/growl-message.service";
import {LoginDataservice} from "./data/login.dataservice";
import {SystemInfoDataservice} from "./data/system-info.dataservice";
import {SystemInfoStore} from "app/core/store/system-info.store";

/* Модуль для общих сервисов-синглтонов (Cookbook/NgModule/CoreModule) */

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [],
    providers: [
        AdminService,
        SystemInfoStore,
        SystemInfoDataservice,
        LoginDataservice,
        GrowlMessageService,
    ]
})
export class CoreModule {
}
