<div class="ui-g ui-fluid right">
  <div class="ui-g-12 ui-md-6 ui-lg-3">
    <div class="form-group-custom">
      <label>Номер тикета</label>
      <input type="text" class="form-control" [(ngModel)]="ticketMask" (keyup)="filter($event)" (keyup.enter)="filter($event, true)">
    </div>
  </div>

  <div class="ui-g-12 ui-md-6 ui-lg-3">
    <div class="form-group-custom">
      <label>Клиент</label>
      <input type="text" class="form-control" [(ngModel)]="username" (keyup)="filter($event)" (keyup.enter)="filter($event, true)">
    </div>
  </div>

  <div class="ui-g-12 ui-md-6 ui-lg-3">
    <div class="form-group-custom">
      <label>Дата от</label>
      <p-calendar dateFormat="dd.mm.yy" [(ngModel)]="dateFrom" [locale]="calendar" (onSelect)="filter($event)" (keyup.enter)="filter($event, true)"></p-calendar>
    </div>
  </div>

  <div class="ui-g-12 ui-md-6 ui-lg-3">
    <div class="form-group-custom">
      <label>Дата до</label>
      <p-calendar dateFormat="dd.mm.yy" [(ngModel)]="dateTo" [locale]="calendar" (onSelect)="filter($event)" (keyup.enter)="filter($event, true)"></p-calendar>
    </div>
  </div>

  <div class="ui-g-12 ui-md-6 ui-lg-6">
    <div class="form-group-custom">
      <label>Ключевые слова</label>
      <input type="text" class="form-control" [(ngModel)]="keywords" (keyup)="filter($event)" (keyup.enter)="filter($event, true)">
    </div>
  </div>

  <div class="ui-g-12 ui-md-6 ui-lg-3">
    <div class="form-group-custom">
      <label>Панель</label>
      <p-multiSelect [options]="panelList" [defaultLabel]="'Выберите панель'" [(ngModel)]="panel" (onChange)="filter($event)" (keyup.enter)="filter($event, true)"></p-multiSelect>
    </div>
  </div>

</div>

<div class="pull-right">
  <button type="button" class="btn btn-primary" style="float: right;" (click)="filter($event, true)">
    Применить
  </button>

</div>

