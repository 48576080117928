import "jquery";
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Message} from "../../domain/message.domain";
import {NavigationService} from "../../services/navigation.service";
import {Subject} from "rxjs/Subject";
import {ChatTextareaEventbus} from "./reply/chat-textarea.eventbus";
import {ChatTextareaDirective} from "./reply/chat-textarea.directive";
import {Store} from "../../store/store";
import {ChatMessagesDataservice} from "../../data/chat-messages.dataservice";
import {ChatInfoShowOnlyNotesEvents} from "../../services/chat-info.show-only-notes.events";

declare let $: any;

@Component({
    selector: 'message',
    templateUrl: './message.component.html'
})
export class MessageComponent implements OnInit, OnDestroy {

    @Input() ticketMessage: Message;
    @Input() noRemove: boolean = false;
    @Output() onOpenRemoveMsgDialog = new EventEmitter<number>();

    private chatTextareaDirective: ChatTextareaDirective;
    private ngDestroyed: Subject<void> = new Subject<void>();
    private _showOnlyNotes: boolean;

    constructor(private activatedRoute: ActivatedRoute,
                private chatMessagesDataservice: ChatMessagesDataservice,
                private chatTextareaEventbus: ChatTextareaEventbus,
                private navigationService: NavigationService,
                private chatInfoShowOnlyNotesEvents: ChatInfoShowOnlyNotesEvents,
                private _store: Store) {
    }

    get store() {
        return this._store;
    }

    ngOnInit() {
        this.chatTextareaEventbus.chatTextarea.takeUntil(this.ngDestroyed).subscribe(data => {
            if (data != null) {
                this.chatTextareaDirective = data;
            }
        });
        this.chatInfoShowOnlyNotesEvents.subscribe(this.ngDestroyed, (data) => this._showOnlyNotes = data);
    }

    ngOnDestroy(): void {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    }

    openRemoveMsgDialog(id: number) {
        this.onOpenRemoveMsgDialog.emit(id);
    }

    get panelPrefix(): string {
        return this.store.ticketData.ticket.panelPrefix;
    }

    get showAnchor() {
        return this.activatedRoute.snapshot.params.anchor;
    }

    makeUrl(anchor: string): string {
        return this.navigationService.makeChatUrl(this.ticketMessage.panelPrefix, this.ticketMessage.ticketId, anchor);
    }

    private decodeText(html) { //todo можно вынести в отдельный сервис
        let txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    pin(id: number) {
        this.chatMessagesDataservice.pinMessage(id, this.ticketMessage.ticketId, this.panelPrefix);
    }

    unpin(id: number) {
        this.chatMessagesDataservice.unpinMessage(id, this.ticketMessage.ticketId, this.panelPrefix);
    }

    scrollToPin(id: number) {
        if (!this.isOnlyNotesAndActive) return;

        this.chatInfoShowOnlyNotesEvents.next(false);
        setTimeout(() => {
            let pos = $("#anchor" + id).position();
            $('html, body').animate({scrollTop: pos.top}, 400);
        }, 100);
    }

    get isOnlyNotesAndActive() {
        return this._showOnlyNotes && this.ticketMessage.active;
    }

    translateMessage(messageId: number, message: string) {
        this.chatMessagesDataservice.translate(messageId, message);
    }
}
