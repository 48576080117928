import {Directive, ElementRef, HostListener, Renderer2} from "@angular/core";
import {Subject} from "rxjs/Rx";

@Directive({
    selector: '[file-drop]'
})
export class FileDropDirective {

    public static onDropFileList: Subject<FileList> = new Subject<FileList>();

    private active = false;

    constructor(private renderer: Renderer2, private el: ElementRef) {
    }

    @HostListener('dragover', ['$event'])
    public onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (!this.active) {
            this.renderer.addClass(this.el.nativeElement, 'file-drop-target');
            this.active = true;
        }
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (this.active) {
            this.renderer.removeClass(this.el.nativeElement, 'file-drop-target');
            this.active = false;
        }
    }

    @HostListener('drop', ['$event'])
    public onDrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        if (this.active) {
            this.renderer.removeClass(this.el.nativeElement, 'file-drop-target');
            this.active = false;
        }

        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            FileDropDirective.onDropFileList.next(files);
        }
    }

}
