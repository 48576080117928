<div class="message-data">

  <span>{{ticket.username}}</span>&nbsp;
  <span class="badge badge-{{ticket.panelPrefix}}">{{ticket.panelPrefix}}</span>
  
  <a class="message-data-time" href="{{makeUrl(ticket.lastMessage.id.toString())}}" id="anchor{{ticket.lastMessage.id}}">
    {{ticket.lastMessage.date | amDateFormat:'DD.MM.YYYY HH:mm:ss'}}
  </a>

  <a class="font-weight-bold" style="color: black;"
     routerLinkActive="active" [routerLink]="['/support/chat', ticket.panelPrefix, ticket.id]">
    {{ticket.mask}}
  </a>
  &mdash; {{ticket.subject}}
  
</div>



<div class="message {{ticket.lastMessage.authorType}}-message" 
     [class.float-right]="['admin', 'note', 'log'].indexOf(ticket.lastMessage.authorType) >=0">
  
  <div innerHtml="{{ticket.lastMessage.text}}" class="message__text">
    {{ticket.lastMessage.text}}
  </div>
  
</div>
