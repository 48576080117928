import {Injectable} from "@angular/core";
import {AdminService} from "../../core/services/admin.service";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {LoginDataservice, UserInfo} from "../../core/data/login.dataservice";
import {Store} from "../store/store";

@Injectable()
export class TicketService {
    private _userInfo: UserInfo;

    private ngDestroyed: Subject<void> = new Subject<void>();

    constructor(private router: Router,
                private loginDataservice: LoginDataservice,
                private adminService: AdminService,
                private store: Store) {

        this.loginDataservice.userInfo.subscribe(data => this._userInfo = data);
    }

    openCreateTicket() {
        this.router.navigate(['/support/chat/' + this._userInfo.panels[0] + '/new']);
    }
}
