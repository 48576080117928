<!--админ / заметка-->
<div *ngIf="['admin', 'note'].indexOf(ticketMessage.authorType) >=0" class="message-data text-right">
  <span *ngIf="ticketMessage.authorType == 'note'">
    <span *ngIf="ticketMessage.pinned" class="pseudo-link" (click)="unpin(ticketMessage.id)">
      <span class="fa fa-thumb-tack"></span>&nbsp;Открепить</span>&nbsp;
    <span *ngIf="!ticketMessage.pinned && ticketMessage.active" class="pseudo-link" (click)="pin(ticketMessage.id)">
      <span class="fa fa-thumb-tack"></span>&nbsp;Прикрепить</span>&nbsp;
    &emsp;
  </span>

  <span *ngIf="ticketMessage.active && !noRemove" class="pseudo-link" (click)="openRemoveMsgDialog(ticketMessage.id)">
    <span class="fa fa-trash-o"></span>&nbsp;Удалить</span>
  <span *ngIf="!ticketMessage.active" class="message-deleted-caption">Удалено</span>
  &emsp;

  <a class="message-data-time" href="{{makeUrl(ticketMessage.id)}}" id="anchor{{ticketMessage.id}}">
    {{ticketMessage.date | amDateFormat:'DD.MM.YYYY HH:mm:ss'}}
  </a>
  <strong class="message-data-name">{{ticketMessage.author}}</strong>

  &nbsp;<span *ngIf="ticketMessage.pinned" class="fa fa-thumb-tack" title="сообщение прикреплено"></span>
</div>
<!--лог-->
<div *ngIf="['log'].indexOf(ticketMessage.authorType) >=0" class="message-data text-right">
  <a class="message-data-time" href="{{makeUrl(ticketMessage.id)}}" id="anchor{{ticketMessage.id}}">
    {{ticketMessage.date | amDateFormat:'DD.MM.YYYY HH:mm:ss'}}
  </a>
  <strong class="message-data-name">{{ticketMessage.author}}</strong>
</div>
<!--кастомер-->
<div *ngIf="ticketMessage.isCustomerMessage" class="message-data">
  <strong class="message-data-name">{{ticketMessage.author}}</strong>
  <a class="message-data-time" href="{{makeUrl(ticketMessage.id)}}" id="anchor{{ticketMessage.id}}">
    {{ticketMessage.date | amDateFormat:'DD.MM.YYYY HH:mm:ss'}}
  </a>
  <span *ngIf="!ticketMessage.active" class="message-deleted-caption">Удалено</span>
  <span *ngIf="store.systemInfo.translateEnabled && !ticketMessage.isTranslated && !noRemove" class="pseudo-link" 
        (click)="translateMessage(ticketMessage.id, ticketMessage.text)">
    <span class="fa fa-language"></span>&nbsp;Перевести</span>
</div>

<div class="message {{ticketMessage.authorType}}-message"
     [class.message-anchor]="showAnchor == ticketMessage.id"
     [class.float-right]="['admin', 'note', 'log'].indexOf(ticketMessage.authorType) >=0"
     [class.pointer]="isOnlyNotesAndActive" (click)="scrollToPin(ticketMessage.id)">
  <div *ngIf="ticketMessage.ticketInfo" innerHtml="{{ticketMessage.ticketInfo}}"></div>
  <hr *ngIf="ticketMessage.ticketInfo">
  <div *ngIf="ticketMessage.isAdminMessage || ticketMessage.isLog" Markdown
       class="message__text">
    {{ticketMessage.text}}
  </div>
  <div *ngIf="ticketMessage.isCustomerMessage || ticketMessage.isNote"
       innerHtml="{{ticketMessage.text}}" class="message__text">{{ticketMessage.text}}
  </div>
  <hr *ngIf="ticketMessage.textTranslated">
  <div *ngIf="ticketMessage.isCustomerMessage && ticketMessage.textTranslated"
       innerHtml="{{ticketMessage.textTranslated}}" class="message__text">{{ticketMessage.textTranslated}}
  </div>
  <div *ngIf="ticketMessage.attachments.length > 0">
    <hr *ngIf="ticketMessage.text">
    <span *ngFor="let attachment of ticketMessage.attachments">
                  <i class="fa fa-paperclip" aria-hidden="true"></i>
                  <a href="{{attachment.url}}"
                     target="_blank"
                     class="attachment-{{ticketMessage.type}}">{{attachment.fileName}}</a><br>
                </span>

  </div>

  <ng-container *ngIf="ticketMessage.evaluation != null">
    <hr>

    <i class="fa fa-thumbs-down" style="color: red; font-size: 20px" *ngIf="ticketMessage.evaluation == 0"></i>
    <i class="fa fa-thumbs-up" style="color: green; font-size: 20px" *ngIf="ticketMessage.evaluation == 1"></i>

    <ng-container *ngIf="ticketMessage.evaluationComment != null">
      <span>
        {{ticketMessage.evaluationComment}}
      </span>
    </ng-container>
  </ng-container>

</div>
