import {Injectable} from "@angular/core";
import {NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {Observable} from "rxjs/Observable";
import {Location} from "@angular/common";

export interface NavigationData {
    url: string,
    error?: any,
}

@Injectable()
export class NavigationService {

    private _navStart: BehaviorSubject<NavigationData>;
    navStart: Observable<NavigationData>;
    private _navEnd: BehaviorSubject<NavigationData>;
    navEnd: Observable<NavigationData>;
    private _navError: BehaviorSubject<NavigationData>;
    navError: Observable<NavigationData>;

    private dashboardUrl: string = "/support/dashboard";
    private previousUrl: string = "/";
    private currentUrl: string = "/";

    constructor(private router: Router,
                private location: Location) {
        this._navStart = <BehaviorSubject<NavigationData>>new BehaviorSubject({});
        this.navStart = this._navStart.asObservable();
        this._navEnd = <BehaviorSubject<NavigationData>>new BehaviorSubject({});
        this.navEnd = this._navEnd.asObservable();
        this._navError = <BehaviorSubject<NavigationData>>new BehaviorSubject({});
        this.navError = this._navError.asObservable();

        router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
                this._navStart.next({
                    url: val.url
                })
            }
            if (val instanceof NavigationEnd) {
                let u = val.urlAfterRedirects ? val.urlAfterRedirects : val.url;
                this.pushUrl(u);
                this._navEnd.next({
                    url: u
                })
            }
            if (val instanceof NavigationError) {
                this._navError.next({
                    url: val.url,
                    error: val.error
                })
            }
        });
    }

    replaceUrl(url: string) {
        if (this.currentUrl != url) {
            this.location.replaceState(url);
        }
        this.pushUrl(url)
    }

    private pushUrl(url: string) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = url;
        if (url.startsWith("/support/dashboard")) {
            this.dashboardUrl = url;
        }
    }

    goToDashboard() {
        this.router.navigateByUrl(this.dashboardUrl);
    }

    goBack() {
        this.router.navigateByUrl(this.previousUrl);
    }

    goTo(url: string) {
        this.router.navigateByUrl(url);
    }

    makeChatUrl(prefix: string, id: number, anchor: string) {
        return `/#/support/chat/${prefix}/${id}/${anchor}`;
    }

    private isPopupBlocked(scope, popup_window) {
        if ((popup_window.innerHeight > 0) == false) {
            scope._displayError();
        }
    }

    openWindow(url: string) {
        let w = window.open("/#" + url, '_blank');
        if (!w) {
            this.goTo(url);
            return;
        }
        if (/chrome/.test(navigator.userAgent.toLowerCase())) {
            setTimeout(() => {
                if (!w || (w.innerHeight > 0) == false) {
                    this.goTo(url);
                    return;
                }
            }, 200);
        } else {
            w.onload = () => {
                if ((w.innerHeight > 0) == false) {
                    this.goTo(url);
                    return;
                }
            };
        }
    }
}
