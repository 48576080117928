import {AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2} from "@angular/core";
import {DomHandler} from "primeng/primeng";

export interface RelativeMenuItem {
    label?: string;
    icon?: string;
    command?: (event?: any) => void;
    url?: string;
    routerLink?: any;
    items?: RelativeMenuItem[];
    expanded?: boolean;
    disabled?: boolean;
    visible?: boolean;
    target?: string;
    routerLinkActiveOptions?: any;
    separator?: boolean;

    data?: any;
}

@Component({
    selector: 'p-tieredMenuSub-relative',
    template: `
        <ul [ngClass]="{'ui-widget-content ui-corner-all ui-shadow ui-submenu-list': !root}" class="ui-menu-list"
            (click)="listClick($event)">
            <ng-template ngFor let-child [ngForOf]="(root ? item : item.items)">
                <li *ngIf="child.separator" class="ui-menu-separator ui-widget-content">
                <li *ngIf="!child.separator" #listItem
                    [ngClass]="{'ui-menuitem ui-widget ui-corner-all':true,'ui-menuitem-active':listItem==activeItem}"
                    [class]="child.styleClass" [ngStyle]="child.style"
                    (mouseenter)="onItemMouseEnter($event, listItem, child)" (mouseleave)="onItemMouseLeave($event)">
                    <a *ngIf="!child.routerLink" [href]="child.url||'#'" class="ui-menuitem-link ui-corner-all"
                       [attr.target]="child.target" [attr.title]="child.title" [attr.id]="child.id"
                       [ngClass]="{'ui-state-disabled':child.disabled}" (click)="itemClick($event, child)">
                        <span class="ui-menuitem-icon fa fa-fw" *ngIf="child.icon" [ngClass]="child.icon"></span>
                        <span class="ui-menuitem-text">{{child.label}}</span>
                        <span class="ui-submenu-icon fa fa-fw fa-caret-right" *ngIf="child.items"></span>
                    </a>
                    <a *ngIf="child.routerLink" [routerLink]="child.routerLink" [queryParams]="child.queryParams"
                       [routerLinkActive]="'ui-state-active'"
                       [routerLinkActiveOptions]="child.routerLinkActiveOptions||{exact:false}" [href]="child.url||'#'"
                       class="ui-menuitem-link ui-corner-all" [attr.target]="child.target" [attr.title]="child.title"
                       [attr.id]="child.id"
                       [ngClass]="{'ui-state-disabled':child.disabled}" (click)="itemClick($event, child)">

                        <span class="ui-menuitem-icon fa fa-fw" *ngIf="child.icon" [ngClass]="child.icon"></span>
                        <span class="ui-menuitem-text">{{child.label}}</span>
                        <span class="ui-submenu-icon fa fa-fw fa-caret-right" *ngIf="child.items"></span>
                    </a>
                    <p-tieredMenuSub-relative class="ui-submenu" [item]="child" *ngIf="child.items"
                                              [baseZIndex]="baseZIndex" [generalCommand]="generalCommand"
                                              [autoZIndex]="autoZIndex"></p-tieredMenuSub-relative>
                </li>
            </ng-template>
        </ul>
    `,
    providers: [DomHandler]
})
export class TieredMenuSubRelative {

    @Input() generalCommand: (event?: any) => void;

    @Input() item: RelativeMenuItem;

    @Input() root: boolean;

    @Input() autoZIndex: boolean = true;

    @Input() baseZIndex: number = 0;

    constructor(public domHandler: DomHandler) {
    }

    activeItem: HTMLLIElement;

    hideTimeout: any;

    onItemMouseEnter(event: Event, item: HTMLLIElement, menuitem: RelativeMenuItem) {
        if (menuitem.disabled) {
            return;
        }

        if (this.hideTimeout) {
            clearTimeout(this.hideTimeout);
            this.hideTimeout = null;
        }

        this.activeItem = item;
        let nextElement: HTMLElement = <HTMLElement> item.children[0].nextElementSibling;
        if (nextElement) {
            let sublist: HTMLElement = <HTMLElement> nextElement.children[0];
            if (this.autoZIndex) {
                sublist.style.zIndex = String(this.baseZIndex + (++DomHandler.zindex));
            }
            sublist.style.zIndex = String(++DomHandler.zindex);

            sublist.style.top = '0px';
            sublist.style.left = this.domHandler.getOuterWidth(item.children[0]) + 'px';
        }
    }

    onItemMouseLeave(event: Event) {
        this.hideTimeout = setTimeout(() => {
            this.activeItem = null;
        }, 1000);
    }

    itemClick(event: Event, item: RelativeMenuItem) {
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        if (!item.url) {
            event.preventDefault();
        }

        let command = !!item.command ? item.command : !!item.items ? false : this.generalCommand;
        if (command) {
            command({
                originalEvent: event,
                item: item
            });
        } else {
            event.stopPropagation();
        }
    }

    listClick(event: Event) {
        this.activeItem = null;
    }
}

@Component({
    selector: 'p-tieredMenu-relative',
    template: `
        <div
            [ngClass]="{'ui-tieredmenu ui-menu ui-widget ui-widget-content ui-corner-all ui-helper-clearfix':true,'ui-menu-dynamic ui-shadow':popup}"
            [class]="styleClass" [ngStyle]="style" style="z-index: 1000">
            <p-tieredMenuSub-relative [item]="model" root="root"
                                      [generalCommand]="sharedCommand"></p-tieredMenuSub-relative>
        </div>
    `,
    providers: [DomHandler]
})
export class TieredMenuRelative implements AfterViewInit, OnDestroy {

    //это действие будет навешено на клик на любой пункт или подпункт меню для которого не заданы command и items
    @Input() sharedCommand: (event?: any) => void;

    @Input() containerParent;
    @Input() containerFixed: boolean;

    @Input() model: RelativeMenuItem[];

    @Input() popup: boolean;

    @Input() style: any;

    @Input() styleClass: string;

    @Input() appendTo: any;

    @Input() autoZIndex: boolean = true;

    @Input() baseZIndex: number = 0;

    container: any;

    documentClickListener: any;

    preventDocumentDefault: any;

    constructor(public el: ElementRef, public domHandler: DomHandler, public renderer: Renderer2) {
    }

    ngAfterViewInit() {
        this.container = this.el.nativeElement.children[0];

        if (this.popup) {
            this.documentClickListener = this.renderer.listen('document', 'click', () => {
                if (!this.preventDocumentDefault) {
                    this.hide();
                }
                this.preventDocumentDefault = false;
            });
        }
    }

    toggle(event: Event) {
        if (this.container.offsetParent)
            this.hide();
        else
            this.show(event);
    }

    show(event: Event): void {
        this.preventDocumentDefault = true;
        this.container.style.display = 'block';

        this.absolutePosition(this.container, event.target);
        this.domHandler.fadeIn(this.container, 250);
    }

    absolutePosition(menu, target) {
        menu.style.marginTop = '32px';
        menu.style.zindex = 1001;
    };

    hide() {
        this.container.style.display = 'none';
    }

    ngOnDestroy() {
        if (this.popup && this.documentClickListener) {
            this.documentClickListener();
        }
    }

}
