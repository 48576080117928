import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import "jquery";
import "bootstrap";
import {ChatMessagesDataservice, PanelTicketIds} from "../../data/chat-messages.dataservice";
import {NavigationData, NavigationService} from "../../services/navigation.service";
import {Subject} from "rxjs";
import {GrowlMessageService} from "../../../core/services/growl-message.service";
import {LoginDataservice, UserInfo} from "../../../core/data/login.dataservice";
import {ChatInfoShowLogsEvents} from "../../services/chat-info.show-logs.events";
import {Message as GrowlMessage} from "primeng/components/common/api";
import {Dialog} from "primeng/components/dialog/dialog";
import "rxjs/add/operator/takeUntil";
import {ChatTextareaDirective} from "./reply/chat-textarea.directive";
import {AsideSupportService} from "./aside/aside-support.service";
import {ChatTextareaEventbus} from "./reply/chat-textarea.eventbus";
import {Store} from "../../store/store";
import {ChatInfoShowOnlyNotesEvents} from "../../services/chat-info.show-only-notes.events";
import {MassReplyDataservice} from "../../data/mass-reply.dataservice";
import {TicketList} from "../../store/ticket-list.store";

declare var $: any;

@Component({
    templateUrl: 'chat-mass-reply.component.html'
})
export class ChatMassReplyComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("noteDialog") noteDialog: Dialog;

    private showLogs: boolean = false;
    private showOnlyNotes: boolean = false;

    private _userInfo: UserInfo;
    private chatTextareaDirective: ChatTextareaDirective;
    private ngDestroyed: Subject<void> = new Subject<void>();

    private quoteText: string = "";

    private _ticketsIdsGroupedByPanels: PanelTicketIds[];
    private _panelPrefixForAllTickets: string;

    constructor(private asideSupportService: AsideSupportService,
                private chatMessagesDataservice: ChatMessagesDataservice,
                private massReplyDataservice: MassReplyDataservice,
                private growlMessageService: GrowlMessageService,
                private activatedRoute: ActivatedRoute,
                private navigationService: NavigationService,
                private chatInfoShowLogsEvents: ChatInfoShowLogsEvents,
                private chatInfoShowOnlyNotesEvents: ChatInfoShowOnlyNotesEvents,
                private chatTextareaEventbus: ChatTextareaEventbus,
                private loginDataservice: LoginDataservice,
                private _store: Store) {
    }

    get store() {
        return this._store;
    }

    ngOnInit() {
        this.navigationService.navEnd.takeUntil(this.ngDestroyed).subscribe((n: NavigationData) => {
            let params: { [k: string]: string; } = this.activatedRoute.snapshot.queryParams;

            this._ticketsIdsGroupedByPanels = Object.getOwnPropertyNames(params).map(name => {
                let ids: number[] = params[name]
                    .split(",")
                    .map(id => Number.parseInt(id));

                return {panelPrefix: name, ids: ids}
            });

            if (this._ticketsIdsGroupedByPanels.length == 1) {
                this._panelPrefixForAllTickets = this._ticketsIdsGroupedByPanels[0].panelPrefix;
            }

            this.massReplyDataservice.loadTicketListSubject.next({
                ticketsIdsGroupedByPanels: this._ticketsIdsGroupedByPanels,
                erase: true
            });

            this.chatInfoShowOnlyNotesEvents.next(false);
            this._store.ticketData.clear();
        });

        this.chatMessagesDataservice.messageSent.takeUntil(this.ngDestroyed).subscribe(data => {
            if (data.sent) {
                this.navigationService.goToDashboard();
            }
        });

        this.asideSupportService.showTabs = ['info'];

        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(data => this._userInfo = data);
        this.chatTextareaEventbus.chatTextarea.takeUntil(this.ngDestroyed).subscribe(data => {
            if (data != null) {
                this.chatTextareaDirective = data;
                const draftText = "";
                let hasAdminMsg = false;
                this.chatTextareaDirective.initMessage(draftText, hasAdminMsg);
            }
        });

        this.chatInfoShowLogsEvents.subscribe(this.ngDestroyed, (data) => this.showLogs = data);
        this.chatInfoShowOnlyNotesEvents.subscribe(this.ngDestroyed, (data) => this.showOnlyNotes = data);
    }

    ngOnDestroy(): void {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    }

    get msgs(): GrowlMessage[] {
        return this.growlMessageService.msgs;
    }

    removeGrowlMsg(event) {
        this.growlMessageService.removeMsg(event.message);
    }

    get massReplyTicketList(): TicketList {
        return this._store.massReplyTickets.value;
    }

    ngAfterViewInit() {
    }

    postMassReplyByHotKey(event, chatMessage: string, postpone: boolean) {
        event.preventDefault();

        this.postMassReply(event, chatMessage, postpone)
    }

    postMassReply(event, chatMessage: string, postpone: boolean) {
        event.preventDefault();

        if (!chatMessage) {
            this.growlMessageService.error("Введите сообщение");
            return;
        }

        let reply = message => {
            this.chatMessagesDataservice.massReply(message, postpone, this._ticketsIdsGroupedByPanels);
        };

        this.chatMessagesDataservice.processReply = true;
        reply(chatMessage)
    }

    isProcessReply() {
        return this.chatMessagesDataservice.isProcessReply();
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    quotation(text: string = this.quoteText) {
        text = this.decodeText(text.split("\n").map(s => "> " + s).join("\n"));
        this.chatTextareaDirective.insertText(text + "\n\n__cursorPosition__", null, "__cursorPosition__", true);
        this.chatTextareaDirective.focus();
        $('.quote-block').css('visibility', 'hidden');
        return false;
    }

    private decodeText(html) { //todo можно вынести в отдельный сервис
        let txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    selectQuote() {
        setTimeout(() => {
            let selection = window.getSelection();
            let quoteText = selection.toString();

            if (quoteText) {
                let range = selection.getRangeAt(0);
                if (range.toString()) {
                    this.quoteText = quoteText;
                    this.setQuoteBlockPosition(range);
                    return;
                }
            }
            $('.quote-block').css('visibility', 'hidden');
        }, 10);
    }

    setQuoteBlockPosition(range: any) {
        let position = range.getBoundingClientRect();
        let scrollTop = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop;
        let topOffset = 25;
        let leftOffset = 90;
        let top = position.top + scrollTop - topOffset;
        let left = position.left + (position.width - leftOffset) / 2;
        $(".quote-block").offset({top: top, left: left}).css('visibility', 'visible');
    }

    get panelPrefixForAllTickets(): string {
        return this._panelPrefixForAllTickets;
    }

}
