import { OnChanges, SimpleChanges } from "@angular/core";
import { ChatTextareaEventbus } from "./chat-textarea.eventbus";
import { Subject } from "rxjs";
import { LoginDataservice } from "../../../../core/data/login.dataservice";
var ChatTopControlsComponent = /** @class */ (function () {
    function ChatTopControlsComponent(chatTextareaEventbus, loginDataservice) {
        this.chatTextareaEventbus = chatTextareaEventbus;
        this.loginDataservice = loginDataservice;
        this.ngDestroyed = new Subject();
        var _this = this;
        this.templateMenuItemClick = function (event) {
            var templateMessage = event.item.data + "\n\n";
            _this.chatTextareaDirective.insertTextWithPlaceholder(templateMessage);
            _this.chatTextareaDirective.focus();
        };
        this.insertSnippetItemClick = function (event) {
            _this.chatTextareaEventbus.snippetDialogSubject.next(true);
        };
        this.downloadMenuItems = [
            { label: 'PuTTY' },
            { label: 'WinMTR' },
            { label: 'WinSCP' },
            { label: 'FileZilla' },
        ];
        this.insertMenuItems = [
            {
                label: 'Код (сниппет)',
                command: this.insertSnippetItemClick,
            },
            { label: 'Цитата' },
            { label: 'Изображение' },
            { label: 'Файл' },
        ];
    }
    ChatTopControlsComponent.prototype.ngOnChanges = function (changes) {
        if (this._userInfo) {
            this._templateMenuItems = this._userInfo.predefinedRepliesInPanels[this.ticketPanel];
        }
    };
    ChatTopControlsComponent.prototype.ngOnInit = function () {
        var _this_1 = this;
        this.parent = jQuery("main")[0];
        this.chatTextareaEventbus.chatTextarea.takeUntil(this.ngDestroyed).subscribe(function (data) {
            _this_1.chatTextareaDirective = data;
        });
        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(function (data) {
            _this_1._userInfo = data;
        });
        this._templateMenuItems = this._userInfo.predefinedRepliesInPanels[this.ticketPanel];
    };
    Object.defineProperty(ChatTopControlsComponent.prototype, "templateMenuItems", {
        get: function () {
            return this._templateMenuItems;
        },
        enumerable: true,
        configurable: true
    });
    ChatTopControlsComponent.prototype.ngOnDestroy = function () {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    };
    ChatTopControlsComponent.prototype.addNote = function () {
        this.chatTextareaEventbus.noteDialogSubject.next(true);
    };
    ChatTopControlsComponent.prototype.isSelectedPanel = function () {
        return this.ticketPanel != null && this.ticketPanel != "?";
    };
    return ChatTopControlsComponent;
}());
export { ChatTopControlsComponent };
