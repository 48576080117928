var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, observable } from "mobx-angular";
import { Subject } from "rxjs/Subject";
var TicketListStore = /** @class */ (function () {
    function TicketListStore() {
        this._changed = new Subject();
        this.changed = this._changed.asObservable();
        this.value = { tickets: [], loading: true };
    }
    TicketListStore.prototype.set = function (ticketList) {
        this.value = ticketList;
        this._changed.next(ticketList);
    };
    TicketListStore.prototype.clear = function () {
        this.value = { tickets: [], loading: true };
        this._changed.next(this.value);
    };
    Object.defineProperty(TicketListStore.prototype, "all", {
        get: function () {
            if (this.value == null) {
                return null;
            }
            return this.value.tickets;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketListStore.prototype, "countAll", {
        get: function () {
            if (this.value == null) {
                return 0;
            }
            return this.value.tickets.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketListStore.prototype, "loading", {
        get: function () {
            if (this.value == null) {
                return true;
            }
            return this.value.loading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketListStore.prototype, "counters", {
        get: function () {
            if (!this.value || !this.value.counters) {
                return {};
            }
            return this.value.counters;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], TicketListStore.prototype, "value", void 0);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], TicketListStore.prototype, "set", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], TicketListStore.prototype, "clear", null);
    __decorate([
        computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], TicketListStore.prototype, "all", null);
    __decorate([
        computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], TicketListStore.prototype, "countAll", null);
    __decorate([
        computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], TicketListStore.prototype, "loading", null);
    __decorate([
        computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], TicketListStore.prototype, "counters", null);
    return TicketListStore;
}());
export { TicketListStore };
