var Admin = /** @class */ (function () {
    function Admin(id, username, userRealName) {
        this.id = id;
        this.username = username;
        this.userRealName = userRealName;
    }
    Object.defineProperty(Admin.prototype, "label", {
        get: function () {
            return "" + this.userRealName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Admin.prototype, "value", {
        get: function () {
            return this;
        },
        enumerable: true,
        configurable: true
    });
    return Admin;
}());
export { Admin };
