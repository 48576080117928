import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Message} from "../../domain/message.domain";
import "jquery";
import "bootstrap";
import {ChatMessagesDataservice} from "../../data/chat-messages.dataservice";
import {NavigationData, NavigationService} from "../../services/navigation.service";
import {Subject} from "rxjs";
import {GrowlMessageService} from "../../../core/services/growl-message.service";
import {LoginDataservice, UserInfo} from "../../../core/data/login.dataservice";
import {SelectItem} from "primeng/primeng";
import {ChatTextareaDirective} from "./reply/chat-textarea.directive";
import {AsideSupportService} from "./aside/aside-support.service";
import {ChatTextareaEventbus} from "./reply/chat-textarea.eventbus";
import {Store} from "../../store/store";
import {TicketData} from "../../store/ticket.store";
import {CheckService} from "../../services/litera.service";
import {ChatMarkdownService} from "../../services/chat-markdown.service";
import {TicketInfoBuilder} from "../../domain/builder/ticket-info.builder";

@Component({
    templateUrl: 'chat-new.component.html'
})
export class ChatNewComponent implements OnInit, OnDestroy {

    @ViewChild("chatNote") chatNote: ElementRef;
    @ViewChild("chatMessageSubject") chatMessageSubject: any;

    private _ticketPanel: string;
    private _ticketSetUser: string;
    private _ticketSetDept: string;
    private _ticketSetSubj: string;
    private _ticketSetPanel: string;
    private _userInfo: UserInfo;
    private chatTextareaDirective: ChatTextareaDirective;
    private ngDestroyed: Subject<void> = new Subject<void>();
    private _phone: string;
    private _customerEmail: string;
    private _time: string;
    private _isSendSmsNow: boolean = false;
    private _isSendSms: boolean = false;
    private _isSendEmail: boolean = false;

    panels: SelectItem[];

    constructor(private asideSupportService: AsideSupportService,
                private chatMessagesDataservice: ChatMessagesDataservice,
                private growlMessageService: GrowlMessageService,
                private activatedRoute: ActivatedRoute,
                private navigationService: NavigationService,
                private chatTextareaEventbus: ChatTextareaEventbus,
                private loginDataservice: LoginDataservice,
                private _store: Store,
                private checkService: CheckService,
                private ticketInfoBuilder: TicketInfoBuilder,
                private chatMarkdownService: ChatMarkdownService) {
    }

    get store(): Store {
        return this._store;
    }

    ngOnInit() {
        this.navigationService.navEnd.takeUntil(this.ngDestroyed).subscribe((n: NavigationData) => {
            this._ticketPanel = this.activatedRoute.snapshot.params.panel;
            this._store.ticketData.clear();
            this.chatMessagesDataservice.getNewTicketData(this._ticketPanel, 0);
            this._ticketSetUser = this.activatedRoute.snapshot.queryParams.username;
            this._ticketSetDept = this.activatedRoute.snapshot.queryParams.department;
            this._ticketSetSubj = this.activatedRoute.snapshot.queryParams.subject;
            this._ticketSetPanel = this.activatedRoute.snapshot.queryParams.panel;
        });

        this._store.ticketData.changed.takeUntil(this.ngDestroyed).subscribe(data => {
            if (this._ticketSetUser) {
                this.ticketData.username = this._ticketSetUser;
                this.ticketData.ticket.username = this._ticketSetUser;
            }
            if (this._ticketSetDept) {
                this.ticketData.ticket.department = this._store.departments.getById(+this._ticketSetDept);
            }
            if (this._ticketSetSubj) {
                this.ticketData.ticket.subject = this._ticketSetSubj;
            }
            if (this._ticketSetPanel) {
                this._ticketPanel = this._ticketSetPanel;
            } else {
                this.ticketData.ticket.panelPrefix = undefined;
            }
            this.showNotificationSettings();
        });
        this.chatMessagesDataservice.messageSent.takeUntil(this.ngDestroyed).subscribe(data => {
            if (data.sent) {
                this.growlMessageService.success("Отправлено", "Сообщение отправлено");
                this.navigationService.goToDashboard();
            }
        });

        this.asideSupportService.showTabs = ['info'];

        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(data => {
            this._userInfo = data;
            this.panels = this._userInfo.panels.map((p: string) => {
                return {label: p, value: p};
            });
            this.panels.splice(0, 0, {label: "?", value: "?"});
        });
        this.chatTextareaEventbus.chatTextarea.takeUntil(this.ngDestroyed).subscribe(data => {
            this.chatTextareaDirective = data;
            if (this.chatTextareaDirective) {
                this.chatTextareaDirective.initMessage();
            }
        });

        setTimeout(() => {
            if (this.chatMessageSubject != undefined) {
                this.chatMessageSubject.nativeElement.focus();
            }
        }, 100);
    }

    ngOnDestroy(): void {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
    }

    get msgs() {
        return this.growlMessageService.msgs;
    }

    removeGrowlMsg(event) {
        this.growlMessageService.removeMsg(event.message);
    }

    get ticketData(): TicketData {
        return this._store.ticketData.value;
    }

    set ticketData(td: TicketData) {
        this._store.ticketData.set(td);
    }

    get panelPrefix(): string {
        return this.ticketData.ticket.panelPrefix;
    }

    postMessageByHotKey(event, chatMessage: string, postpone: boolean) {
        event.preventDefault();

        this.postMessage(event, chatMessage, postpone)
    }

    //в случае редактирования не забыть проверить postMessage метод в chat.component.ts
    postMessage(event, chatMessage: string, postpone: boolean) {
        event.preventDefault();
        let inputs = $(".message__form").find("input[type='file']");

        const formData: FormData = new FormData();
        formData.append('panelPrefix', this.panelPrefix);

        if (inputs) {
            let n = 0;
            for (let i = 0; i < inputs.length; i++) {
                let nativeElement = inputs[i];
                let files = nativeElement["files"];
                for (let j = 0; j < files.length; j++) {
                    let file: File = files[j];
                    if (file != undefined && file.size > 0) {
                        formData.append('attachment' + n++, file);
                    }
                }
            }
        }

        formData.append('message', chatMessage);
        if (this.chatNote) {
            formData.append('note', this.chatNote.nativeElement.value);
        }
        if (this.isSendSms) {
            formData.append('isSendSms', "true");
            if (this._isSendSmsNow) {
                formData.append('isSendSmsNow', "true");
            }
        }
        if (this.isSendEmail) {
            formData.append('htmlMessageForSendEmail', this.chatMarkdownService.parse(chatMessage))
        }
        formData.append('subject', this.ticketData.ticket.subject);
        formData.append('personUsername', this.ticketData.ticket.username);
        formData.append('departmentId', "" + this.ticketData.ticket.department.id);
        formData.append('statusId', "" + this.ticketData.ticket.status.id);
        formData.append('assignTo', this.ticketData.ticket.assignedTo ? this.ticketData.ticket.assignedTo.username : "");
        formData.append('type', "message");
        if (postpone != null) {
            formData.append('postpone', postpone ? "true" : "false");
        }

        let error = false;
        let requiredFields = ['panelPrefix', 'message', 'subject', 'personUsername'];
        requiredFields.forEach(f => {
            const val = formData.get(f);
            if (!val || val == 'undefined') {
                this.growlMessageService.error(`необходимо указать ${f}`);
                error = true;
            }
        });
        if (error) {
            return;
        }

        let create = message => {
            formData.set('message', message);
            this.chatMessagesDataservice.create(this._ticketPanel, formData);
        };
        if (chatMessage) {
            this.checkService.start({text: chatMessage, ticket: this.ticketData.ticket, onSuccess: create});
            this.chatMessagesDataservice.processReply = true;
            return;
        }
        create(chatMessage);
    }

    isProcessReply() {
        return this.chatMessagesDataservice.isProcessReply();
    }

    createTicketInPanel(panel) {
        this.ticketData.ticket.panelPrefix = panel;
        this.showNotificationSettings()
    }

    showNotificationSettings() {
        this._phone = "";
        this._time = "";
        this._customerEmail = "";
        this._isSendEmail = false;
        this._isSendSms = false;

        let panel = this.ticketData.ticket.panelPrefix;
        let username = this.ticketData.ticket.username;
        if (!username || !panel || panel == "?") {
            return
        }

        let setPhoneAndTime = data => {
            this._phone = data.phone;
            this._time = data.time;
            this._customerEmail = data.customerEmail;
            this.store.ticketData.ticket.customerNote = data["customer"]["note"];
            this.store.ticketData.setInfo(this.ticketInfoBuilder.build(data["customer"]));
        };
        this.chatMessagesDataservice.getNotificationSettings(panel, username, setPhoneAndTime);
    }

    get phone() {
        return this._phone;
    }

    get customerEmail() {
        return this._customerEmail;
    }

    get time() {
        if (this._isSendSmsNow) {
            return "сейчас";
        }
        return "в " + this._time + ":00 MSK";
    }

    invertSendSmsNow() {
        this._isSendSmsNow = !this._isSendSmsNow;
    }

    get isSendSms() {
        if (this.isAbuse && this.phone) {
            this._isSendSms = true;
        }
        return this._isSendSms;
    }

    get isSendEmail() {
        if (this.isAbuse && this.customerEmail) {
            this._isSendEmail = true;
        }
        return this._isSendEmail;
    }

    invertIsSendSms() {
        this._isSendSms = !this._isSendSms
    }

    changeSendEmail() {
        this._isSendEmail = !this._isSendEmail
    }

    get isAbuse() {
        return this.ticketData.ticket.department.name == "Abuse";
    }

    sendMsgWithoutCheck() {
        this.checkService.doSuccess();
    }

    get literaIframe(): boolean {
        return this.checkService.isChecking();
    }

    reloadIframe() {
        this.checkService.reload();
    }

    closeIframe() {
        this.checkService.stop();
    }

    selectTicketMask(event: Event) {
    }
}
