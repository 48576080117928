export class TicketInfo {
    ticketId: number;
    username: string;
    email: string;
    phone: string;
    orders: string[];
    regInfoName: string;
    confirmedEmail: boolean;
    activate: boolean;
    confirmedRegInfo: boolean;
    panelLinkToOrders: string;
    customerPanelLinks: string;
}
