export class Department {
    id: number;
    name: string;
    active: boolean;

    constructor(id: number, name: string, active: boolean) {
        this.id = id;
        this.name = name;
        this.active = active;
    }

    get label(): string {
        return this.name;
    }

    get value(): Department {
        return this;
    }
}
