import {Injectable} from "@angular/core";
import {Store} from "../../store/store";
import {Admin} from "../admin.domain";

@Injectable()
export class AdminBuilder {

    constructor(private store: Store) {
    }

    build(adminData): Admin {
        if (!adminData.id) {
            return null;
        }

        return new Admin(adminData.id, adminData.username, adminData.userRealName);
    }
}
