import { AfterViewInit, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import "jquery";
import "bootstrap";
import { ChatMessagesDataservice } from "../../data/chat-messages.dataservice";
import { NavigationService } from "../../services/navigation.service";
import { Subject } from "rxjs";
import { GrowlMessageService } from "../../../core/services/growl-message.service";
import { LoginDataservice } from "../../../core/data/login.dataservice";
import { ChatInfoShowLogsEvents } from "../../services/chat-info.show-logs.events";
import { Dialog } from "primeng/components/dialog/dialog";
import "rxjs/add/operator/takeUntil";
import { AsideSupportService } from "./aside/aside-support.service";
import { ChatTextareaEventbus } from "./reply/chat-textarea.eventbus";
import { Store } from "../../store/store";
import { ChatInfoShowOnlyNotesEvents } from "../../services/chat-info.show-only-notes.events";
import { CheckService } from "../../services/litera.service";
var ChatComponent = /** @class */ (function () {
    function ChatComponent(asideSupportService, chatMessagesDataservice, growlMessageService, activatedRoute, navigationService, chatInfoShowLogsEvents, chatInfoShowOnlyNotesEvents, chatTextareaEventbus, loginDataservice, _store, checkService) {
        this.asideSupportService = asideSupportService;
        this.chatMessagesDataservice = chatMessagesDataservice;
        this.growlMessageService = growlMessageService;
        this.activatedRoute = activatedRoute;
        this.navigationService = navigationService;
        this.chatInfoShowLogsEvents = chatInfoShowLogsEvents;
        this.chatInfoShowOnlyNotesEvents = chatInfoShowOnlyNotesEvents;
        this.chatTextareaEventbus = chatTextareaEventbus;
        this.loginDataservice = loginDataservice;
        this._store = _store;
        this.checkService = checkService;
        this._noteDialogDisplay = false;
        this.showLogs = false;
        this.showOnlyNotes = false;
        this.removeMsgDialog = false;
        this._ticketData = null;
        this.ngDestroyed = new Subject();
        this.ticketSyncInterval = null;
        this.quoteText = "";
    }
    Object.defineProperty(ChatComponent.prototype, "store", {
        get: function () {
            return this._store;
        },
        enumerable: true,
        configurable: true
    });
    ChatComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.navigationService.navEnd.takeUntil(this.ngDestroyed).subscribe(function (n) {
            _this._ticketId = _this.activatedRoute.snapshot.params.id;
            _this._ticketPanel = _this.activatedRoute.snapshot.params.panel;
            _this._ticketData = null;
            _this.chatInfoShowOnlyNotesEvents.next(false);
            _this.chatMessagesDataservice.loadMessages(_this._ticketPanel, _this._ticketId, true);
        });
        this.store.ticketData.changed.takeUntil(this.ngDestroyed).subscribe(function (data) {
            _this._ticketData = data;
            if (data) {
                setTimeout(function () { return _this.noteDialog.onHide.subscribe(function () { return _this.closeNoteDialog(); }); }, 1);
            }
            setTimeout(function () {
                var anchor = _this.showAnchor;
                if (!anchor) {
                    anchor = "" + _this.store.ticketData.lastMessage.id;
                }
                $('html, body').animate({
                    scrollTop: $("#anchor" + anchor).offset().top - 55 // с поправкой на шапку
                }, 20);
            }, 0);
        });
        this.chatMessagesDataservice.messageSent.takeUntil(this.ngDestroyed).subscribe(function (data) {
            if (data.sent) {
                _this.growlMessageService.success("Отправлено", "Сообщение отправлено");
            }
            if (data.goToDashboard) {
                _this.navigationService.goToDashboard();
            }
            else {
                _this.chatTextareaDirective.initMessage("", true);
            }
        });
        this.chatTextareaEventbus.noteDialogSubject.takeUntil(this.ngDestroyed).subscribe(function (data) { return _this._noteDialogDisplay = data; });
        this.asideSupportService.showTabs = ['info'];
        this.loginDataservice.userInfo.takeUntil(this.ngDestroyed).subscribe(function (data) { return _this._userInfo = data; });
        this.chatTextareaEventbus.chatTextarea.takeUntil(this.ngDestroyed).subscribe(function (data) {
            if (data != null) {
                _this.chatTextareaDirective = data;
                var hasAdminMsg = void 0;
                var draftText = void 0;
                if (_this._ticketData) {
                    hasAdminMsg = _this._ticketData.messages.some(function (msg) { return msg.authorType == 'admin'; });
                    draftText = _this._ticketData.ticket.draftText;
                }
                _this.chatTextareaDirective.initMessage(draftText, hasAdminMsg);
            }
        });
        this.chatInfoShowLogsEvents.subscribe(this.ngDestroyed, function (data) { return _this.showLogs = data; });
        this.chatInfoShowOnlyNotesEvents.subscribe(this.ngDestroyed, function (data) { return _this.showOnlyNotes = data; });
        this.ticketSyncInterval = setInterval(function () {
            _this.ticketSynchronization();
        }, 3000);
    };
    ChatComponent.prototype.ngOnDestroy = function () {
        this.ngDestroyed.next();
        this.ngDestroyed.complete();
        clearInterval(this.ticketSyncInterval);
    };
    ChatComponent.prototype.closeNoteDialog = function () {
        this.chatTextareaEventbus.noteDialogSubject.next(false);
    };
    Object.defineProperty(ChatComponent.prototype, "msgs", {
        get: function () {
            return this.growlMessageService.msgs;
        },
        enumerable: true,
        configurable: true
    });
    ChatComponent.prototype.removeGrowlMsg = function (event) {
        this.growlMessageService.removeMsg(event.message);
    };
    Object.defineProperty(ChatComponent.prototype, "noteDialogDisplay", {
        get: function () {
            return this._noteDialogDisplay;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatComponent.prototype, "ticketData", {
        get: function () {
            return this._store.ticketData.value;
        },
        enumerable: true,
        configurable: true
    });
    ChatComponent.prototype.ngAfterViewInit = function () {
    };
    Object.defineProperty(ChatComponent.prototype, "typingAdmins", {
        get: function () {
            return this.store.ticketData.typingAdmins;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatComponent.prototype, "messages", {
        get: function () {
            var _this = this;
            return this.ticketData.messages.filter(function (m) {
                if (_this.showOnlyNotes) {
                    return m.type == 'note';
                }
                return _this.showLogs ? true : (m.type != 'log' && m.active);
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatComponent.prototype, "panelPrefix", {
        get: function () {
            return this.ticketData.ticket.panelPrefix;
        },
        enumerable: true,
        configurable: true
    });
    ChatComponent.prototype.postMessageByHotKey = function (event, chatMessage, postpone) {
        event.preventDefault();
        this.postMessage(event, chatMessage, postpone);
    };
    ChatComponent.prototype.reloadData = function (event) {
        event.preventDefault();
        this.chatMessagesDataservice.loadMessages(this._ticketPanel, this._ticketId, true);
    };
    ChatComponent.prototype.onKeydownHandler = function (evt) {
        if (this.store.ticketData.ticketChanged) {
            this.reloadData(evt);
        }
    };
    ChatComponent.prototype.ticketSynchronization = function () {
        if (!this.store.ticketData.value || !this.store.ticketData.ticket || this.isProcessReply()) {
            return;
        }
        var message = $("#message-to-send").val();
        var formData = this.generateFormData();
        formData.append('ticketVersion', "" + this._ticketData.ticket.version);
        var defaultText = this.chatTextareaDirective.initMessageText.replace(this.chatTextareaDirective.cursorPositionMarker, "");
        if (message == defaultText) {
            // исходный текст = пустой черновик
            message = "";
        }
        else if (message != null && message != "") {
            // вставляем в черновик позицию курсора
            message = message.slice(0, this.chatTextareaDirective.textareaSelectionStart) +
                this.chatTextareaDirective.cursorPositionMarker +
                message.slice(this.chatTextareaDirective.textareaSelectionStart);
        }
        formData.append('draftMessage', message);
        this.chatMessagesDataservice.ticketSynchronization(formData);
    };
    //в случае редактирования не забыть проверить postMessage метод в chat-new.component.ts
    ChatComponent.prototype.postMessage = function (event, chatMessage, postpone) {
        var _this = this;
        event.preventDefault();
        var inputs = $(".message__form").find("input[type='file']");
        var formData = this.generateFormData();
        var fileCount = 0;
        if (inputs) {
            var n = 0;
            for (var i = 0; i < inputs.length; i++) {
                var nativeElement = inputs[i];
                var files = nativeElement["files"];
                for (var j = 0; j < files.length; j++) {
                    var file = files[j];
                    if (file != undefined && file.size > 0) {
                        formData.append('attachment' + n++, file);
                        fileCount++;
                    }
                }
            }
        }
        if (fileCount == 0 && chatMessage == "") {
            this.growlMessageService.error("Введите сообщение или прикрепите файл");
            return;
        }
        formData.append('type', "message");
        if (postpone != null) {
            formData.append('postpone', postpone ? "true" : "false");
        }
        formData.append('afterPropertyChangeInChat', this.store.ticketData.afterPropertyChangeInChat ? "true" : "false");
        var reply = function (message) {
            formData.append('message', message);
            _this.chatMessagesDataservice.reply(_this._ticketPanel, _this._ticketId, formData, true, postpone != null);
            _this.store.ticketData.clearAfterPropertyChangeInChat();
        };
        if (chatMessage) {
            this.checkService.start({ text: chatMessage, ticket: this.ticketData.ticket, onSuccess: reply });
            this.chatMessagesDataservice.processReply = true;
            return;
        }
        reply(chatMessage);
    };
    ChatComponent.prototype.isProcessReply = function () {
        return this.chatMessagesDataservice.isProcessReply();
    };
    ChatComponent.prototype.generateFormData = function () {
        var formData = new FormData();
        formData.append('ticketId', "" + this._ticketId);
        formData.append('panelPrefix', this.panelPrefix);
        return formData;
    };
    ChatComponent.prototype.postNote = function (event, chatMessage, pin) {
        event.preventDefault();
        if (chatMessage == "") {
            this.growlMessageService.error("Введите сообщение");
            return;
        }
        var formData = this.generateFormData();
        formData.append('message', chatMessage);
        formData.append('type', "note");
        formData.append('pin', "" + pin);
        this.chatMessagesDataservice.reply(this._ticketPanel, this._ticketId, formData, false);
        this.closeNoteDialog();
    };
    ChatComponent.prototype.openRemoveMsgDialog = function (id) {
        this.removeMsgId = id;
        this.removeMsgDialog = true;
    };
    ChatComponent.prototype.closeRemoveMsgDialog = function () {
        this.removeMsgDialog = false;
    };
    ChatComponent.prototype.removeMessage = function () {
        this.chatMessagesDataservice.removeMessage(this.removeMsgId, this._ticketId, this.panelPrefix);
        this.closeRemoveMsgDialog();
    };
    ChatComponent.prototype.scrollTop = function () {
        window.scrollTo(0, 0);
    };
    Object.defineProperty(ChatComponent.prototype, "showAnchor", {
        get: function () {
            return this.activatedRoute.snapshot.params.anchor;
        },
        enumerable: true,
        configurable: true
    });
    ChatComponent.prototype.quotation = function (text) {
        if (text === void 0) { text = this.quoteText; }
        text = this.decodeText(text.split("\n").map(function (s) { return "> " + s; }).join("\n"));
        this.chatTextareaDirective.insertText(text + "\n\n__cursorPosition__", null, "__cursorPosition__", true);
        this.chatTextareaDirective.focus();
        $('.quote-block').css('visibility', 'hidden');
        return false;
    };
    ChatComponent.prototype.selectTicketMask = function (event) {
        var range = document.createRange();
        var el = event.target;
        range.selectNodeContents(el);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    };
    ChatComponent.prototype.decodeText = function (html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    };
    ChatComponent.prototype.selectQuote = function () {
        var _this = this;
        setTimeout(function () {
            var selection = window.getSelection();
            var quoteText = selection.toString();
            if (quoteText) {
                var range = selection.getRangeAt(0);
                if (range.toString()) {
                    _this.quoteText = quoteText;
                    _this.setQuoteBlockPosition(range);
                    return;
                }
            }
            $('.quote-block').css('visibility', 'hidden');
        }, 10);
    };
    ChatComponent.prototype.setQuoteBlockPosition = function (range) {
        var position = range.getBoundingClientRect();
        var scrollTop = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop;
        var topOffset = 25;
        var leftOffset = 90;
        var top = position.top + scrollTop - topOffset;
        var left = position.left + (position.width - leftOffset) / 2;
        $(".quote-block").offset({ top: top, left: left }).css('visibility', 'visible');
    };
    ChatComponent.prototype.sendMsgWithoutCheck = function () {
        this.checkService.doSuccess();
    };
    Object.defineProperty(ChatComponent.prototype, "literaIframe", {
        get: function () {
            return this.checkService.isChecking();
        },
        enumerable: true,
        configurable: true
    });
    ChatComponent.prototype.closeIframe = function () {
        this.checkService.stop();
    };
    ChatComponent.prototype.reloadIframe = function () {
        this.checkService.reload();
    };
    ChatComponent.prototype.translateQuoteText = function (text) {
        if (text === void 0) { text = this.quoteText; }
        this.chatMessagesDataservice.translate(null, text);
    };
    ChatComponent.prototype.hasTranslatedText = function () {
        return this.store.ticketData.translatedText != null;
    };
    ChatComponent.prototype.removeTranslatedText = function () {
        this.store.ticketData.translateText(null);
    };
    return ChatComponent;
}());
export { ChatComponent };
